import React, { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ProgressBar from 'react-bootstrap/ProgressBar';

import * as Scroll from 'react-scroll';
// import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Modal from 'react-bootstrap/Modal';
import { renderToString } from 'react-dom/server';
import RingLoader from "react-spinners/RingLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import BeatLoader from "react-spinners/BeatLoader";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';
import $ from 'jquery';
import interact from 'interactjs';
import colormap from 'colormap';

import 'toolcool-range-slider/dist/plugins/tcrs-moving-tooltip.min.js';
import 'toolcool-range-slider/dist/plugins/tcrs-generated-labels.min.js';
import { RangeSlider } from 'toolcool-range-slider';
import 'toolcool-range-slider';

// import { AiFillFire } from 'react-icons/ai';
// import { FaUpload, FaPlay, FaPause, FaPauseCircle, FaBook, FaUndo, FaRedo, FaCopy, FaCut, FaPaste, FaPhoneSlash } from 'react-icons/fa';
// import { MdReportProblem } from 'react-icons/md';
// import { BiSave } from "react-icons/bi";
// import { CgPushLeft, CgPushRight, CgDisplaySpacing } from 'react-icons/cg';
// import { GoSettings, GoMute, GoUnmute } from 'react-icons/go';
import { FaTags } from 'react-icons/fa';
import { RiUserVoiceFill } from 'react-icons/ri';
// import { FiTrendingUp, FiTrendingDown } from 'react-icons/fi';
// import { RiLayoutRightFill } from 'react-icons/ri';
// import { RxReset } from 'react-icons/rx';
// import { MdOutlineFastForward, MdOutlineFastRewind, MdSettingsInputComposite, MdDarkMode } from 'react-icons/md';
// import { TfiLayoutSidebarRight, TfiSplitH } from 'react-icons/tfi';
// import { IoPlaySkipBack, IoPlaySkipForward, IoPlayForwardSharp, IoPlayBackSharp, IoSunnySharp, IoDuplicate, IoCopy } from 'react-icons/io5';
// import { FcCancel } from "react-icons/fc";
// import { TbLogout, TbWaveSine, TbArrowBarDown, TbArrowBarUp, TbArrowBarLeft, TbArrowBarRight } from "react-icons/tb";
// import { TiWaves } from 'react-icons/ti';
// import { GiCancel, GiWaves } from "react-icons/gi";
// import { ImDownload3, ImLoop2, ImCross } from "react-icons/im";
// import { BsTrashFill, BsInfoCircleFill, BsPlayBtn, BsFilePlay, BsPauseBtn } from "react-icons/bs"

import WaveSurfer from 'wavesurfer.js';
//@ts-ignore
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
// import TimelinePlugin from 'wavesurfer.timeline.js';
//@ts-ignore
import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js';
//@ts-ignore
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
// import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';
//@ts-ignore
import SpectrogramPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.spectrogram.min.js';
// import SpectrogramPlugin from 'wavesurfer.js/src/plugin/spectrogram/index.js'
// import SpectrogramPlugin from 'wavesurfer.spectrogram.js';
//@ts-ignore
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';
//@ts-ignore
import ElanPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.elan.min.js';

import {
    // Button,
    Card,
    Col,
    Dropdown,
    Form,
    FormControl,
    InputGroup,
    Row,
    Container,
    Table,
    Pagination,
    ButtonGroup,
    ToggleButton
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
// import './WaveSurfer.scss'
import { ToastFailedMsg, ToastSuccessMsg, ToastInfoMsg, ToastWarningMsg } from "../../common/toast";

import playImg from '../../icons/play-button.png';

import { getLoggedInUserId, getRoles, decodeToken, isLoggedIn, unsetToken } from "../../common/http";
import { start } from "repl";

// let scrollButton = Scroll.Button;

// const colormap = require('colormap');

// import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js';

// import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';

// https://github.com/katspaugh/wavesurfer.js/issues/1077

import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slider from '@mui/material/Slider';
import Divider from '@mui/material/Divider';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import JoinInnerIcon from '@mui/icons-material/JoinInner';
import BalanceIcon from '@mui/icons-material/Balance';

import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import WavesIcon from '@mui/icons-material/Waves';
import WaterIcon from '@mui/icons-material/Water';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import LensBlurIcon from '@mui/icons-material/LensBlur';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import ReplayIcon from '@mui/icons-material/Replay';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerifiedIcon from '@mui/icons-material/Verified';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/Upload';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HistoryIcon from '@mui/icons-material/History';

import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DeleteIcon from '@mui/icons-material/Delete';

import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import VerticalSplitOutlinedIcon from '@mui/icons-material/VerticalSplitOutlined';

import BlockIcon from '@mui/icons-material/Block';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';

import ShortcutIcon from '@mui/icons-material/Shortcut';
import InfoIcon from '@mui/icons-material/Info';
import MenuBookIcon from '@mui/icons-material/MenuBook';


import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const sttEvalAnnotators = [
    "annotatorstteval@gmail.com",
    "mohiuddinmia14@gmail.com",
    "Mdmehedihasanrakib44@gmail.com",
    "shahinurling.du@gmail.com"
];

export default function WaveSurferComPage() {
    const openInvalidDialogRef: any = useRef(false);
    const [openInvalidDialog, setOpenInvalidDialog] = useState(false);

    const SENTENCE_LEVEL = 0;
    const WORD_LEVEL = 1;
    const TRANSCRIPTION_LEVEL = 2;
    const PHONEME_LEVEL = 3;
    const SHOW_GRAPHEME_AFTER_WORD_LEVEL = false

    let tierDivHeight = 36;
    const fftSamples = 256;

    const zoomMin = 20;
    const zoomMax = 10000;
    const zoomStep = 10;
    const initialZoom = 120;

    let fontSizeMin = 12;
    let fontSizeMax = 48;
    let fontSizeDefault = 24;

    let showSpectrogram = false;

    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkModeWorkspace') ? JSON.parse(localStorage.getItem('darkModeWorkspace')) : false);
    // const darkModeRef = useRef(false);

    const backgroundColorLight = "#DEDEDE";
    const waveColorLight = "#471620";
    const progressColorLight = "#006B43";
    const handleColorLight = "#990033";

    const backgroundColorDark = "#0E0918";
    const waveColorDark = "#FF9E44";
    const progressColorDark = "#FF4093";
    const handleColorDark = "#E60000";

    const selectedRegionOpacity = useRef(0.5);
    const selectedRegionColor = useRef(`rgba(211, 211, 247, ${selectedRegionOpacity.current})`);

    const [backgroundColor, setBackgroundColor] = useState(backgroundColorLight);
    const [waveColor, setWaveColor] = useState(waveColorLight);
    const [progressColor, setProgressColor] = useState(progressColorLight);
    const [handleColor, setHandleColor] = useState(handleColorLight);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showShortcutsModal, setShowShortcutsModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleCloseDelete = () => setShowDeleteModal(false);
    const handleShowDelete = () => setShowDeleteModal(true);

    const handleCloseShortcuts = () => setShowShortcutsModal(false);
    const handleShowShortcuts = () => setShowShortcutsModal(true);

    const handleCloseCancel = () => setShowCancelModal(false);
    const handleShowCancel = () => setShowCancelModal(true);

    const handleCloseLogout = () => setShowLogoutModal(false);
    const handleShowLogout = () => setShowLogoutModal(true);

    const canvasHeightPercentageMin = 0.1
    const canvasHeightPercentageMax = 1
    const canvasHeightDefault = 0.35
    const canvasHeightStep = 0.005;
    const canvasHeightBigStep = 0.02;

    const incrementOnKeyPress = 0.20

    const resizeStep = 0.005;
    const seekStep = 2.5;

    const playbackRateStep = 0.1;
    const minPlaybackRate = 0.1;
    const maxPlaybackRate = 5;

    const barHeightStep = 0.25;
    const minBarHeight = 0.5;
    const maxBarHeight = 10;

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [overlappedSnackbar, setOverlappedSnackbar] = React.useState(-1);

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOverlappedSnackbar(-1);
    };

    const randomString = () => {
        var chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 8;
        var randomstring = '';
        for (var i = 0; i < string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars[rnum];
        }
        return randomstring;
    }

    let sentenceTagDictSTT: any = {
        "BN": "Background Noise - ব্যাকগ্রাউন্ড শব্দ",
        // "FS": "Filler & Stuttering", *
        // "PD": "Pronunciation Deviation - উচ্চারণ বিচ্যুতি", #
        "OVL": "Overlap - একাধিক",
        "OS": "Organic Sound - হাঁচি/কাশি/হাই/শ্বাস",
        // "UC": "Unclear - অস্পষ্ট", ৳
        // "WU": "Wrong Utterances - ভুল উচ্চারণ", ৳
        // "D": "Dialect - আঞ্চলিকতা", %
        "BM": "Background Music - ব্যাকগ্রাউন্ড মিউজিক",
        "MI": "Masking Interference",
        // "SD": "Severe Dialect (Multiple Word) - আঞ্চলিকতা_একাধিক",
        // "PF": "Pause Filler - কথায় উ/আ শব্দ",
        // "NA": "Not Accepted - অগ্রহণযোগ্য",
        // "SP": "Stuttering Problem - তোতলামি",
        // "IS": "Incomplete Sentence - অসম্পূর্ণ",
        // "UP": "Unwanted Pause - অযাচিত বিরতি",
        // "MS": "Mechanical Sound - যান্ত্রিক শব্দ",
    }

    let sentenceTagDictTTS: any = {
        "BN": "Background Noise - ব্যাকগ্রাউন্ড শব্দ",
        "FS": "Filler & Stuttering",
        // "PD": "Pronunciation Deviation - উচ্চারণ বিচ্যুতি", ##
        "LV": "Low Volume - ভলিউম কম",
        "TL": "Too Loud - বেশি লাউড",
        "OVL": "Overlap - একাধিক",
        "OS": "Organic Sound - হাঁচি/কাশি/হাই/শ্বাস",
        // "UC": "Unclear - অস্পষ্ট", @@
        "WU": "Wrong Utterances - ভুল উচ্চারণ",
        "D": "Dialect - আঞ্চলিকতা",
        "TF": "Too Fast - বেশি দ্রুত",
        "TS": "Too Slow - বেশি স্লো",
        "UP": "Unwanted Pause - অযাচিত পজ",
        "PR": "Pause Required - বিরাম প্রয়োজন",
        // "SD": "Severe Dialect (Multiple Word) - আঞ্চলিকতা_একাধিক",
        // "PF": "Pause Filler - কথায় উ/আ শব্দ",
        // "NA": "Not Accepted - অগ্রহণযোগ্য",
        // "SP": "Stuttering Problem - তোতলামি",
        // "IS": "Incomplete Sentence - অসম্পূর্ণ",
        "BM": "Background Music - ব্যাকগ্রাউন্ড মিউজিক",
        "EP": "Environmental Problem - প্রতিকূল পরিবেশ",
        "RF": "Rise Fall - আপ ডাউন",
        // "UP": "Unwanted Pause - অযাচিত বিরতি",
        // "MS": "Mechanical Sound - যান্ত্রিক শব্দ",
        "MI": "Masking Interference",
        "MISC": "Miscellaneous - অন্যান্য"
    }

    let wordTagDictSTT = {
        // "IW": "Invalid Word - অশব্দ",
        // "D": "Dialect - আঞ্চলিক শব্দ",
        // "AWM": "Audio Word Mismatch - অডিও টেক্সটে অমিল",
        "UC": "Unclear - অস্পষ্ট",
        "F": "Filler",
        "S": "Stuttering - তোতলামি",
        "PD": "Pronunciation Deviation - উচ্চারণ বিচ্যুতি",
        "STM": "Speech Text Mismatch - অডিও টেক্সটে অমিল",
        "OS": "Organic Sound - হাঁচি/কাশি/হাই/শ্বাস",
        "D": "Dialect - আঞ্চলিকতা",
        "NW": "Non Word - অশব্দ",
        "MI": "Masking Interference"
    }

    let wordTagDictTTS = {
        // "IW": "Invalid Word - অশব্দ",
        // "D": "Dialect - আঞ্চলিক শব্দ",
        // "AWM": "Audio Word Mismatch - অডিও টেক্সটে অমিল",
        "UC": "Unclear - অস্পষ্ট",
        "F": "Filler",
        "S": "Stuttering - তোতলামি",
        "PD": "Pronunciation Deviation - উচ্চারণ বিচ্যুতি",
        "STM": "Speech Text Mismatch - অডিও টেক্সটে অমিল",
        "OS": "Organic Sound - হাঁচি/কাশি/হাই/শ্বাস",
        "D": "Dialect - আঞ্চলিকতা",
        "NW": "Non Word - অশব্দ",
        "MI": "Masking Interference"
    }

    // speaker tag:
    // <s_1> speaker_1  বক্তা_১
    // <s_2> speaker_2  বক্তা_২
    // <s_3> speaker_3  বক্তা_৩
    // <s_n> speaker_n  বক্তা_n
    // <OVL> overlap    একাধিক

    const ipaToRomanizedMap: any = {
        "h": "H",
        "i̯": "i^",
        "o̯": "o^",
        "u̯": "u^",
        "e̯": "Y",
        "ʲ": "Y",
        "ʷ": "w",

        "ɔ̃": "O*",
        "ã": "a*",
        "ĩ": "i*",
        "ũ": "u*",
        "õ": "o*",
        "ẽ": "e*",
        "æ̃": "E*",

        "ɔ": "O",
        "a": "a",
        "i": "i",
        "u": "u",
        "o": "o",
        "e": "e",
        "æ": "E",

        "kʰ": "kh",
        "k": "k",

        "gʰ": "gh",
        "g": "g",

        "ŋ": "N",

        "cʰ": "ch",
        "c": "c",

        "ɟʰ": "jh",
        "ɟ": "j",

        "t̪ʰ": "th",
        "t̪": "t",
        "tʰ": "Th",
        "t": "T",

        "d̪ʰ": "dh",
        "d̪": "d",
        "dʰ": "Dh",
        "d": "D",

        "n": "n",
        "l": "l",

        "pʰ": "ph",
        "p": "p",

        "bʰ": "v",
        "b": "b",

        "m": "m",
        "ʃ": "sh",
        "s": "s",

        "ɽʰ": "rh",
        "ɽ": "R",
        "r": "r",
    }

    const romanizedToIpaMap = Object.fromEntries(Object.entries(ipaToRomanizedMap).map(a => a.reverse()))
    romanizedToIpaMap["y"] = "ʲ"

    const dependent_list_ipa = ['_', '1', '2', '̃', 'ʰ', '̪', '̯']
    const dependent_list_romanized = ['*', 'h', '^']

    const get_independent_list_ipa = (input_str: string) => {
        let input_list = input_str.split('')
        let output_list: any = []
        while (input_list.length > 0) {
            let char = input_list.shift()
            if (char.trim() == '') {
                continue
            }
            if (dependent_list_ipa.includes(char)) {
                try {
                    output_list[output_list.length - 1] = output_list[output_list.length - 1] + char
                }
                catch (error) {
                    continue
                }
            }
            else {
                output_list.push(char)
            }
        }
        return output_list
    }

    const get_independent_list_romanized = (input_str: string) => {
        let input_list = input_str.split('')
        let output_list: any = []
        while (input_list.length > 0) {
            let char = input_list.shift()
            if (char.trim() == '') {
                continue
            }
            if (dependent_list_romanized.includes(char)) {
                try {
                    output_list[output_list.length - 1] = output_list[output_list.length - 1] + char
                }
                catch (error) {
                    continue
                }
            }
            else {
                output_list.push(char)
            }
        }
        return output_list
    }

    const getRomanizedFromIpa = (ipa_str: string) => {
        let romanized_str: any = []
        let words = ipa_str.trim().split(/[ \t]+/ig)
        words.forEach((word: string) => {
            word = word.trim()
            romanized_str.push(get_independent_list_ipa(word).map((phn: any) => ipaToRomanizedMap[phn]).join(''))
        })
        return romanized_str.join(' ')
    }

    const getIpaFromRomanized = (romanized_str: string) => {
        let ipa_str: any = []
        let words = romanized_str.trim().split(/[ \t]+/ig)
        words.forEach((word: string) => {
            word = word.trim()
            ipa_str.push(get_independent_list_romanized(word).map((romanized_phn: any) => romanizedToIpaMap[romanized_phn]).join(''))
        })
        return ipa_str.join(' ')
    }

    let undoList: any = [];

    let graphemeTierDiv: any = null;
    let phonemeTierDiv: any = null;

    const userInfo: any = useRef({});
    const [userId, setUserId]: any = useState(null);

    const [hasMaskingInterference, setHasMaskingInterference]: any = useState(false);
    const [currentGain, setCurrentGain]: any = useState(1);
    const [timePassed, setTimePassed]: any = useState(Math.floor(new Date().getTime() / 1000) - parseInt(localStorage.getItem('last_picked')));
    const [previousAnnotation, setPreviousAnnotation]: any = useState(null);
    const [playing, setPlaying]: any = useState(false);
    const [playingRegion, setPlayingRegion]: any = useState(false);
    const [playingLoop, setPlayingLoop]: any = useState(false);
    const [mute, setMute]: any = useState(false);

    // const gainRef: any = useRef(1);
    const [normalized, setNormalized]: any = useState(false);
    const normalizedRef: any = useRef(false);
    const maxAvgRmsdBFS: any = useRef(0);
    const targetdBFS = 0;

    const previousAnnotationRef: any = useRef(null);
    const canvasHeightPercentageRef: any = useRef(canvasHeightDefault);
    const tagsRegionMapRef: any = useRef({});
    const graphemePhonemeMapRef: any = useRef({});

    // const tierDeleteButtonRef: any = useRef(null);


    // const displayContentRef: any = useRef(null)
    // const sliderRef = useRef<RangeSlider>(null);
    const [zoomSliderValue, setZoomSliderValue]: any = useState(initialZoom)

    const waveformRef: any = useRef(null)
    const waveTimelineRef: any = useRef(null)
    const waveSpectrogramRef: any = useRef(null)

    const wavesurfer: any = useRef(null);
    let audioContextRef: any = useRef(null);
    let mediaElementSourceRef: any = useRef(null);
    const lastSelectedRegion: any = useRef(null);

    const playRegion: any = useRef(false);
    const playRegionLoop: any = useRef(false);
    const playUniversal: any = useRef(false);

    const dragEnabled: any = useRef(false);
    const pairingTextboxEnabled: any = useRef(false);
    const pairingRegionEnabled: any = useRef(true);
    // const wordBreakEnabled: any = useRef(true);

    const heldKeys: any = useRef({});

    const [loading, setLoading]: any = useState(true);
    const [isLoading, setIsLoading]: any = useState(true);
    const [error, setError]: any = useState('');
    const [progress, setProgress]: any = useState(0);
    const [unloadEventListener, setUnloadEventListener]: any = useState(false);
    // const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = ProSidebar();

    const beforeUnloadListener = (event: any) => {
        event.preventDefault();
        return event.returnValue = "Are you sure you want to exit?";
    };

    const truncate = (str: any, n: any) => {
        if (str) {
            return (str.length > n) ? str.slice(0, n - 1) + '…' : str;
        }
    };

    const onLogout = async () => {
        setIsLoading(true);
        setUnloadEventListener(false);

        setTimeout(() => {
            localStorage.removeItem('speech_id');
            localStorage.removeItem('latestPage');
            localStorage.removeItem('latestSizePerPage');
            unsetToken();
            setIsLoading(false);
            // history.push('/login')
            // history.go(0)
            window.location.replace(window.location.origin + `/login`)
        }, 1000);
        // return true;
    };

    useEffect(() => {
        // window.location.href = window.location.href;
        // window.location.reload();
        let user = decodeToken()
        if (!user) {
            window.location.replace(window.location.origin + `/login`)
        }
        console.log('LoggedIn-UserInfo', user);
        userInfo.current = user;
        setUserId(user.userId);

        // audioContextRef.current = new AudioContext();

        // Events.scrollEvent.register('begin', function () {
        //     console.log("begin", arguments);
        // });

        // Events.scrollEvent.register('end', function () {
        //     console.log("end", arguments);
        // });

        // scrollSpy.update();

        // console.log('msg', sidebarMenusData);

        // (function (global) {

        //     if(typeof (global) === "undefined") {
        //         throw new Error("window is undefined");
        //     }

        //     var _hash = "!";
        //     var noBackPlease = function () {
        //         global.location.href += "#";

        //         // Making sure we have the fruit available for juice (^__^)
        //         global.setTimeout(function () {
        //             global.location.href += "!";
        //         }, 50);
        //     };

        //     global.onhashchange = function () {
        //         if (global.location.hash !== _hash) {
        //             global.location.hash = _hash;
        //         }
        //     };

        //     global.onload = function () {
        //         noBackPlease();

        //         // Disables backspace on page except on input fields and textarea..
        //         document.body.onkeydown = function (e: any) {
        //             var elm = e.target.nodeName.toLowerCase();
        //             if (e.which === 8 && (elm !== 'input' && elm  !== 'textarea')) {
        //                 e.preventDefault();
        //             }
        //             // Stopping the event bubbling up the DOM tree...
        //             e.stopPropagation();
        //         };
        //     }
        // })(window);

        // setTimeout(() => {
        //     window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
        // }, 1000);

        // window.onpopstate = (event) => {
        //     alert(`location: ${document.location}, state: ${JSON.stringify(event.state)}`)
        // }
    }, []);

    function amplifyMedia(mediaElem: any, multiplier: any) {
        // create an audio context and hook up the media element as the source
        let audioCtx = audioContextRef.current;
        if (mediaElementSourceRef.current == null) {
            mediaElementSourceRef.current = audioCtx.createMediaElementSource(mediaElem);
        }

        // create a gain node
        let gainNode = audioCtx.createGain();
        gainNode.gain.value = multiplier; // double the volume
        mediaElementSourceRef.current.connect(gainNode);

        // connect the gain node to an output destination
        gainNode.connect(audioCtx.destination);
    }

    const getMaxDbfs = async (audioBuffer: any) => {
        // const audioContext = new (window.AudioContext);
        // const result = fetch(audioUrl)
        //     .then(function (res) { return res.arrayBuffer(); })
        //     .then(function (buf) {
        //         return audioContext.decodeAudioData(buf);
        //     }).then(function (audioBuffer) {
        console.log("Audio Buffer: ", audioBuffer);
        const length = audioBuffer.length;
        const numChannels = audioBuffer.numberOfChannels;
        let maxDbfs = -Infinity;
        let sumDbfs = 0;
        let sumSquared = 0;

        for (let channel = 0; channel < numChannels; channel++) {
            const channelData = audioBuffer.getChannelData(channel);
            for (let i = 0; i < channelData.length; i++) {
                const sample = channelData[i];
                const dbfs = 20 * Math.log10(Math.abs(sample));
                // Square each sample and accumulate
                sumSquared += sample * sample;

                if (dbfs > maxDbfs) {
                    maxDbfs = dbfs;
                }

                // if (dbfs == Number.NEGATIVE_INFINITY) {
                //     continue
                // }

                // Accumulate dBFS values for average calculation
                // sumDbfs += dbfs;
            }
        }

        // Calculate the average dBFS and RMS dBFS
        // const avgDbfs = sumDbfs / (numChannels * length);

        // Calculate the mean of the squared samples
        const meanSquared = sumSquared / (numChannels * length);

        // Take the square root to get the RMS value
        const rmsDbfs = 20 * Math.log10(Math.sqrt(meanSquared));

        return { maxDbfs, rmsDbfs };
        //     });
        // return result;
    };

    function get_unique_values(arr: any) {
        return Array.from(new Set(arr));
    }

    const buttonTooltip = (title: string, description: string, shortcut: string) => {
        return (
            <Stack sx={{ m: 0, p: 0, width: '100%' }}>
                <Box sx={{ px: 1, py: 0.5, width: '100%', backgroundColor: 'rgba(32, 32, 32, 1)', textAlign: 'center', fontFamily: 'Roboto, sans-serif', fontSize: '150%', fontWeight: 'bolder' }}>{title.toUpperCase()}</Box>
                <Divider light={true} orientation='horizontal' sx={{ height: '0.5px', backgroundColor: 'rgba(62, 39, 35, 1)' }} />
                <Box sx={{ px: 1, py: 0.5, width: '100%', backgroundColor: 'rgba(26, 0, 13, 1)', textAlign: 'center', fontFamily: 'Noto Serif Bengali, serif', fontSize: '175%', fontWeight: 'bolder' }}>{description}</Box>
                <Divider light={true} orientation='horizontal' sx={{ height: '0.5px', backgroundColor: 'rgba(62, 39, 35, 1)' }} />
                <Stack direction='row' spacing={2} sx={{ px: 1, py: 0.5, backgroundColor: 'rgba(32, 32, 32, 1)' }}>
                    <Box><ShortcutIcon /></Box>
                    <Box sx={{ width: '100%', textAlign: 'left', fontFamily: 'Roboto, sans-serif', fontSize: '125%', fontWeight: 'bolder' }}>{shortcut.toUpperCase()}</Box>
                </Stack>
            </Stack>
        )
    }

    const formatHourMinSec = (totalSeconds: number) => {
        let hours = Math.floor(totalSeconds / 3600)
        let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
        let seconds = Math.round(totalSeconds - hours * 3600 - minutes * 60)
        // return `${("0" + hour).slice(-2)} hr ${("0" + minutes).slice(-2)} min ${("0" + seconds).slice(-2)} sec`
        return `${hours > 0 ? hours + ' hr ' : ''}${minutes > 0 ? minutes + ' min ' : ''}${seconds > 0 ? seconds + ' sec' : ''}`
    }

    useEffect(() => {
        // console.log("Background Color Changed: ", backgroundColor);
        if (wavesurfer.current) {
            wavesurfer.current.setBackgroundColor(backgroundColor);
        }
    }, [backgroundColor]);

    useEffect(() => {
        // console.log("Wave Color Changed: ", waveColor);
        if (wavesurfer.current) {
            wavesurfer.current.setWaveColor(waveColor);
        }
    }, [waveColor]);

    useEffect(() => {
        // console.log("Progress Color Changed: ", progressColor);
        if (wavesurfer.current) {
            wavesurfer.current.setProgressColor(progressColor);
        }
    }, [progressColor]);

    useEffect(() => {
        // console.log("Handle Color Changed: ", handleColor);
        if (wavesurfer.current) {
            wavesurfer.current.setCursorColor(handleColor);
        }
    }, [handleColor]);

    useEffect(() => {
        console.log("Toggle DARK Mode !!! ", darkMode);
        localStorage.setItem('darkModeWorkspace', JSON.stringify(darkMode))

        if (darkMode) {
            setBackgroundColor(backgroundColorDark);
            setWaveColor(waveColorDark);
            setProgressColor(progressColorDark);
            setHandleColor(handleColorDark); // #BF6EE1

            selectedRegionOpacity.current = 0.2;
        } else {
            setBackgroundColor(backgroundColorLight);
            setWaveColor(waveColorLight);
            setProgressColor(progressColorLight);
            setHandleColor(handleColorLight);

            selectedRegionOpacity.current = 0.5;
        }
        selectedRegionColor.current = `rgba(211, 211, 247, ${selectedRegionOpacity.current})`;

        if (lastSelectedRegion.current != null) {
            lastSelectedRegion.current.update({
                color: selectedRegionColor.current
            })
        }
    }, [darkMode]);

    // useEffect(() => {
    // console.log("RENDERED ! AGAIN !! ...");
    // });

    useEffect(() => {
        // the handler for actually showing the prompt
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";
        };

        const doSomething = () => {
            // code here
            console.log("HERE WE GO !!! ", window.location);
        }

        // if the form is NOT unchanged, then set the onbeforeunload
        if (unloadEventListener) {
            window.addEventListener("beforeunload", handler);

            window.onunload = function () {
                var nothing = doSomething();
            }

            // clean it up, if the dirty state changes
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        // since this is not dirty, don't do anything
        return () => { };
    }, [unloadEventListener]);

    useEffect(() => {
        //Runs on the first render
        //And any time any dependency value changes

        console.log("Previously Saved Annotations: ", previousAnnotation);
        // document.addEventListener("click", function (event) {
        //     event.stopImmediatePropagation()
        //     console.log(event.screenX, event.screenY);
        // }, false);
        if (previousAnnotation == null) {
            (async () => { await getAllAnnotations() })();
        } else {
            previousAnnotationRef.current = previousAnnotation;

            let status = previousAnnotation.annotation_status;
            let graphemeTierArray = []
            let phonemeTierArray = []
            let annotations = previousAnnotation.annotation;

            // if (previousAnnotation.annotation_status <= WORD_LEVEL || SHOW_GRAPHEME_AFTER_WORD_LEVEL) {
            for (let i = 0; i < annotations.length; i++) {
                if (annotations[i].tagList.length > 0 && status > WORD_LEVEL) {
                    graphemeTierArray.push(null);
                    continue;
                }
                let graphemeTierContent: any = {}

                graphemeTierContent["id"] = `${annotations[i].id}|txt`;
                graphemeTierContent["tierId"] = 1;
                graphemeTierContent["tierName"] = "grapheme";
                graphemeTierContent["start"] = annotations[i].start;
                graphemeTierContent["end"] = annotations[i].end;
                graphemeTierContent["text"] = annotations[i].text;

                // console.log("GRAPHEME TIER CONTENT: ", graphemeTierContent);
                graphemeTierArray.push(graphemeTierContent)
            }
            // }

            console.log("Grapheme Tier Array: ", graphemeTierArray);
            console.log("GRAPHEME PHONEME MAP: ", graphemePhonemeMapRef.current);

            if (previousAnnotation.annotation_status > WORD_LEVEL) {
                if (previousAnnotation.annotation_status == PHONEME_LEVEL) {
                    for (let i = 0; i < annotations.length; i++) {
                        let current_alignment = annotations[i].alignment;
                        console.log("ALIGNMENT: ", current_alignment);
                        let segment_wise_alignment_array = []
                        for (let j = 0; j < current_alignment.length; j++) {
                            let phonemeTierContent: any = {}
                            phonemeTierContent["id"] = `${current_alignment[j]["id"]}|phn`;
                            phonemeTierContent["tierId"] = 2;
                            phonemeTierContent["tierName"] = "phoneme";
                            // phonemeTierContent["start"] = current_alignment[j]["all_confidences"][0].start / 1000;
                            // phonemeTierContent["end"] = current_alignment[j]["all_confidences"].slice(-1)[0].end / 1000;
                            phonemeTierContent["start"] = current_alignment[j]["start"]
                            phonemeTierContent["end"] = current_alignment[j]["end"]
                            phonemeTierContent["text"] = { "verdict": getRomanizedFromIpa(current_alignment[j]["phone"]) };
                            // phonemeTierContent["confidence"] = Math.max(...current_alignment[j]["all_confidences"].map((item: any) => {
                            //     return item["confidence"]
                            // }));

                            segment_wise_alignment_array.push(phonemeTierContent)
                        }
                        phonemeTierArray.push(segment_wise_alignment_array)
                    }
                } else {
                    for (let i = 0; i < annotations.length; i++) {
                        console.log(annotations[i].phoneme);
                        if (annotations[i].tagList.length > 0 && status > WORD_LEVEL) {
                            phonemeTierArray.push(null);
                            continue;
                        }

                        let phonemeTierContent: any = {}
                        phonemeTierContent["id"] = `${annotations[i].id}|phn`;
                        phonemeTierContent["tierId"] = 2;
                        phonemeTierContent["tierName"] = "phoneme";
                        phonemeTierContent["start"] = annotations[i].start;
                        phonemeTierContent["end"] = annotations[i].end;
                        if ("verdict" in annotations[i].phoneme) {
                            phonemeTierContent["text"] = { "verdict": getRomanizedFromIpa(annotations[i].phoneme["verdict"]) };
                        }
                        else {
                            phonemeTierContent["text"] = { "deep_phonemizer": getRomanizedFromIpa(annotations[i].phoneme["deep_phonemizer"]) };
                        }
                        phonemeTierArray.push(phonemeTierContent);
                    }
                }
            }

            console.log("Phoneme Tier Array: ", phonemeTierArray);

            for (let i = 0; i < 300; i++) {
                heldKeys.current[i] = [false, 0]
            }

            setUnloadEventListener(true);

            document.addEventListener('keydown', async (e: any) => {
                // console.log(e.keyCode);
                // if (e.repeat) return;

                if (e.keyCode === 88 && e.ctrlKey === true && e.shiftKey === false) {
                    // CUT ???
                } else if (e.key === "ArrowDown" && e.ctrlKey === true && e.shiftKey === false) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL and Down Arrow pressed");
                    if (wavesurfer.current.params.barHeight - barHeightStep >= minBarHeight) {
                        wavesurfer.current.params.barHeight -= barHeightStep;
                        wavesurfer.current.empty();
                        wavesurfer.current.drawBuffer();
                        await adjustCanvasHeight();
                    }
                } else if (e.key === "ArrowDown" && e.ctrlKey === true && e.shiftKey === true) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL + SHIFT + DOWN Arrow pressed");
                    canvasHeightPercentageRef.current = Math.min(canvasHeightPercentageMax, canvasHeightPercentageRef.current + canvasHeightStep);
                    await adjustCanvasHeight();
                } else if (e.key === "ArrowUp" && e.ctrlKey === true && e.shiftKey === false) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL and Up Arrow pressed");
                    if (wavesurfer.current.params.barHeight + barHeightStep <= maxBarHeight) {
                        wavesurfer.current.params.barHeight += barHeightStep;
                        wavesurfer.current.empty();
                        wavesurfer.current.drawBuffer();
                        await adjustCanvasHeight();
                    }
                } else if (e.key === "ArrowUp" && e.ctrlKey === true && e.shiftKey === true) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL + SHIFT + Up Arrow pressed");
                    canvasHeightPercentageRef.current = Math.max(canvasHeightPercentageMin, canvasHeightPercentageRef.current - canvasHeightStep);
                    await adjustCanvasHeight();
                } else if (e.keyCode === 220 && e.ctrlKey === true && e.shiftKey === true) {
                    //canvas Height 'RESET'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + SHIFT + | pressed, canvas height RESET");

                    canvasHeightPercentageRef.current = canvasHeightDefault;
                    await adjustCanvasHeight();
                } else if (e.keyCode === 191 && e.ctrlKey === true && e.altKey === true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL ALT / pressed");
                    wavesurfer.current.toggleMute();
                    setMute(wavesurfer.current.getMute())
                } else if (e.keyCode === 78 && e.ctrlKey === true && e.altKey === true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL ALT N pressed");
                    // Toggle, Do the Opposite
                    if (normalizedRef.current) {
                        amplifyMedia(document.querySelector('audio'), 1);
                    } else {
                        // Calculate the gain adjustment factor based on target and actual dBFS
                        const gainFactor = Math.pow(10, (targetdBFS - maxAvgRmsdBFS.current['maxDbfs']) / 20);
                        console.log("GAIN FACTOR: ", gainFactor)
                        if (Number.isFinite(gainFactor)) {
                            amplifyMedia(document.querySelector('audio'), Math.max(gainFactor, 1));
                        }
                    }
                    normalizedRef.current = !normalizedRef.current
                    setNormalized(normalizedRef.current)
                } else if (e.keyCode === 85 && e.ctrlKey === true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + U pressed, UNIVERSAL play");

                    if (audioContextRef && audioContextRef.current.state == "suspended") {
                        await audioContextRef.current.resume();
                    }

                    if (!(wavesurfer.current.isPlaying())) {
                        setPlaying(true);
                        setPlayingRegion(false);
                        setPlayingLoop(false);

                        playUniversal.current = true;
                        playRegion.current = false;
                        playRegionLoop.current = false;

                        try {
                            if (!(wavesurfer.current.isPlaying())) {
                                wavesurfer.current.play();
                            }
                        } catch (err: any) {
                            console.log(`PLAY INTERRUPTED ! ${err.message}`);
                        }

                    } else if (!playUniversal.current && wavesurfer.current.isPlaying()) {
                        setPlaying(true);
                        setPlayingRegion(false);
                        setPlayingLoop(false);

                        playUniversal.current = true;
                        playRegion.current = false;
                        playRegionLoop.current = false;
                    } else if (playUniversal.current && wavesurfer.current.isPlaying()) {
                        try {
                            wavesurfer.current.pause();
                        } catch (err: any) {
                            console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                        }

                        setPlaying(false);
                        setPlayingRegion(false);
                        setPlayingLoop(false);

                        playUniversal.current = false;
                        playRegion.current = false;
                        playRegionLoop.current = false;
                    }
                } else if (e.keyCode === 32 && e.ctrlKey === true && e.shiftKey === false && e.altKey === false) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    if (lastSelectedRegion.current != null) {
                        console.log("CTRL + SPACE pressed, REGION play");
                        // lastSelectedRegion.current.playLoop();

                        if (audioContextRef && audioContextRef.current.state == "suspended") {
                            await audioContextRef.current.resume();
                        }

                        if (!(wavesurfer.current.isPlaying())) {
                            console.log(wavesurfer.current.getCurrentTime());

                            playUniversal.current = false;
                            playRegionLoop.current = false;

                            if (wavesurfer.current.getCurrentTime() >= lastSelectedRegion.current.start && wavesurfer.current.getCurrentTime() < lastSelectedRegion.current.end) {
                                setPlaying(false);
                                setPlayingLoop(false);

                                try {
                                    let playPromise = undefined;
                                    if (!(wavesurfer.current.isPlaying())) {
                                        playPromise = wavesurfer.current.play();
                                    }
                                    console.log("LOOK ! ", playPromise);

                                    if (playPromise !== undefined) {
                                        playPromise.then((value: any) => {
                                            console.log("NOW NOW !!!", wavesurfer.current.isPlaying());
                                            playRegion.current = true;
                                            setPlayingRegion(true);
                                            // Automatic playback started!
                                            // Show playing UI.
                                        }).catch((error: any) => {
                                            console.log("THEN THEN !!!", error);
                                            // Auto-play was prevented
                                            // Show paused UI.
                                        });
                                    } else {
                                        console.log("ERROR ERROR !!!");
                                    }
                                } catch (err: any) {
                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                }
                            } else {
                                setPlaying(false);
                                setPlayingLoop(false);

                                wavesurfer.current.seekTo(lastSelectedRegion.current.start / wavesurfer.current.getDuration())
                                try {
                                    let playPromise = undefined;
                                    if (!(wavesurfer.current.isPlaying())) {
                                        playPromise = wavesurfer.current.play();
                                    }
                                    console.log("LOOK ! ", playPromise);

                                    if (playPromise !== undefined) {
                                        playPromise.then((value: any) => {
                                            console.log("NOW NOW !!!", wavesurfer.current.isPlaying());
                                            playRegion.current = true;
                                            setPlayingRegion(true);
                                            // Automatic playback started!
                                            // Show playing UI.
                                        }).catch((error: any) => {
                                            console.log("THEN THEN !!!", error, wavesurfer.current.isPlaying());
                                            // Auto-play was prevented
                                            // Show paused UI.
                                        });
                                    } else {
                                        console.log("ERROR ERROR !!!");
                                    }
                                } catch (err: any) {
                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                }
                            }
                        } else if (!playRegion.current && wavesurfer.current.isPlaying()) {
                            console.log("ARE YOU HERE?", wavesurfer.current.isPlaying());

                            playUniversal.current = false;
                            playRegion.current = true;
                            playRegionLoop.current = false;

                            setPlaying(false);
                            setPlayingRegion(true);
                            setPlayingLoop(false);
                        } else if (playRegion.current && wavesurfer.current.isPlaying()) {
                            console.log("OR HERE?");

                            try {
                                wavesurfer.current.pause();
                            } catch (err: any) {
                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                            }

                            playUniversal.current = false;
                            playRegion.current = false;
                            playRegionLoop.current = false;

                            setPlaying(false);
                            setPlayingRegion(false)
                            setPlayingLoop(false);
                        }
                    }
                } else if (e.keyCode === 32 && e.ctrlKey === true && e.shiftKey === true && e.altKey === false) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    if (lastSelectedRegion.current != null) {
                        console.log("CTRL + SHIFT + SPACE pressed, REGION play from start");

                        if (audioContextRef && audioContextRef.current.state == "suspended") {
                            await audioContextRef.current.resume();
                        }

                        if (!(wavesurfer.current.isPlaying())) {
                            playUniversal.current = false;
                            playRegion.current = true;
                            playRegionLoop.current = false;

                            setPlaying(false);
                            setPlayingRegion(true);
                            setPlayingLoop(false);

                            wavesurfer.current.seekTo(lastSelectedRegion.current.start / wavesurfer.current.getDuration())
                            try {
                                if (!(wavesurfer.current.isPlaying())) {
                                    wavesurfer.current.play();
                                }
                            } catch (err: any) {
                                console.log(`PLAY INTERRUPTED ! ${err.message}`);
                            }
                        } else if (!playRegion.current && wavesurfer.current.isPlaying()) {
                            playUniversal.current = false;
                            playRegion.current = true;
                            playRegionLoop.current = false;

                            setPlaying(false);
                            setPlayingRegion(true);
                            setPlayingLoop(false);
                        } else if (playRegion.current && wavesurfer.current.isPlaying()) {
                            try {
                                wavesurfer.current.pause();
                            } catch (err: any) {
                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                            }

                            playUniversal.current = false;
                            playRegion.current = false;
                            playRegionLoop.current = false;

                            setPlaying(false);
                            setPlayingRegion(false);
                            setPlayingLoop(false);
                        }
                    }
                } else if (e.keyCode === 81 && e.ctrlKey === true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    if (lastSelectedRegion.current != null) {
                        console.log("CTRL and Q pressed, Region LOOP play");

                        if (audioContextRef && audioContextRef.current.state == "suspended") {
                            await audioContextRef.current.resume();
                        }

                        if (!playRegionLoop.current && !(wavesurfer.current.isPlaying())) {
                            if (wavesurfer.current.getCurrentTime() >= lastSelectedRegion.current.start && wavesurfer.current.getCurrentTime() < lastSelectedRegion.current.end) {
                                playUniversal.current = false;
                                playRegion.current = false;
                                playRegionLoop.current = true;

                                setPlaying(false);
                                setPlayingRegion(false);
                                setPlayingLoop(true);

                                try {
                                    if (!(wavesurfer.current.isPlaying())) {
                                        wavesurfer.current.play();
                                    }
                                } catch (err: any) {
                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                }
                            } else {
                                playUniversal.current = false;
                                playRegion.current = false;
                                playRegionLoop.current = true;

                                setPlaying(false);
                                setPlayingRegion(false);
                                setPlayingLoop(true);

                                wavesurfer.current.seekTo(lastSelectedRegion.current.start / wavesurfer.current.getDuration())
                                try {
                                    if (!(wavesurfer.current.isPlaying())) {
                                        wavesurfer.current.play();
                                    }
                                } catch (err: any) {
                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                }
                            }
                        }
                        // else if (playRegionLoop.current && !wavesurfer.current.isPlaying()) {

                        // }
                        else if (!playRegionLoop.current && wavesurfer.current.isPlaying()) {
                            playUniversal.current = false;
                            playRegion.current = false;
                            playRegionLoop.current = true;

                            setPlaying(false);
                            setPlayingRegion(false);
                            setPlayingLoop(true);
                        } else if (playRegionLoop.current) {
                            try {
                                if (wavesurfer.current.isPlaying()) {
                                    wavesurfer.current.pause();
                                }
                            } catch (err: any) {
                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                            }

                            playUniversal.current = false;
                            playRegion.current = false;
                            playRegionLoop.current = false;

                            setPlaying(false);
                            setPlayingRegion(false);
                            setPlayingLoop(false);
                        }
                    }
                } else if (e.keyCode === 68 && e.ctrlKey === true && e.shiftKey === false && e.altKey === false) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    let status = previousAnnotationRef.current.annotation_status;
                    let unlock = (status < TRANSCRIPTION_LEVEL);

                    if (unlock) {
                        console.log("CTRL and D pressed, Toggle DRAG");

                        if (dragEnabled.current) {
                            dragEnabled.current = false;
                            Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                                region.update({
                                    drag: false
                                })
                            });
                        } else {
                            dragEnabled.current = true;
                            Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                                region.update({
                                    drag: true
                                })
                            });
                        }
                    }
                }
                // else if (e.keyCode == 68 && e.ctrlKey === true && e.shiftKey == false && e.altKey === true) {
                //     e.preventDefault();
                //     if (heldKeys.current[e.keyCode][0]) {
                //         return;
                //     }
                //     heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                //     darkModeRef.current = !(darkModeRef.current);
                //     setDarkMode(!darkMode);
                //     console.log("CTRL + ALT + D pressed: Toggle DARK Mode !!! ", darkModeRef.current);

                //     if (darkModeRef.current) {
                //         setBackgroundColor("#0e0918");
                //         setWaveColor("#FF9E44");
                //         setProgressColor("#FF4093");
                //         setHandleColor("#FF0000"); // #BF6EE1
                //     } else {
                //         setBackgroundColor(backgroundColor);
                //         setWaveColor(waveColor);
                //         setProgressColor(progressColor);
                //         setHandleColor(handleColor);
                //     }
                // }
                else if (e.keyCode === 188 && e.ctrlKey === true && e.shiftKey === false) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL + < pressed");
                    // console.log(heldKeys.current[e.keyCode]);
                    let resizeBy = resizeStep * heldKeys.current[e.keyCode][1];
                    if (previousAnnotationRef.current.annotation_status != TRANSCRIPTION_LEVEL && lastSelectedRegion.current != null) {
                        let children = document.querySelectorAll(`[data-id=${lastSelectedRegion.current.id}] textarea`);
                        if (previousAnnotationRef.current.annotation_status == PHONEME_LEVEL && children[0].id.endsWith('txt')) {
                        } else {
                            lastSelectedRegion.current.onResize(-Math.min(resizeBy, lastSelectedRegion.current.start), 'start');
                        }
                    }
                } else if (e.keyCode === 190 && e.ctrlKey === true && e.shiftKey === false) {
                    // console.log("CTRL + > pressed");
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL + > pressed");
                    // console.log(heldKeys.current[e.keyCode]);
                    let resizeBy = resizeStep * heldKeys.current[e.keyCode][1];
                    if (previousAnnotationRef.current.annotation_status != TRANSCRIPTION_LEVEL && lastSelectedRegion.current != null) {
                        let children = document.querySelectorAll(`[data-id=${lastSelectedRegion.current.id}] textarea`);
                        if (previousAnnotationRef.current.annotation_status == PHONEME_LEVEL && children[0].id.endsWith('txt')) {
                        } else {
                            lastSelectedRegion.current.onResize(Math.min(resizeBy, lastSelectedRegion.current.end - lastSelectedRegion.current.start), 'start');
                        }
                    }
                } else if (e.keyCode === 188 && e.ctrlKey === true && e.shiftKey === true) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL + SHIFT + < pressed");
                    // console.log(heldKeys.current[e.keyCode]);
                    let resizeBy = resizeStep * heldKeys.current[e.keyCode][1];
                    if (previousAnnotationRef.current.annotation_status != TRANSCRIPTION_LEVEL && lastSelectedRegion.current != null) {
                        let children = document.querySelectorAll(`[data-id=${lastSelectedRegion.current.id}] textarea`);
                        if (previousAnnotationRef.current.annotation_status == PHONEME_LEVEL && children[0].id.endsWith('txt')) {
                        } else {
                            lastSelectedRegion.current.onResize(-Math.min(resizeBy, lastSelectedRegion.current.end - lastSelectedRegion.current.start));
                        }
                    }
                } else if (e.keyCode === 190 && e.ctrlKey === true && e.shiftKey === true) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("CTRL + SHIFT + > pressed");

                    let resizeBy = resizeStep * heldKeys.current[e.keyCode][1];
                    // console.log(heldKeys.current[e.keyCode], resizeBy, lastSelectedRegion.current);
                    if (previousAnnotationRef.current.annotation_status != TRANSCRIPTION_LEVEL && lastSelectedRegion.current != null) {
                        let children = document.querySelectorAll(`[data-id=${lastSelectedRegion.current.id}] textarea`);
                        if (previousAnnotationRef.current.annotation_status == PHONEME_LEVEL && children[0].id.endsWith('txt')) {
                        } else {
                            lastSelectedRegion.current.onResize(Math.min(resizeBy, wavesurfer.current.getDuration() - lastSelectedRegion.current.end));
                        }
                    }
                } else if (e.keyCode === 188 && e.shiftKey === true && e.ctrlKey === false) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("SHIFT + < pressed");
                    let dragBy = resizeStep * heldKeys.current[e.keyCode][1];
                    if (previousAnnotationRef.current.annotation_status < TRANSCRIPTION_LEVEL && lastSelectedRegion.current != null) {
                        lastSelectedRegion.current.onDrag(-Math.min(dragBy, lastSelectedRegion.current.start));
                    }
                } else if (e.keyCode === 190 && e.shiftKey === true && e.ctrlKey === false) {
                    e.preventDefault();
                    // if (heldKeys.current[e.keyCode]) {
                    //     return;
                    // }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    // console.log("SHIFT + > pressed");
                    let dragBy = resizeStep * heldKeys.current[e.keyCode][1];
                    if (previousAnnotationRef.current.annotation_status < TRANSCRIPTION_LEVEL && lastSelectedRegion.current != null) {
                        lastSelectedRegion.current.onDrag(Math.min(dragBy, wavesurfer.current.getDuration() - lastSelectedRegion.current.end));
                    }
                }
                else if (e.key === "ArrowLeft" && e.ctrlKey === true && e.shiftKey == false && e.altKey == false && !heldKeys.current[66][0]) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + LEFT ARROW pressed");
                    await focusOnCurrentRegion((await getPreviousRegion()).previousRegion, false, true)
                } else if (e.key === "ArrowRight" && e.ctrlKey === true && e.shiftKey == false && e.altKey == false && !heldKeys.current[66][0]) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + RIGHT ARROW pressed");
                    await focusOnCurrentRegion((await getNextRegion()).nextRegion, false, true);
                } else if (e.key === "ArrowLeft" && e.ctrlKey === true && e.shiftKey == true && e.altKey == false && !heldKeys.current[66][0]) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + SHIFT + LEFT ARROW pressed");
                    await focusOnCurrentRegion((await getPreviousRegion()).previousRegion, true, true)
                } else if (e.key === "ArrowRight" && e.ctrlKey === true && e.shiftKey == true && e.altKey == false && !heldKeys.current[66][0]) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + SHIFT + RIGHT ARROW pressed");
                    await focusOnCurrentRegion((await getNextRegion()).nextRegion, true, true);
                }
                else if (e.keyCode == 13 && e.shiftKey == false) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("ENTER pressed");
                    await focusOnCurrentRegion(null, true, true)
                }
                else if (e.keyCode == 27) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("ESC pressed");
                    if (lastSelectedRegion.current != null) {
                        deFocusCurrentRegion(true)
                    }
                }
                else if (e.key == "4" && e.ctrlKey == true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + 4 pressed");
                    wavesurfer.current.skipBackward(seekStep);
                }
                else if (e.key == "6" && e.ctrlKey == true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + 6 pressed");
                    wavesurfer.current.skipForward(seekStep);
                }
                else if (e.key === "0" && e.ctrlKey === true && e.shiftKey === false && e.altKey === false) {
                    // if (viewOnly.current) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];
                    console.log("CTRL + 0 pressed");

                    if (openInvalidDialogRef.current) {
                        console.log("INVALID !!!");
                        const audioAnnotation: any = {
                            id: randomString(),
                            start: 0,
                            end: wavesurfer.current.getDuration(),
                            text: { "verdict": "" },
                            phoneme: {},
                            tagList: ['NA'],
                            speakerNo: "1"
                        }
                        console.log(audioAnnotation);
                        await uploadAnnotation([audioAnnotation], true);
                        openInvalidDialogRef.current = false;
                        setOpenInvalidDialog(false);
                    } else {
                        openInvalidDialogRef.current = true;
                        setOpenInvalidDialog(true);
                    }
                    // }
                }
                else if (e.keyCode == 46 && e.ctrlKey === true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + DELETE pressed");
                    deleteRegionProperly();
                }
                else if (e.keyCode === 83 && e.ctrlKey === true && e.altKey == false && e.shiftKey == false) {
                    // save
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + S pressed : SAVE");
                    saveOrSubmit(false)
                }
                else if (e.keyCode === 88 && e.ctrlKey === true && e.altKey == false && e.shiftKey == true) {
                    // submit
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + SHIFT + X pressed : SUBMIT");
                    saveOrSubmit(true)
                }
                else if (e.keyCode === 107 && e.ctrlKey === true) {
                    //zoom '+'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + pressed, ZOOM +");

                    setZoom('+')
                } else if (e.keyCode === 109 && e.ctrlKey === true) {
                    //zoom '-'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL - pressed, ZOOM -");

                    setZoom('-')
                } else if (e.keyCode === 219 && e.ctrlKey === true) {
                    //playback '-'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL [ pressed, playback rate -");

                    wavesurfer.current.setPlaybackRate(Math.max(minPlaybackRate, wavesurfer.current.getPlaybackRate() - playbackRateStep))
                } else if (e.keyCode === 221 && e.ctrlKey === true) {
                    //playback '+'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL ] pressed, playback rate +");

                    wavesurfer.current.setPlaybackRate(Math.min(maxPlaybackRate, wavesurfer.current.getPlaybackRate() + playbackRateStep))
                } else if (e.keyCode === 220 && e.ctrlKey === true && e.shiftKey === false) {
                    //playback speed 'RESET'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL | pressed, playback rate RESET");

                    wavesurfer.current.setPlaybackRate(1)
                } else if (e.keyCode === 82 && e.ctrlKey === true) {
                    //playback 'RESET'
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    console.log("CTRL + R pressed, playback RESET");

                    wavesurfer.current.stop();
                    wavesurfer.current.seekAndCenter(0);

                    setPlaying(false);
                    setPlayingRegion(false);
                    setPlayingLoop(false);

                    playUniversal.current = false;
                    playRegion.current = false;
                    playRegionLoop.current = false;

                    if (lastSelectedRegion.current) {
                        deFocusCurrentRegion(false)
                    }
                }
                else if (e.ctrlKey === true && e.altKey === false && e.shiftKey === false && e.keyCode === 66) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];
                }
                else if (e.ctrlKey === true && e.altKey === false && e.shiftKey === false && e.key === "ArrowRight" && heldKeys.current[66][0]) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    if (previousAnnotationRef.current.annotation_status == SENTENCE_LEVEL) {
                        console.log("CTRL + B + RIGHT ARROW pressed: Merge Next");
                        await merge(null, 1);
                    }
                }
                else if (e.ctrlKey === true && e.altKey === false && e.shiftKey === false && e.key === "ArrowLeft" && heldKeys.current[66][0]) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                    if (previousAnnotationRef.current.annotation_status == SENTENCE_LEVEL) {
                        console.log("CTRL + B + LEFT ARROW pressed: Merge Previous");
                        await merge(null, -1);
                    }
                }
                else if (e.keyCode === 65 && e.ctrlKey === true && e.shiftKey === true) {
                    let status = previousAnnotationRef.current.annotation_status
                    if (status < TRANSCRIPTION_LEVEL) {
                        e.preventDefault();
                        // if (heldKeys.current[e.keyCode][0]) {
                        //     return;
                        // }
                        heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                        // console.log("CTRL + SHIFT + A pressed");
                        let dragBy = resizeStep * heldKeys.current[e.keyCode][1];

                        let sortedFilteredRegions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                            return a.start - b.start;
                        }).filter((region: any) => {
                            return region.end <= wavesurfer.current.getCurrentTime();
                        });

                        sortedFilteredRegions.forEach((region: any) => {
                            region.onDrag(-Math.min(dragBy, sortedFilteredRegions[0].start));
                        })
                    }
                }
                else if (e.keyCode === 83 && e.ctrlKey === true && e.shiftKey === true) {
                    let status = previousAnnotationRef.current.annotation_status
                    if (status < TRANSCRIPTION_LEVEL) {
                        e.preventDefault();
                        // if (heldKeys.current[e.keyCode][0]) {
                        //     return;
                        // }
                        heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                        // console.log("CTRL + SHIFT + S pressed");
                        let dragBy = resizeStep * heldKeys.current[e.keyCode][1];

                        let sortedFilteredRegions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                            return a.start - b.start;
                        }).filter((region: any) => {
                            return region.end <= wavesurfer.current.getCurrentTime();
                        });

                        sortedFilteredRegions.forEach((region: any) => {
                            region.onDrag(Math.min(dragBy, wavesurfer.current.getCurrentTime() - sortedFilteredRegions[sortedFilteredRegions.length - 1].end));
                        })
                    }
                }
                else if (e.keyCode === 68 && e.ctrlKey === true && e.shiftKey === true) {
                    let status = previousAnnotationRef.current.annotation_status
                    if (status < TRANSCRIPTION_LEVEL) {
                        e.preventDefault();
                        // if (heldKeys.current[e.keyCode][0]) {
                        //     return;
                        // }
                        heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                        // console.log("CTRL + SHIFT + D pressed");
                        let dragBy = resizeStep * heldKeys.current[e.keyCode][1];

                        let sortedFilteredRegions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                            return a.start - b.start;
                        }).filter((region: any) => {
                            return region.start >= wavesurfer.current.getCurrentTime();
                        });

                        sortedFilteredRegions.forEach((region: any) => {
                            region.onDrag(-Math.min(dragBy, sortedFilteredRegions[0].start - wavesurfer.current.getCurrentTime()));
                        })
                    }
                }
                else if (e.keyCode === 70 && e.ctrlKey === true && e.shiftKey === true) {
                    let status = previousAnnotationRef.current.annotation_status
                    if (status < TRANSCRIPTION_LEVEL) {
                        e.preventDefault();
                        // if (heldKeys.current[e.keyCode][0]) {
                        //     return;
                        // }
                        heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                        // console.log("CTRL + SHIFT + F pressed");
                        let dragBy = resizeStep * heldKeys.current[e.keyCode][1];

                        let sortedFilteredRegions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                            return a.start - b.start;
                        }).filter((region: any) => {
                            return region.start >= wavesurfer.current.getCurrentTime();
                        });

                        sortedFilteredRegions.forEach((region: any) => {
                            region.onDrag(Math.min(dragBy, wavesurfer.current.getDuration() - sortedFilteredRegions[sortedFilteredRegions.length - 1].end));
                        })
                    }
                } else if (e.keyCode === 80 && e.ctrlKey === true && e.altKey === true) {
                    e.preventDefault();
                    if (heldKeys.current[e.keyCode][0]) {
                        return;
                    }
                    heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];
                    console.log("CTRL + ALT + P pressed");

                    let status = previousAnnotationRef.current.annotation_status

                    if (status == TRANSCRIPTION_LEVEL) {
                        if (pairingTextboxEnabled.current) {
                            pairingTextboxEnabled.current = false;
                        } else {
                            pairingTextboxEnabled.current = true;
                        }
                    }
                    // else if (status == PHONEME_LEVEL) {
                    //     if (pairingRegionEnabled.current) {
                    //         pairingRegionEnabled.current = false;
                    //     } else {
                    //         pairingRegionEnabled.current = true;
                    //     }
                    // }
                }
                // else if (e.keyCode === 83 && e.ctrlKey === true && e.altKey == true) {
                //     //playback 'RESET'
                //     e.preventDefault();
                //     if (heldKeys.current[e.keyCode][0]) {
                //         return;
                //     }
                //     heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                //     console.log("CTRL + ALT + S pressed, Toggle Spectrogram");

                //     wavesurfer.current.spectrogram.destroy();
                // }

                // else if (e.keyCode === 87 && e.altKey === true) {
                //     //playback 'RESET'
                //     e.preventDefault();
                //     if (heldKeys.current[e.keyCode][0]) {
                //         return;
                //     }
                //     heldKeys.current[e.keyCode] = [true, heldKeys.current[e.keyCode][1] + incrementOnKeyPress];

                //     console.log("ALT + W pressed, Toggle Break Word");

                //     let status = previousAnnotationRef.current.annotation_status;
                //     let unlock = (status == 0 || status == 2);

                //     if (wordBreakEnabled.current) {
                //         wordBreakEnabled.current = false;
                //         Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                //             let textBoxElement = status == 0 ? document.getElementById(`${region.id}txt`) : status == 1 ? document.getElementById(`${region.id}phn`) : null;
                //             if (textBoxElement != null) {
                //                 textBoxElement.style.wordBreak = "normal"
                //             }
                //         });
                //     } else {
                //         wordBreakEnabled.current = true;
                //         Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                //             let textBoxElement = status == 0 ? document.getElementById(`${region.id}txt`) : status == 1 ? document.getElementById(`${region.id}phn`) : null;
                //             if (textBoxElement != null) {
                //                 textBoxElement.style.wordBreak = "break-all"
                //             }
                //         });
                //     }
                // }
                else if ((e.key === "ArrowRight" || e.key === "ArrowLeft") && e.altKey === true) {
                    e.preventDefault();
                }
            });

            document.addEventListener('keyup', async (e: any) => {
                if (heldKeys.current[e.keyCode][0] == false) {
                    return;
                }

                heldKeys.current[e.keyCode] = [false, 0];

                // console.log("HELD KEYS: ", heldKeys);

                if (e.keyCode === 188 || e.keyCode === 190) {
                    // console.log("< or > UPPPPPPPP !!!");
                    if (lastSelectedRegion && lastSelectedRegion.current != null) {
                        let region = lastSelectedRegion.current;

                        if (!(await isChildlessRegion(region))) {
                            if (status == PHONEME_LEVEL) {
                                let parentRegion = wavesurfer.current.regions.list[region.id.split('-').slice(0, 2).join('-')];
                                let prevRegion = (await getPreviousRegion(region))['previousRegion'];
                                let nextRegion = (await getNextRegion(region))['nextRegion'];

                                let siblingCount = 0

                                for await (const regionn of Object.values(wavesurfer.current.regions.list)) {
                                    let reg: any = regionn
                                    if (reg.id.startsWith(region.id.split('-').slice(0, 2).join('-') + '-')) {
                                        siblingCount += 1;
                                    }
                                }
                                console.log("Sibling Count: ", siblingCount);

                                let firstChild = parseInt(region.id.split('-')[2]) == 1 ? true : false;
                                let lastChild = parseInt(region.id.split('-')[2]) == siblingCount ? true : false

                                // if (firstChild && region.start != parentStart) {
                                //     region.update({
                                //         start: parentStart
                                //     })
                                // }
                                // if (lastChild && region.end != parentEnd) {
                                //     region.update({
                                //         end: parentEnd
                                //     })
                                // }

                                if (firstChild) {
                                    if (region.start < prevRegion.end) {
                                        if (region.id != prevRegion.id && parentRegion.id != prevRegion.id) {
                                            region.update({
                                                start: prevRegion.end
                                            })
                                        }
                                    }
                                    parentRegion.update({
                                        start: region.start,
                                        // end: region.end
                                    })
                                }
                                if (lastChild) {
                                    if (region.end > nextRegion.start) {
                                        if (region.id != nextRegion.id && parentRegion.id != nextRegion.id) {
                                            region.update({
                                                end: nextRegion.start
                                            })
                                        }
                                    }
                                    parentRegion.update({
                                        // start: region.start,
                                        end: region.end
                                    })
                                }

                                if (!firstChild) {
                                    let prevSibling = wavesurfer.current.regions.list[`${region.id.split('-').slice(0, 2).join('-')}-${parseInt(region.id.split('-')[2]) - 1}`];

                                    if (region.start <= prevSibling.start) {
                                        region.update({
                                            start: prevSibling.end
                                        })
                                    }

                                    if (pairingRegionEnabled.current) {
                                        if (region.start != prevSibling.end) {
                                            prevSibling.update({
                                                end: region.start
                                            })
                                        }
                                    }
                                }
                                if (!lastChild) {
                                    let nextSibling = wavesurfer.current.regions.list[`${region.id.split('-').slice(0, 2).join('-')}-${parseInt(region.id.split('-')[2]) + 1}`];

                                    if (region.end >= nextSibling.end) {
                                        region.update({
                                            end: nextSibling.start
                                        })
                                    }

                                    if (pairingRegionEnabled.current) {
                                        if (region.end != nextSibling.start) {
                                            nextSibling.update({
                                                start: region.end
                                            })
                                        }
                                    }
                                }
                            }
                            else if (status == WORD_LEVEL) {
                                let sortedRegions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                                    return a.start - b.start;
                                });
                                let regionIndex = -1;
                                for (let i = 0; i < sortedRegions.length; i++) {
                                    if (region.id == sortedRegions[i].id) {
                                        regionIndex = i;
                                        break;
                                    }
                                }
                                let firstChild = regionIndex == 0 ? true : false;
                                let lastChild = regionIndex == sortedRegions.length - 1 ? true : false;

                                if (!firstChild) {
                                    let prevSibling = wavesurfer.current.regions.list[sortedRegions[regionIndex - 1].id];

                                    if (region.start <= prevSibling.start) {
                                        region.update({
                                            start: prevSibling.end
                                        })
                                    }
                                }

                                if (!lastChild) {
                                    let nextSibling = wavesurfer.current.regions.list[sortedRegions[regionIndex + 1].id];

                                    if (region.end >= nextSibling.end) {
                                        region.update({
                                            end: nextSibling.start
                                        })
                                    }
                                }
                            }
                            if (status < PHONEME_LEVEL) {
                                await shiftAndRemove(region);
                            }
                        }
                    }
                    // e.preventDefault();
                    // if (heldKeys.current[e.keyCode][0]) {
                    //     return;
                    // }
                    // heldKeys.current[e.keyCode] = true;

                    // console.log("CTRL + < pressed");
                    // if (lastSelectedRegion.current != null && (lastSelectedRegion.current.start - resizeStep > 0)) {
                    //     lastSelectedRegion.current.onResize(-resizeStep, 'start');
                    // }
                }
            });

            // window.addEventListener("error", (event) => {
            //     store.dispatch<any>(setErrorAction({ message: event.message }));
            // });

            // window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
            //     store.dispatch<any>(setErrorAction({ message: event.reason.message }));
            // });

            loadWaveSurfer(graphemeTierArray, phonemeTierArray);
        }
    }, [previousAnnotation]);

    // const getCanvasWidthHeight = () => {
    //     let canvas: any = document.querySelectorAll('canvas');

    //     console.log("Canvas: ", canvas);
    //     console.log("Canvas Length: ", canvas.length);

    //     let canvasWidth = 0;
    //     let canvasHeight = 0;

    //     for (let i = 0; i < canvas.length; i++) {
    //         //@ts-ignore
    //         canvas[i].style.height = `${0.37 * $(waveformRef.current).height()}px`;

    //         if (canvas[i].style.zIndex === "2") {
    //             //@ts-ignore
    //             canvasWidth += $(canvas[i]).width();
    //             //@ts-ignore
    //             canvasHeight = $(canvas[i]).height();
    //         }
    //     }
    //     console.log("Canvas with Z-Index 2 -> Width-Height: ", canvasWidth, canvasHeight);
    //     return [canvasWidth, canvasHeight]
    // }

    const getAllAnnotations = async () => {
        // setLoading(true)
        // let logedUser: any = await getLoggedInUserId();

        // let loggedUserId: any = await getLoggedInUserId();
        //let annotatorPickObj = {"annotator_id":loggedUserId,"speech_id":id};

        let annotatorPickObj = { "annotator_id": userInfo.current.userId, "speech_id": localStorage.getItem("speech_id") ? localStorage.getItem("speech_id") : "" };

        //let res = await pick(annotatorPickObj);

        // let res = await savedAnnotationWork(annotatorPickObj);

        // console.log("M res: ", res);

        // if (res.status === 200 || res.status === 201) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(annotatorPickObj);

        var requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            // redirect: 'follow'
        };

        try {
            let response = await fetch("https://amarkantho.revesoft.com:5106/saved-work/", requestOptions)
            if (response.status == 200 || response.status == 201) {
                let result = await response.json()
                // ToastSuccessMsg(result.message);
                console.log("HRE WE ARE !!! ", result)
                setPreviousAnnotation(result);
                return true;
            } else if (response.status == 400) {
                let result = await response.json()
                ToastFailedMsg(result.message)
                window.location.replace(window.location.origin + `/annotation-task`)
                return false;
            } else if (response.status == 403) {
                let result = await response.json()
                ToastFailedMsg(result.message)
                localStorage.removeItem('speech_id')
                window.location.replace(window.location.origin + `/annotation-task`)
                return false;
            } else {
                let result = await response.json()
                console.log('error', result);
                setLoading(false);
                setIsLoading(false);
                setError(result.message);
                // alert(`Response Status: ${res.status}`);
                // history.push('/')
                // history.go(0)
                // window.location.replace(window.location.origin + `/annotation-task`)
                ToastFailedMsg(result.message);
                return false;
            }
        } catch (error: any) {
            console.log('error', error);
            setLoading(false);
            setIsLoading(false);
            setError(error.message);
            // alert(`Response Status: ${res.status}`);
            // history.push('/')
            // history.go(0)
            // window.location.replace(window.location.origin + `/annotation-task`)
            ToastFailedMsg(error.message);
            return false;
        }


        // return result;
        // let data = await res.json();
        // console.log("GOT'EM !!! ", data);    
        // }
        // else {
        //     // await loadSurfer([],true);
        //     // alert(`Response Status: ${res.status}`);
        //     history.push('/')
        //     history.go(0)
        // }
    }

    const drawSavedRegions = async (graphemeTierArray: any, phonemeTierArray: any) => {
        console.log("Inside Draw Saved Region ...");
        console.log("Annotation Status: ", previousAnnotationRef.current.annotation_status);
        let status = previousAnnotationRef.current.annotation_status;

        console.log("GRAPHEME TIER ARRAY: ", graphemeTierArray);
        console.log("PHONEME TIER ARRAY: ", phonemeTierArray);

        let annotations = previousAnnotationRef.current.annotation

        for (let i = 0; i < annotations.length; i++) {
            if (graphemeTierArray[i] == null || (status > WORD_LEVEL && phonemeTierArray[i] == null)) {
                continue;
            }
            let unlock = (status < TRANSCRIPTION_LEVEL)
            wavesurfer.current.addRegion({
                id: annotations[i].id,
                start: annotations[i].start,
                end: annotations[i].end,
                drag: unlock,
                resize: unlock,
                scroll: true,
                // loop: true,
                color: `rgba(${unlock ? 0 : 255}, ${unlock ? 255 : 0}, 0, 0)`,
                // showTooltip: false
                // attributes: {
                //     label: annotations[i].id
                // },
            });

            try {
                // if (SHOW_GRAPHEME_AFTER_WORD_LEVEL) {
                await makeTextBox(graphemeTierArray[i])
                // }
            } catch (err: any) {
                console.error(`GRAPHEME BOX COULD NOT BE CREATED ! ${err.message}`);
            }

            if (status == TRANSCRIPTION_LEVEL) {
                try {
                    console.log(i, phonemeTierArray[i]);

                    await makeTextBox(phonemeTierArray[i]);
                } catch (err: any) {
                    console.error(`PHONEME BOX COULD NOT BE CREATED ! ${err.message}`);
                }
            } else if (status == PHONEME_LEVEL) {
                for (let j = 0; j < phonemeTierArray[i].length; j++) {
                    wavesurfer.current.addRegion({
                        id: `${phonemeTierArray[i][j].id.split('|')[0]}`,
                        start: phonemeTierArray[i][j].start,
                        end: phonemeTierArray[i][j].end,
                        drag: false,
                        resize: true,
                        scroll: true,
                        // color: `rgba(${(1 - phonemeTierArray[i][j].confidence) * 255}, ${phonemeTierArray[i][j].confidence * 255}, 0, 0.125)`,
                        // attributes: {
                        //     label: `${phonemeTierArray[i][j].id.split('|')[0]}`
                        // },
                    });
                    await makeTextBox(phonemeTierArray[i][j]);
                }
            }
            // console.log("IS IT SYNC NOW ???");
        }
    }

    const isOutOfViewport = function (elem: any, wave: Boolean) {

        // Get element's bounding
        // console.log(elem);
        var bounding = elem.getBoundingClientRect();

        // Check if it's out of the viewport on each side
        let out: any = {};
        // out.top = bounding.top < 0;
        // out.left = bounding.left < 0;
        // out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
        if (wave) {
            out.left = bounding.right < 0;
        } else {
            out.left = (bounding.left < 0) || (bounding.left > (window.innerWidth || document.documentElement.clientWidth));
        }

        if (wave) {
            out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
        } else {
            out.right = (bounding.right < 0) || (bounding.right > (window.innerWidth || document.documentElement.clientWidth));
        }

        // out.any = out.top || out.left || out.bottom || out.right;
        // out.all = out.top && out.left && out.bottom && out.right;

        return out;

    };

    const setSelectionRange = (input: any, selectionStart: any, selectionEnd: any) => {
        if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        }
        else if (input.createTextRange) {
            var range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
    }

    const setCaretToPos = (input: any, pos: any) => {
        // 0 to Focus at Start, -1 to focus at End
        setSelectionRange(input, pos, pos);
    }

    const resetCursor = async (txtElement: any) => {
        if (txtElement.setSelectionRange) {
            txtElement.focus();
            txtElement.setSelectionRange(0, 0);
        } else if (txtElement.createTextRange) {
            let range = txtElement.createTextRange();
            range.moveStart('character', 0);
            range.select();
        }
    }

    const isTextSelected = async (input: any) => {
        let startPos = input.selectionStart;
        let endPos = input.selectionEnd;
        let selection = document.getSelection()?.toString();

        if (selection && selection.length != 0) {
            console.log("SELECTION !!! ", selection);
            return selection;
        }
        if (input.value.substring(startPos, endPos).length != 0) {
            console.log("SELECTION: ", input.value.substring(startPos, endPos));
            return selection;
        }
        return null;
    }

    const makeTextBox = async (originalTextBox: any, numberOfChunks: any = 1) => {
        let status = previousAnnotationRef.current.annotation_status;
        // if (status > 0) {
        //     return;
        // }
        console.log("INSIDE MAKE TEXT BOX ...");
        console.log("originalTextBox: ", originalTextBox);

        //@ts-ignore
        let tierTop = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * originalTextBox.tierId}px`;

        let pairedRegion: any = document.querySelector('[data-id="' + originalTextBox.id.split('|')[0] + '"]');
        console.log('Paired Region', pairedRegion);

        // if (previousAnnotationRef.current.annotation_status == 1) {
        //     pairedRegion.style.display = 'flex';
        //     pairedRegion.style.flexDirection = 'row';
        //     // pairedRegion.style.alignItems = 'center';
        //     pairedRegion.style.justifyContent = 'space-between';
        // }

        let textboxParent = document.createElement('div');
        textboxParent.className = "parentDiv";
        textboxParent.id = originalTextBox.id + "div";
        // if (previousAnnotationRef.current.annotation_status == 1) {
        //     textboxParent.id = originalTextBox.id.split('|')[0] + '|' + originalTextBox.id.split('|')[2] + "div";
        // }

        textboxParent.style.display = (status > WORD_LEVEL && originalTextBox.tierId == 1 && !SHOW_GRAPHEME_AFTER_WORD_LEVEL) ? 'none' : 'block';
        textboxParent.style.position = 'absolute';
        // textboxParent.style.zIndex = "15";

        //@ts-ignore
        textboxParent.style.top = tierTop;

        // if (previousAnnotationRef.current.annotation_status != 1) {
        textboxParent.style.left = pairedRegion.style.left + 'px';
        // }
        // else {
        //     let index = parseInt(originalTextBox.id.split('|')[1])
        //     textboxParent.style.left = `${100 * index / numberOfChunks}%`
        // }

        textboxParent.style.width = `${100 / numberOfChunks}%`; // $(pairedRegion).width() + 'px';
        textboxParent.style.height = tierDivHeight + 'px';
        // textboxParent.style.display = 'flex';
        // textboxParent.style.flexDirection = 'row';
        // textboxParent.style.alignItems = 'center';
        // textboxParent.style.justifyContent = 'space-between';

        let textBox: any = document.createElement('textarea');
        textBox.setAttribute('spellcheck', "false");
        // textBox.className = "resizable-textbox";

        // textBox.type = 'text';
        textBox.style.border = '1px solid #006B43'
        // textBox.style.backgroundColor = "FloralWhite"

        if ((status <= WORD_LEVEL) || (status == TRANSCRIPTION_LEVEL && originalTextBox.tierId == 2)) {
            // textBox.disabled = false;
            textBox.readOnly = false;
            textBox.style.backgroundColor = "FloralWhite"; // '#FDF9F7';
        } else {
            // textBox.disabled = true;
            textBox.readOnly = true;
            textBox.style.backgroundColor = '#ACACAC';
        }
        textBox.style.overflow = "hidden"
        textBox.style.textAlign = "left" // justify
        // textBox.style.wordBreak = "break-all"
        textBox.style.wordWrap = "break-word"
        textBox.style.resize = "none"

        // element.style.resize = 'none';

        textBox.style.display = 'block';
        // textBox.style.zIndex = "15";

        textBox.style.top = textboxParent.style.top;
        textBox.style.left = textboxParent.style.left;
        textBox.style.width = '100%'; // textboxParent.style.width;

        textBox.id = originalTextBox.id;

        console.log("originalTextBox.text: ", originalTextBox.text);

        textBox.value = originalTextBox.text["verdict"] || originalTextBox.text["verdict"] == "" ? originalTextBox.text["verdict"] : originalTextBox.text["original"] ? originalTextBox.text["original"] : originalTextBox.text["google_normalized"] != '' && originalTextBox.text["google_normalized"] != undefined ? originalTextBox.text["google_normalized"] : originalTextBox.text["deep_phonemizer"] ? originalTextBox.text["deep_phonemizer"] : originalTextBox.text["reve"];

        let existingTextBoxes = document.querySelectorAll("textarea");
        if (existingTextBoxes.length == 0) {
            let lastFontSize = localStorage.getItem('fontSize');
            if (lastFontSize != undefined) {
                textBox.style.fontSize = lastFontSize + 'px';
            } else {
                textBox.style.fontSize = fontSizeDefault + 'px';
            }
        } else {
            textBox.style.fontSize = existingTextBoxes[0].style.fontSize;
        }
        textBox.style.fontWeight = 'bold';

        textBox.style.color = "black";
        textBox.style.minHeight = tierDivHeight + 'px';
        textBox.style.height = tierDivHeight + 'px';
        textBox.style.paddingLeft = "7.5px"
        textBox.style.paddingRight = "5px"

        console.log("textbox.id: ", textBox.id);

        console.log("originalTextBox.text : ", originalTextBox.text);
        console.log("textbox.value: ", textBox.value);


        textBox.addEventListener("mousedown", async (e: any) => {
            console.log("TextBox Mousedown !!! ", e);
            e.stopPropagation();
            let status = previousAnnotationRef.current.annotation_status;

            if (e.buttons == 1) {
                await removeLastChildlessRegion(0);
                let containingRegion = await getContainingRegion(e.target);
                toggleTagList(containingRegion.id, "none");
                toggleSpeakerList(containingRegion.id, "none");

                if (lastSelectedRegion.current == null || lastSelectedRegion.current.id != containingRegion.id) {
                    // console.log("HERERERERE");
                    await focusOnCurrentRegion(containingRegion, false, true);
                }

                let children = document.querySelectorAll(`[data-id=${containingRegion.id}] textarea`);

                for (let i = 0; i < children.length; i++) {
                    if (children[i].id !== e.target.id) {
                        (children[i] as HTMLTextAreaElement).style.height = tierDivHeight + 'px';
                    }
                }
                // e.target.style.height = 0 + 'px';
                e.target.style.height = Math.max(parseFloat(e.target.style.height), e.target.scrollHeight) + 'px';

                // let handles = document.querySelectorAll('[data-id="' + e.target.id.split('|')[0] + '"] > handle')
                // console.log("HANDLES: ", handles);

                // handles.forEach((handle: any) => {
                //     handle.style.width = '1px';
                //     handle.style.height = `100%`;
                //     handle.style.top = `0px`;
                // })
            }
            else if (e.buttons == 2) {
                e.preventDefault()
            }
        });

        textBox.addEventListener("click", async (e: any) => {
            e.stopPropagation();
        });

        textBox.addEventListener("mouseleave", async (e: any) => {
            if ((document.activeElement === e.target && e.buttons != 0) || (await isTextSelected(e.target))) {
                wavesurfer.current.enableDragSelection({
                    drag: false,
                    // slop: 5,
                    // loop: false,
                });
                // e.preventDefault();
                // e.stopPropagation();
            }
            // console.log("TEXTBOX MOUSE LEAVE: ", e);
        });

        textBox.addEventListener("input", (e: any) => {
            // console.log("TextBox Selection !!! ", e.target.scrollHeight, e.target.style.height);
            e.stopPropagation();
            // e.target.style.height = 0 + 'px';
            e.target.style.height = Math.max(parseFloat(e.target.style.height), e.target.scrollHeight) + 'px';

            let status = previousAnnotationRef.current.annotation_status;
            if (status == TRANSCRIPTION_LEVEL && pairingTextboxEnabled.current) {
                let pairedGraphemeBox = document.getElementById(e.target.id.slice(0, -3) + 'txt') as HTMLTextAreaElement;
                let pairedPhonemeBoxesList = graphemePhonemeMapRef.current[pairedGraphemeBox.value]

                pairedPhonemeBoxesList.forEach((phonemeBoxId: any) => {
                    (document.getElementById(phonemeBoxId) as HTMLTextAreaElement).value = e.target.value;
                });
            }

            if (e.buttons == 1) {
                //
            }
        });

        textBox.addEventListener("wheel", (e: any) => {
            // console.log("WHEEL ", e.target);
            // console.log("TextBox Selection !!! ", e.target.scrollHeight, e.target.style.height);

            let scale = parseFloat(e.target.style.fontSize);

            if (e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();

                // e.deltaY
                scale += e.deltaY * -0.01;

                // Restrict scale
                scale = Math.min(Math.max(fontSizeMin, scale), fontSizeMax);
                localStorage.setItem('fontSize', scale.toString());

                // Apply scale transform
                // el.style.transform = `scale(${scale})`;

                // e.target.style.fontSize = scale + 'px';
                Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                    let children = document.querySelectorAll(`[data-id=${region.id}] textarea`);
                    children.forEach((textBoxElement: any) => {
                        if (textBoxElement != null) {
                            textBoxElement.style.fontSize = scale + 'px';
                            // textBoxElement.style.height = 0 + 'px';
                            // textBoxElement.style.height = textBoxElement.scrollHeight + 'px';
                        }
                    })
                });
            }

            if (e.buttons == 1) {
                //
            }
        });

        textboxParent.appendChild(textBox)
        // console.log("TEXTBOX HEIGHT, ", textBox.style, textBox.scrollHeight);


        // textBox.style.height = 0;
        // textBox.style.height = textBox.scrollHeight + 'px';

        // autocomplete(textBox, trie);
        pairedRegion.appendChild(textboxParent)
        // setWaveformSate(wavesurfer);
    }

    const setZoom = async (sign: any, value: any = null) => {
        // (document.querySelector("wave") as HTMLElement).style.scrollBehavior = "auto";
        // console.log("BEFORE ZOOM: ", wavesurfer.current.params.minPxPerSec);
        let status = previousAnnotationRef.current.annotation_status
        if (value != null) {
            // zoom.current = value;
            setZoomSliderValue(Number(value))
            wavesurfer.current.zoom(Number(value), true);
        } else {
            if (sign == '+') {
                // console.log("+ ", wavesurfer.current.params.minPxPerSec);
                setZoomSliderValue(Math.min(wavesurfer.current.params.minPxPerSec + zoomStep, zoomMax))
                wavesurfer.current.zoom(Math.min(wavesurfer.current.params.minPxPerSec + zoomStep, zoomMax), true);
                // console.log("+ ", wavesurfer.current.params.minPxPerSec);
            } else if (sign == '-') {
                // console.log("- ", wavesurfer.current.params.minPxPerSec);
                setZoomSliderValue(Math.max(wavesurfer.current.params.minPxPerSec - zoomStep, zoomMin))
                wavesurfer.current.zoom(Math.max(wavesurfer.current.params.minPxPerSec - zoomStep, zoomMin), true);
                // console.log("- ", wavesurfer.current.params.minPxPerSec);
            }
        }
        // wavesurfer.current.empty();
        // wavesurfer.current.drawBuffer();
        // wavesurfer.current.timeline.render();

        // wavesurfer.current.drawer.clearWave();
        // wavesurfer.current.drawBuffer();
        // wavesurfer.current.timeline.init();

        // if (wavesurfer.current.spectrogram) {
        //     wavesurfer.current.spectrogram.init();
        // }

        let sumWidth = await adjustCanvasHeight();
        // console.log("Sum Width: ", sumWidth);
        // console.log("AFTER ZOOM: ", wavesurfer.current.params.minPxPerSec);

        if (showSpectrogram) {
            if (status > TRANSCRIPTION_LEVEL) {
                if (wavesurfer.current.params.minPxPerSec < 6000) {
                    wavesurfer.current.spectrogram.init();
                    // wavesurfer.current.spectrogram.render();
                } else {
                    wavesurfer.current.spectrogram.canvas.style.width = Math.max(wavesurfer.current.drawer.getWidth(), wavesurfer.current.getDuration() * wavesurfer.current.params.minPxPerSec) + "px";
                }
            }
        }

        // (document.querySelector("wave") as HTMLElement).style.scrollBehavior = "smooth";
    }

    const getContainingRegion = async (textBox: any) => {
        let regions: any = Object.values(wavesurfer.current.regions.list);

        if (textBox != null) {
            console.log("GET CONTAINING REGION: ", textBox.id);
            for (let i = 0; i < regions.length; i++) {
                if (regions[i].id == textBox.id.split('|')[0]) {
                    return regions[i];
                }
            }
        } else {
            console.log("GET CONTAINING REGION: PLAYHEAD");
            for (let i = 0; i < regions.length; i++) {
                if (wavesurfer.current.getCurrentTime() >= regions[i].start && wavesurfer.current.getCurrentTime() <= regions[i].end) {
                    return regions[i];
                }
            }
            return null;
        }
    }

    const recenterRegion = async (currentRegion: any) => {
        (document.querySelector("wave") as HTMLElement).style.scrollBehavior = "smooth";
        if (wavesurfer.current.getCurrentTime() <= currentRegion.start + 0.05 || wavesurfer.current.getCurrentTime() >= currentRegion.end - 0.05) {
            wavesurfer.current.seekTo(currentRegion.start / wavesurfer.current.getDuration());
        }

        // wavesurfer.current.drawer.recenter((currentRegion.start + currentRegion.end) / (2 * wavesurfer.current.getDuration()));
        let regionElement = (document.querySelector('[data-id="' + currentRegion.id + '"]') as HTMLElement);
        // console.log("SCROLL INTO VIEW !!! ", regionElement);
        console.log("SCROLL INTO VIEW !!!");

        let result = isOutOfViewport(regionElement, false);
        console.log("RESULT: ", result, $(regionElement).width());

        if (result.left == false && result.right == false) {

        } else {
            if ($(regionElement).width() <= (window.innerWidth || document.documentElement.clientWidth)) {
                wavesurfer.current.drawer.recenter((currentRegion.start + currentRegion.end) / (2 * wavesurfer.current.getDuration()));
                // wavesurfer.current.seekAndCenter(currentRegion.start / wavesurfer.current.getDuration());
            }
            else {
                let link = $(regionElement);
                let offset = link.offset();

                let regionTop = offset.top;
                let regionLeft = offset.left;
                console.log("REGION LEFT: ", regionLeft);

                // window.scrollTo(-500, 0);
                document.querySelector("wave")?.scrollBy({
                    left: regionLeft - 60,
                    top: 0,
                    behavior: "smooth"
                });

                // window.scrollTo({  
                //     left: regionLeft,
                //     top: regionTop,
                //     behavior: "smooth"
                // });
            }
        }

        (document.querySelector("wave") as HTMLElement).style.scrollBehavior = "auto";
        // console.log("NOW THEN");
    }

    const deFocusCurrentRegion = async (escPressed: Boolean) => {
        let currentRegion = lastSelectedRegion.current;
        let regionElement = document.querySelector('[data-id="' + currentRegion.id + '"]') as HTMLElement;

        regionElement.style.zIndex = "3";
        regionElement.style.borderLeft = '0px solid rgba(0, 0, 0, 0)';
        regionElement.style.borderRight = '0px solid rgba(0, 0, 0, 0)';

        currentRegion.scroll = false;

        console.log("DeFocus Current Region: ", currentRegion, regionElement);

        let status = previousAnnotationRef.current.annotation_status;
        let unlock = (status < TRANSCRIPTION_LEVEL);

        let textBoxElement = status < TRANSCRIPTION_LEVEL ? document.getElementById(`${currentRegion.id}|txt`) : status >= TRANSCRIPTION_LEVEL ? document.getElementById(`${currentRegion.id}|phn`) : null;

        if (!escPressed || textBoxElement == null || document.activeElement != textBoxElement) {
            lastSelectedRegion.current = null;
            // displayContentRef.current.innerHTML = `<p class="my-0 py-2 bg-secondary text-white text-center"> </p>`;

            // toggleTagButton(currentRegion.id, "none");
            toggleTagList(currentRegion.id, "none");
            toggleSpeakerList(currentRegion.id, "none");
            // toggleSpeakerDiv(currentRegion.id, "none");

            currentRegion.update({
                color: `rgba(0, 0, 0, 0)`
            });
        }

        if (textBoxElement != null) {
            // await mergeAndGenerateChild(region, "check", status, prevState);
            textBoxElement?.blur();
            // document.getSelection()?.empty();
            // window.getSelection().removeAllRanges();
            // textBoxElement.style.height = 0 + 'px';
            textBoxElement.style.height = tierDivHeight + 'px';

            // setCaretToPos(textBoxElement, 0);
            // await resetCursor(textBoxElement);
        }

        // let handles = document.querySelectorAll('[data-id="' + currentRegion.id + '"] > handle')
        // console.log("HANDLES: ", handles);

        // handles.forEach((handle: any) => {
        //     handle.style.width = '1px';
        //     handle.style.height = `${tierDivHeight * 4}px`;
        //     handle.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height()}px`;
        // });
    }

    const focusOnCurrentRegion = async (currentRegion: any, focusChild: Boolean, recenter: Boolean) => {
        if (currentRegion == null) {
            if (lastSelectedRegion.current != null) {
                currentRegion = lastSelectedRegion.current;
            } else {
                currentRegion = await getContainingRegion(null);
                focusChild = false;
            }
        }
        if (currentRegion == null) return;

        // toggleTagButton(currentRegion.id, "block")
        // toggleSpeakerDiv(currentRegion.id, "block")

        let isChildless = await isChildlessRegion(currentRegion)
        if (!isChildless) {
            // displayContentRef.current.style.display = "block"
            // console.log("BEFORE: ", displayContentRef.current.innerHTML);
            // displayContentRef.current.innerHTML = `<p class="my-0 py-2 bg-secondary text-white text-center">${(document.getElementById(`${currentRegion.id}txt`) as HTMLTextAreaElement).value}</p>`;
            // console.log("AFTER: ", displayContentRef.current.innerHTML);
        }
        if (lastSelectedRegion.current != null && lastSelectedRegion.current.id != currentRegion.id) {
            // lastSelectedRegion.current = null;
            await deFocusCurrentRegion(false)
        }
        if (lastSelectedRegion.current == null || currentRegion.id !== lastSelectedRegion.current.id) {
            let regionElement = document.querySelector('[data-id="' + currentRegion.id + '"]') as HTMLElement;

            regionElement.style.zIndex = "30";
            regionElement.style.borderLeft = '0.1px solid rgba(0, 0, 0, 1)'
            regionElement.style.borderRight = '0.1px solid rgba(0, 0, 0, 1)'

            // update the scroll option of the region
            currentRegion.scroll = true;

            currentRegion.update({
                color: selectedRegionColor.current
            });

            console.log("Focus On Current Region: ", currentRegion, regionElement);
        }
        lastSelectedRegion.current = currentRegion;
        let status = previousAnnotationRef.current.annotation_status;

        if (focusChild) {
            // console.log("FOCUS CHILD");

            let textBoxElement = status <= WORD_LEVEL ? document.getElementById(`${currentRegion.id}|txt`) : status == TRANSCRIPTION_LEVEL ? document.getElementById(`${currentRegion.id}|phn`) : null;

            if (textBoxElement != null) {
                if (!playUniversal.current) {
                    textBoxElement.focus();
                    setCaretToPos(textBoxElement, -1);
                }

                // textBoxElement.style.height = 0 + 'px';
                textBoxElement.style.height = `${Math.max(parseFloat(textBoxElement.style.height), textBoxElement.scrollHeight)}px`;
                // await resetCursor(textBoxElement);
            }

            // let handles = document.querySelectorAll('[data-id="' + currentRegion.id + '"] > handle')
            // console.log("HANDLES: ", handles);

            // handles.forEach((handle: any) => {
            //     handle.style.width = '1px';
            //     handle.style.height = `100%`;
            //     handle.style.top = `0px`;
            // })
        }

        if (recenter) {
            await recenterRegion(currentRegion);
            // console.log("WHATTTTTTTT !!!")

            // setTimeout(function () {
            // },100);

            // setTimeout(function () {
            //     window.scrollTo(100, 0);
            // },200);
            // regionElement.scrollIntoView({
            //     // block: "end",
            //     // inline: "start",
            //     behavior: "smooth"
            // });

            // (document.querySelector('[data-id="' + currentRegion.id + '"]') as HTMLElement).scrollLeft = 0

            // (document.querySelector('[data-id="' + currentRegion.id + '"]') as HTMLElement).scrollBy({
            //     top: 0,
            //     left: -(document.querySelector('[data-id="' + currentRegion.id + '"]') as HTMLElement).scrollLeft,
            //     behavior: 'smooth'
            // });
        } else if (isChildless) {
            // wavesurfer.current.seekTo(currentRegion.start / wavesurfer.current.getDuration());
        }

        Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
            if (region.id != currentRegion.id) {
                let regionElement = document.querySelector('[data-id="' + region.id + '"]') as HTMLElement;
                regionElement.style.borderLeft = '0px solid rgba(0, 0, 0, 0)';
                regionElement.style.borderRight = '0px solid rgba(0, 0, 0, 0)';
                region.scroll = false;
                region.update({
                    color: `rgba(0, 0, 0, 0)`,
                });
            }
        });
        // }
    }

    const getPreviousRegion = async (regn: any = null, parentRegion: any = null) => {
        if (regn == null) {
            let regions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                return a.start - b.start;
            });
            let target_index: any = null;
            let selected_region_index = -1;

            for (let i = 0; i < regions.length; i++) {
                if (regions[i].end <= wavesurfer.current.getCurrentTime()) {
                    target_index = i;
                    // break;
                }
            }

            for (let i = 0; i < regions.length; i++) {
                if (lastSelectedRegion.current != null && lastSelectedRegion.current.id == regions[i].id) {
                    selected_region_index = i;
                }
            }

            if (target_index == null) {
                target_index = 0 // regions.length - 1;
            } else if (lastSelectedRegion.current != null) { // && regions[target_index].id == lastSelectedRegion.current.id) {
                target_index = selected_region_index - 1;
                if (target_index < 0) {
                    target_index = 0 // regions.length - 1;
                }
            }

            return { index: target_index, previousRegion: regions[target_index] };
        } else {
            let regions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                return a.start - b.start;
            }).filter((region: any) => document.getElementById(`${region.id}|txt`) == null);
            let target_index: any = null;
            let selected_region_index = -1;

            for (let i = 0; i < regions.length; i++) {
                if (regn.id == regions[i].id) {
                    selected_region_index = i;
                }
            }

            target_index = selected_region_index - 1;
            if (target_index < 0) {
                target_index = 0 // regions.length - 1;
            }

            return { index: target_index, previousRegion: regions[target_index] };
        }
    }

    const getNextRegion = async (regn: any = null, parentRegion: any = null) => {
        if (regn == null) {
            let regions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                return a.start - b.start;
            });
            let target_index: any = null;
            let selected_region_index = -1;

            for (let i = 0; i < regions.length; i++) {
                if (regions[i].start >= wavesurfer.current.getCurrentTime()) {
                    target_index = i;
                    break;
                }
            }

            for (let i = 0; i < regions.length; i++) {
                if (lastSelectedRegion.current != null && lastSelectedRegion.current.id == regions[i].id) {
                    selected_region_index = i;
                }
            }

            if (target_index == null) {
                target_index = regions.length - 1;
            } else if (lastSelectedRegion.current != null) { // && regions[target_index].id == lastSelectedRegion.current.id) {
                target_index = selected_region_index + 1;
                if (target_index == regions.length) {
                    target_index = regions.length - 1;
                }
            }

            return { index: target_index, nextRegion: regions[target_index] };
        } else {
            let regions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                return a.start - b.start;
            }).filter((region: any) => document.getElementById(`${region.id}|txt`) == null);
            let target_index: any = null;
            let selected_region_index = -1;

            for (let i = 0; i < regions.length; i++) {
                if (regn.id == regions[i].id) {
                    selected_region_index = i;
                }
            }

            target_index = selected_region_index + 1;
            if (target_index >= regions.length) {
                target_index = regions.length - 1;
            }

            return { index: target_index, nextRegion: regions[target_index] };
        }
    }

    // document.onmousemove = function (e) {
    //     if (e.shiftKey && e.buttons == 1) {
    //         // alert("Mouse Left and Shift was pressed");
    //         if (lastSelectedRegion.current != null) {
    //             (document.querySelector('[data-id="' + lastSelectedRegion.current.id + '"]') as HTMLElement).style.cursor = "move";
    //             lastSelectedRegion.current.drag = true;
    //         }
    //     }
    // };

    // document.onmouseup = function (e) {
    //     // if (lastSelectedRegion && lastSelectedRegion.current != null) {
    //     //     let regionElement = document.querySelector('[data-id="' + lastSelectedRegion.current.id + '"]') as HTMLElement;
    //     //     if (regionElement != null) {
    //     //         regionElement.style.cursor = "default";
    //     //         lastSelectedRegion.current.drag = false;
    //     //     }
    //     // }
    //     wavesurfer.current.enableDragSelection({
    //         drag: true,
    //         slop: 5,
    //         loop: false,
    //     });
    // }

    const duplicateRegion = async (targetRegion: any = null, duplicateEnd: any) => {
        if (previousAnnotationRef.current.annotation_status <= WORD_LEVEL) {
            console.log("SPLIT DUPLICATE !!!");
            let duplicate_id = `${targetRegion.id.split('—')[0]}—${Date.now()}`;

            // if ((targetRegion != null || lastSelectedRegion.current != null) && previousAnnotationRef.current.annotation_status == 0) {
            let graphemeTierContent: any = {}

            graphemeTierContent["id"] = `${duplicate_id}|txt`;
            graphemeTierContent["tierId"] = 1;
            graphemeTierContent["tierName"] = "grapheme";
            graphemeTierContent["start"] = targetRegion.end;
            graphemeTierContent["end"] = duplicateEnd;
            // graphemeTierContent["text"] = { "verdict": (document.getElementById(`${targetRegion.id}txt`) as HTMLTextAreaElement).value.trim() };
            graphemeTierContent["text"] = { "verdict": "" };

            console.log("GRAPHEME TIER CONTENT: ", graphemeTierContent);

            wavesurfer.current.addRegion({
                id: duplicate_id,
                start: targetRegion.end,
                end: duplicateEnd,
                drag: true,
                resize: true,
                scroll: true,
                color: selectedRegionColor.current,
                // attributes: {
                //     label: duplicate_id
                // },
            });
            await makeTextBox(graphemeTierContent);

            return wavesurfer.current.regions.list[duplicate_id];
            // }
        }
    }

    const isChildlessRegion = async (region: any) => {
        let children = document.querySelectorAll(`[data-id=${region.id}] textarea`);
        // console.log("CHILDREN: ", children);

        if (children.length > 0) {
            return false;
        } else {
            if (tagsRegionMapRef.current[region.id]["tagList"].length == 0) {
                // console.log(tagsRegionMapRef.current[region.id])
                return true;
            } else {
                return false;
            }
        }
    }

    const removeLastChildlessRegion = async (threshold: any) => {
        let targetRegions: any = [];
        // Object.values(wavesurfer.current.regions.list).forEach(async (region: any) => {
        //     if(await isChildlessRegion(region)){
        //         targetRegions.push(region);
        //     }
        // });
        for await (const region of Object.values(wavesurfer.current.regions.list)) {
            if (await isChildlessRegion(region)) {
                targetRegions.push(region);
            }
        };
        console.log("Target Childless Regions: ", targetRegions);

        while (targetRegions.length > threshold) {
            targetRegions.shift().remove()
        }
    }

    const getOverlappedRegions = async (invadingRegion: any) => {
        let status = previousAnnotationRef.current.annotation_status;
        let fullyOverlappedRegions: any = [];
        let partiallyOverlappedRegions: any = [];
        let justTouches: any = false;

        for await (const regionn of Object.values(wavesurfer.current.regions.list)) {
            let region: any = regionn;

            if (region.id != invadingRegion.id) {
                if (region.start >= invadingRegion.start && region.end <= invadingRegion.end) {
                    fullyOverlappedRegions.push(region);
                } else if ((region.start >= invadingRegion.start && region.start < invadingRegion.end) ||
                    (region.end > invadingRegion.start && region.end <= invadingRegion.end) ||
                    (invadingRegion.start >= region.start && invadingRegion.start < region.end) ||
                    (invadingRegion.end > region.start && invadingRegion.end <= region.end)) {
                    let exclude = false
                    if (status == PHONEME_LEVEL) {
                        if (document.getElementById(`${region.id}|txt`)) {
                            exclude = true
                        }
                    }
                    if (!exclude) {
                        partiallyOverlappedRegions.push(region);
                    }
                } else if (region.start == invadingRegion.end ||
                    region.end == invadingRegion.start) {
                    justTouches = true
                }
            }
        };

        return { "partiallyOverlapped": partiallyOverlappedRegions, "fullyOverlapped": fullyOverlappedRegions, "justTouches": justTouches }
    }

    const shiftAndRemove = async (invadingRegion: any) => {
        let status = previousAnnotationRef.current.annotation_status;
        let result: any = await getOverlappedRegions(invadingRegion)

        let fullyOverlappedRegions = result["fullyOverlapped"];
        let partiallyOverlappedRegions = result["partiallyOverlapped"];

        // console.log("FULLY OVERLAPPED REGIONS !!! ", fullyOverlappedRegions);
        // console.log("PARTIALLY OVERLAPPED REGIONS !!! ", partiallyOverlappedRegions);

        if (status == SENTENCE_LEVEL) {
            fullyOverlappedRegions.forEach((region: any) => {
                region.remove();
            });
        } else {
            if (fullyOverlappedRegions.length > 0) {
                partiallyOverlappedRegions = []
            }
        }

        partiallyOverlappedRegions.forEach((region: any) => {
            if ((region.end > invadingRegion.start) && (region.start < invadingRegion.start)) {
                region.onResize(invadingRegion.start - region.end);
            }
            else if ((region.start < invadingRegion.end) && (region.end > invadingRegion.end)) {
                region.onResize(invadingRegion.end - region.start, 'start');
            }
        });
    }

    const merge = async (invadingRegion: any, previousOrNext: any = 0) => {
        if (previousOrNext == -1) {
            // merge previous
            if (lastSelectedRegion.current != null && !(await isChildlessRegion(lastSelectedRegion.current))) {
                let { index, previousRegion } = await getPreviousRegion()
                if (lastSelectedRegion.current.id != previousRegion.id) {
                    let previousTextboxValue = (document.getElementById(`${previousRegion.id}|txt`) as HTMLTextAreaElement).value
                    let currentTextbox = (document.getElementById(`${lastSelectedRegion.current.id}|txt`) as HTMLTextAreaElement)
                    currentTextbox.value = previousTextboxValue + ' ' + currentTextbox.value

                    lastSelectedRegion.current.update({
                        start: previousRegion.start
                    });

                    tagsRegionMapRef.current[previousRegion.id]["tagList"].forEach((tag: any) => {
                        if (!(tagsRegionMapRef.current[lastSelectedRegion.current.id]["tagList"].includes(tag))) {

                            let tagSpan = document.createElement('span')
                            tagSpan.className = 'key m-0 p-0'
                            tagSpan.style.fontSize = "72%"
                            tagSpan.style.fontWeight = "bolder"
                            let targetTagDict = previousAnnotationRef.current.module == 'stt' ? sentenceTagDictSTT : sentenceTagDictTTS
                            tagSpan.title = `${targetTagDict[tag]}`
                            tagSpan.id = tag
                            tagSpan.innerText = `${tag}`

                            let tagSpanClose = document.createElement('span')
                            tagSpanClose.innerHTML = `&nbsp;&times;`
                            tagSpanClose.onclick = (event: any) => {
                                event.stopPropagation()
                                let tagList = tagsRegionMapRef.current[event.target.parentNode.parentNode.id.split('|')[0]]["tagList"]
                                const index = tagList.indexOf(event.target.parentNode.id);
                                if (index > -1) { // only splice array when item is found
                                    tagList.splice(index, 1); // 2nd parameter means remove one item only
                                }
                                document.getElementById(`${event.target.parentNode.parentNode.id.split('|')[0]}|tagDiv`).removeChild(event.target.parentNode)
                                // console.log(event.target.parentNode.id, tagsRegionMapRef.current);
                            }
                            tagSpan.appendChild(tagSpanClose);

                            tagsRegionMapRef.current[lastSelectedRegion.current.id]["tagList"].push(tag);
                            document.getElementById(`${lastSelectedRegion.current.id}|tagDiv`).appendChild(tagSpan);
                        }
                    });

                    previousRegion.remove()
                }
            }
        }
        else if (previousOrNext == 1) {
            // merge next
            if (lastSelectedRegion.current != null && !(await isChildlessRegion(lastSelectedRegion.current))) {
                let { index, nextRegion } = await getNextRegion()
                if (lastSelectedRegion.current.id != nextRegion.id) {
                    try {
                        let nextTextboxValue = (document.getElementById(`${nextRegion.id}|txt`) as HTMLTextAreaElement).value
                        let currentTextbox = (document.getElementById(`${lastSelectedRegion.current.id}|txt`) as HTMLTextAreaElement)
                        currentTextbox.value = currentTextbox.value + ' ' + nextTextboxValue

                        lastSelectedRegion.current.update({
                            end: nextRegion.end
                        });

                        tagsRegionMapRef.current[nextRegion.id]["tagList"].forEach((tag: any) => {
                            if (!(tagsRegionMapRef.current[lastSelectedRegion.current.id]["tagList"].includes(tag))) {
                                let tagSpan = document.createElement('span')
                                tagSpan.className = 'key m-0 p-0'
                                tagSpan.style.fontSize = "72%"
                                tagSpan.style.fontWeight = "bolder"
                                let targetTagDict = previousAnnotationRef.current.module == 'stt' ? sentenceTagDictSTT : sentenceTagDictTTS
                                tagSpan.title = `${targetTagDict[tag]}`
                                tagSpan.id = tag
                                tagSpan.innerText = `${tag}`

                                let tagSpanClose = document.createElement('span')
                                tagSpanClose.innerHTML = `&nbsp;&times;`
                                tagSpanClose.onclick = (event: any) => {
                                    event.stopPropagation()
                                    let tagList = tagsRegionMapRef.current[event.target.parentNode.parentNode.id.split('|')[0]]["tagList"]
                                    const index = tagList.indexOf(event.target.parentNode.id);
                                    if (index > -1) { // only splice array when item is found
                                        tagList.splice(index, 1); // 2nd parameter means remove one item only
                                    }
                                    document.getElementById(`${event.target.parentNode.parentNode.id.split('|')[0]}|tagDiv`).removeChild(event.target.parentNode)
                                    // console.log(event.target.parentNode.id, tagsRegionMapRef.current);
                                }
                                tagSpan.appendChild(tagSpanClose);

                                tagsRegionMapRef.current[lastSelectedRegion.current.id]["tagList"].push(tag);
                                document.getElementById(`${lastSelectedRegion.current.id}|tagDiv`).appendChild(tagSpan);
                            }
                        })

                        nextRegion.remove();
                    }
                    catch (err: any) {
                        console.error(err.message);
                    }
                }
            }
        }
        else {
            // merge selected
            if (previousAnnotationRef.current.annotation_status == SENTENCE_LEVEL) {
                let minStart: any = wavesurfer.current.getDuration();
                let maxEnd: any = -1;
                let overlappedRegions: any = [invadingRegion];

                let mergedText: any = '';
                let mergedTags: any = [...tagsRegionMapRef.current[invadingRegion.id]["tagList"]];

                console.log("BEFORE SORTING: ", Object.values(wavesurfer.current.regions.list));

                let sorted_regions_list = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                    return a.start - b.start;
                });

                console.log("AFTER SORTING: ", sorted_regions_list);

                sorted_regions_list.forEach((region: any) => {
                    if ((region.start >= invadingRegion.start && region.start <= invadingRegion.end) ||
                        (region.end >= invadingRegion.start && region.end <= invadingRegion.end) ||
                        (invadingRegion.start >= region.start && invadingRegion.start <= region.end) ||
                        (invadingRegion.end >= region.start && invadingRegion.end <= region.end)) {
                        if (region.id != invadingRegion.id) {
                            // console.log("OVERLAPPED !!! : ", region);

                            overlappedRegions.push(region);
                            minStart = region.start < minStart ? region.start : minStart;
                            maxEnd = region.end > maxEnd ? region.end : maxEnd;
                            let children = document.querySelectorAll(`[data-id=${region.id}] textarea`);
                            if (children.length > 0) {
                                mergedText = mergedText + ' ' + (document.getElementById(`${region.id}|txt`) as HTMLTextAreaElement).value;
                            }
                            mergedTags = get_unique_values([...mergedTags, ...tagsRegionMapRef.current[region.id]["tagList"]])
                        }
                    }
                });

                console.log("OVERLAPPED REGIONS !!! ", overlappedRegions);

                if (overlappedRegions.length > 0) {

                    let graphemeTierContent: any = {}

                    graphemeTierContent["id"] = `${invadingRegion.id}|txt`;
                    graphemeTierContent["tierId"] = 1;
                    graphemeTierContent["tierName"] = "grapheme";
                    graphemeTierContent["start"] = minStart;
                    graphemeTierContent["end"] = maxEnd;
                    graphemeTierContent["text"] = { "verdict": mergedText.trim() };

                    console.log("GRAPHEME TIER CONTENT: ", graphemeTierContent);

                    let target_id = invadingRegion.id;
                    if (overlappedRegions.length == 1) {
                        minStart = invadingRegion.start;
                        maxEnd = invadingRegion.end;
                    }
                    overlappedRegions.forEach((overlappedRegion: any) => {
                        overlappedRegion.remove();
                    });

                    tagsRegionMapRef.current[target_id]["tagList"] = mergedTags

                    wavesurfer.current.addRegion({
                        id: target_id,
                        start: minStart,
                        end: maxEnd,
                        drag: true,
                        resize: true,
                        scroll: true,
                        color: selectedRegionColor.current,
                        // attributes: {
                        //     label: target_id,
                        // },
                    });
                    await makeTextBox(graphemeTierContent);

                    return wavesurfer.current.regions.list[target_id];
                }
            }
        }
    }
    const deleteRegionProperly = async () => {
        if (lastSelectedRegion.current != null) {
            if (previousAnnotationRef.current.annotation_status <= WORD_LEVEL || await isChildlessRegion(lastSelectedRegion.current)) {
                lastSelectedRegion.current.remove();
                // displayContentRef.current.innerHTML = `<p class="my-0 py-2 bg-secondary text-white text-center"> </p>`
            }
        } else {
            ToastFailedMsg("কোন Region Select করেন নাই !")
        }
    }

    const toggleTagButton = (regionId: any, display: any) => {
        console.log("TARGET TAG REGION ID: ", regionId);

        let tagButtonElement = document.getElementById(regionId + '|tagButton');

        if (tagButtonElement) {
            tagButtonElement.style.display = display;
        }
    }

    const toggleTagList = (regionId: any, display: any) => {
        let regionElement = document.querySelector('[data-id="' + regionId + '"]') as HTMLElement;
        if (previousAnnotationRef.current.annotation_status < TRANSCRIPTION_LEVEL) {
            console.log("TOGGLE TAG LIST: ", regionId);

            let tagListElement = document.getElementById(regionId + '|tagList');
            if (display != null) {
                tagListElement.style.display = display;
                return;
            }

            if (tagListElement.style.display == "none") {
                tagListElement.style.display = "block";
            } else {
                tagListElement.style.display = "none";
            }
        }
    }

    const toggleSpeakerList = (regionId: any, display: any) => {
        let regionElement = document.querySelector('[data-id="' + regionId + '"]') as HTMLElement;
        if (previousAnnotationRef.current.annotation_status == SENTENCE_LEVEL) {
            console.log("TOGGLE SPEAKER LIST: ", regionId);

            let speakerListElement = document.getElementById(regionId + '|speakerTagList');
            let speakerNumInput = document.getElementById(regionId + '|speakerNumInput')
            if (display != null) {
                speakerListElement.style.display = display;
                speakerNumInput.style.top = `${parseFloat(speakerListElement.style.top) + $(speakerListElement).height()}px`;
                speakerNumInput.style.display = display;
                return;
            }

            if (speakerListElement.style.display == "none") {
                speakerListElement.style.display = "block";
                speakerNumInput.style.top = `${parseFloat(speakerListElement.style.top) + $(speakerListElement).height()}px`;
                speakerNumInput.style.display = "block";
            } else {
                speakerListElement.style.display = "none"
                speakerNumInput.style.display = "none";
            }
        }
    }

    const toggleSpeakerDiv = (regionId: any, display: any) => {
        console.log("TARGET TAG REGION ID: ", regionId);

        let speakerDivElement = document.getElementById(regionId + '|speakerTagDiv');

        if (speakerDivElement) {
            speakerDivElement.style.display = display;
        }
    }

    /**
 * Use formatTimeCallback to style the notch labels as you wish, such
 * as with more detail as the number of pixels per second increases.
 *
 * Here we format as M:SS.frac, with M suppressed for times < 1 minute,
 * and frac having 0, 1, or 2 digits as the zoom increases.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override timeInterval, primaryLabelInterval and/or
 * secondaryLabelInterval so they all work together.
 *
 * @param: seconds
 * @param: pxPerSec
 */
    function formatTimeCallbackk(seconds: any, pxPerSec: any) {
        seconds = Number(seconds);
        var minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        // fill up seconds with zeroes
        var secondsStr = Math.round(seconds).toString();
        if (pxPerSec >= 25 * 10) {
            secondsStr = seconds.toFixed(2);
        } else if (pxPerSec >= 25 * 1) {
            secondsStr = seconds.toFixed(1);
        }

        if (minutes > 0) {
            if (seconds < 10) {
                secondsStr = '0' + secondsStr;
            }
            return `${minutes}:${secondsStr}`;
        }
        // console.log("Second STR FORMAT: ", secondsStr);

        return secondsStr;
    }

    /**
     * Use timeInterval to set the period between notches, in seconds,
     * adding notches as the number of pixels per second increases.
     *
     * Note that if you override the default function, you'll almost
     * certainly want to override formatTimeCallback, primaryLabelInterval
     * and/or secondaryLabelInterval so they all work together.
     *
     * @param: pxPerSec
     */
    function timeIntervall(pxPerSec: any) {
        var retval = 1;
        if (pxPerSec >= 25 * 100) {
            retval = 0.01;
        } else if (pxPerSec >= 25 * 40) {
            retval = 0.025;
        } else if (pxPerSec >= 25 * 10) {
            retval = 0.1;
        } else if (pxPerSec >= 25 * 4) {
            retval = 0.25;
        } else if (pxPerSec >= 25) {
            retval = 1;
        } else if (pxPerSec * 5 >= 25) {
            retval = 5;
        } else if (pxPerSec * 15 >= 25) {
            retval = 15;
        } else {
            retval = Math.ceil(0.5 / pxPerSec) * 60;
        }
        return retval;
    }

    /**
     * Return the cadence of notches that get labels in the primary color.
     * EG, return 2 if every 2nd notch should be labeled,
     * return 10 if every 10th notch should be labeled, etc.
     *
     * Note that if you override the default function, you'll almost
     * certainly want to override formatTimeCallback, primaryLabelInterval
     * and/or secondaryLabelInterval so they all work together.
     *
     * @param pxPerSec
     */
    function primaryLabelIntervall(pxPerSec: any) {
        var retval = 1;
        if (pxPerSec >= 25 * 100) {
            retval = 10;
        } else if (pxPerSec >= 25 * 40) {
            retval = 4;
        } else if (pxPerSec >= 25 * 10) {
            retval = 10;
        } else if (pxPerSec >= 25 * 4) {
            retval = 4;
        } else if (pxPerSec >= 25) {
            retval = 1;
        } else if (pxPerSec * 5 >= 25) {
            retval = 5;
        } else if (pxPerSec * 15 >= 25) {
            retval = 15;
        } else {
            retval = Math.ceil(0.5 / pxPerSec) * 60;
        }
        return retval;
    }

    /**
     * Return the cadence of notches to get labels in the secondary color.
     * EG, return 2 if every 2nd notch should be labeled,
     * return 10 if every 10th notch should be labeled, etc.
     *
     * Secondary labels are drawn after primary labels, so if
     * you want to have labels every 10 seconds and another color labels
     * every 60 seconds, the 60 second labels should be the secondaries.
     *
     * Note that if you override the default function, you'll almost
     * certainly want to override formatTimeCallback, primaryLabelInterval
     * and/or secondaryLabelInterval so they all work together.
     *
     * @param pxPerSec
     */
    function secondaryLabelIntervall(pxPerSec: any) {
        // draw one every 10s as an example
        return Math.floor(10 / timeIntervall(pxPerSec));
    }

    const loadWaveSurfer = async (graphemeTierArray: any, phonemeTierArray: any) => {
        console.log("INSIDE LOAD SURFER ...");

        console.log("PREVIOUSLY SAVED ANNOTATION", previousAnnotationRef.current.annotation);
        console.log("GRAPHEME TIER ARRAY", graphemeTierArray);
        console.log("PHONEME TIER ARRAY", phonemeTierArray);

        console.log("WaveFormRef: ", waveformRef);
        console.log("WaveFormRef Current: ", waveformRef.current);

        let data = previousAnnotationRef.current

        if (waveformRef.current) {
            console.log("Window InnerHeight: ", window.innerHeight)
            console.log("Top NavBar Height: ", document.getElementById("topNavBar")?.offsetHeight);

            audioContextRef.current = new AudioContext({
                sampleRate: data.sample_rate
            });

            // let sound = document.createElement('audio');
            // sound.id = 'speech';
            // sound.crossOrigin = 'anonymous';
            // document.appendChild(sound)
            // console.log("COLORS: ", colors);

            // waveformRef.current.style.backgroundColor = backgroundColor;
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: waveColor,
                progressColor: progressColor,
                cursorColor: handleColor,
                audioContext: audioContextRef.current,
                // backend: "WebAudio",//@ts-ignore
                backend: "MediaElement",//@ts-ignore
                // mediaContainer: document.getElementById('speech'),
                height: window.innerHeight - document.getElementById("topNavBar")?.clientHeight - 20,
                // height: window.innerHeight,
                // xhr: { mode: 'no-cors' },
                // xhr: {
                //     cache: "default",
                //     mode: "cors",
                //     method: "GET",
                //     credentials: "include",
                //     headers: [
                //         { key: "cache-control", value: "no-cache" },
                //         { key: "pragma", value: "no-cache" }
                //     ]
                // },
                // responsive: true,
                // maxCanvasWidth: 4000,
                scrollParent: true,
                autoCenter: false,
                plugins: [
                    RegionPlugin.create({
                        regions: [],
                        dragSelection: true
                    }),
                    TimelinePlugin.create({
                        container: waveTimelineRef.current,
                        formatTimeCallback: formatTimeCallbackk,
                        timeInterval: timeIntervall,
                        primaryLabelInterval: primaryLabelIntervall,
                        secondaryLabelInterval: secondaryLabelIntervall,
                        primaryColor: 'black',
                        secondaryColor: 'red',
                        primaryFontColor: 'black',
                        secondaryFontColor: 'red',
                        // deferInit: true
                    }),
                    SpectrogramPlugin.create({
                        deferInit: true,
                        wavesurfer: wavesurfer.current,
                        container: waveSpectrogramRef.current, // "#wave-spectrogram",
                        labels: true,
                        scrollParent: true, // Enable scrollable spectrogram
                        // height: `${Math.floor(fftSamples / 2)}px`,
                        colorMap: colormap({
                            colormap: 'hot',
                            nshades: 256,
                            format: 'float',
                            // alpha: 1
                        }),
                        fftSamples: fftSamples, // You can adjust this value for better frequency resolution
                        frequencyMax: Math.floor(data.sample_rate / 2), // Set the maximum frequency to 21.6 kHz

                    })
                ],
                barHeight: 1,
            });

            wavesurfer.current.on('loading', function (progressCurrent: any) {
                // console.log("PROGRESS: ", progress);
                if (progressCurrent > progress) {
                    setProgress(progressCurrent);
                }
            });

            // let base64Url = "data:audio/wav;base64," + data.byte;

            // var myHeaders = new Headers();
            // myHeaders.append("Access-Control-Allow-Origin:", "*");
            // https://wavesurfer-js.org/example/media/demo.wav
            // await fetch("http://119.148.4.25:5120/Annotation/2022-12-31/00-06/afd4203b-ff94-486a-9187-ccbe23bedc5b_5.wav", { headers: myHeaders })
            //     .then(response => response.text())
            //     .then(result => console.log("RESULTTTT: ", result))
            //     .catch(error => console.log('error', error));

            // audioContextRef.current = wavesurfer.current.backend.ac;
            // console.log("AUDIO CONTEXT: ", audioContextRef.current)

            console.log("ASYNC right?")
            wavesurfer.current.load(data.url);
            let audioElement = document.querySelector('audio');
            audioElement.crossOrigin = "anonymous";

            wavesurfer.current.enableDragSelection({
                drag: true,
                slop: 5,
                // loop: false,
            });

            wavesurfer.current.on('waveform-ready', async function () {
                console.log("Wavesurfer READY !!! ", wavesurfer.current);
                setLoading(false);
                setIsLoading(false);
                // wavesurfer.current.setVolume(0.1);

                // Analyze the audioBuffer to find the maximum dBFS 
                maxAvgRmsdBFS.current = await getMaxDbfs(wavesurfer.current.backend.buffer);
                console.log('Max Avg Rms dBFS:', maxAvgRmsdBFS.current);

                if (maxAvgRmsdBFS.current) {
                    if (maxAvgRmsdBFS.current['rmsDbfs'] <= -20.0) {
                        // Calculate the gain adjustment factor based on target and actual dBFS
                        const gainFactor = Math.pow(10, (targetdBFS - maxAvgRmsdBFS.current['maxDbfs']) / 20);
                        console.log("GAIN FACTOR: ", gainFactor)
                        if (Number.isFinite(gainFactor)) {
                            amplifyMedia(document.querySelector('audio'), Math.max(gainFactor, 1));
                        }
                        normalizedRef.current = true
                        setNormalized(normalizedRef.current)
                    }
                }

                setInterval(() => setTimePassed(Math.floor(new Date().getTime() / 1000) - parseInt(localStorage.getItem('last_picked'))), 1000);
                // console.log("Drawer !!! ", wavesurfer.current.drawer);
                // try {
                //     wavesurfer.current.play();
                // } catch (err: any) {
                //     console.log(`PLAY INTERRUPTED ! ${err.message}`);
                // }

                // displayContentRef.current.style.display = "none"
                // wavesurfer.current.setHeight(window.innerHeight * 3)
                // wavesurfer.current.seekTo(0)

                console.log("Annotation Status", previousAnnotationRef.current.annotation_status);
                let status = previousAnnotationRef.current.annotation_status;

                // // wavesurfer.current.timeline.init();
                if (status > TRANSCRIPTION_LEVEL && showSpectrogram) {
                    wavesurfer.current.spectrogram.init();
                }

                // setLoading(false);

                let divTop = canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 1;

                // graphemeTierDiv = document.createElement('div');
                // graphemeTierDiv.style.backgroundColor = '#FFE6BC';
                // graphemeTierDiv.style.top = divTop + 'px';
                // graphemeTierDiv.style.height = `${tierDivHeight}px`;
                // graphemeTierDiv.style.position = 'relative';
                // graphemeTierDiv.id = 'graphemeTierDiv';
                // graphemeTierDiv.style.width = `${getCanvasWidthHeight()[0]}px`;

                // // @ts-ignore
                // document.querySelector('wave').appendChild(graphemeTierDiv);

                // console.log("Modified Wave After Inserting Grapheme & Phoneme Tier Div: ", document.querySelector('wave'));

                // makeCanvasShorter();
                // wavesurfer.current.zoom(pxPerSec, true);
                await drawSavedRegions(graphemeTierArray, phonemeTierArray);
                // await adjustCanvasHeight();

                // let regions_list: any = Object.values(wavesurfer.current.regions.list);
                // regions_list.forEach((region: any, index: any) => {
                //     let handles = document.querySelectorAll('[data-id="' + region.id + '"] > handle')
                //     // console.log("HANDLES: ", handles);

                //     handles.forEach((handle: any) => {
                //         handle.style.width = '1px';
                //         handle.style.height = `${tierDivHeight * 4}px`;
                //         handle.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height()}px`;
                //     });
                // })

                console.log("minPxPerSec before Zoom: ", wavesurfer.current.params.minPxPerSec);
                setZoom(null, wavesurfer.current.params.minPxPerSec + zoomStep * 5 * Math.pow((status + 1), 3));
                // wavesurfer.current.timeline.init();
                // makeCanvasShorter();
                console.log("minPxPerSec after Zoom: ", wavesurfer.current.params.minPxPerSec);

                interact('.resizable')
                    .resizable({
                        // resize from all edges and corners
                        edges: { left: false, right: false, bottom: true, top: false },

                        listeners: {
                            async move(event) {
                                event.stopPropagation();
                                wavesurfer.current.enableDragSelection({
                                    drag: false,
                                    // slop: 5,
                                    // loop: false,
                                });
                                // console.log("INTERACT !!! ", event);

                                var target = event.target
                                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                                // update the element's style
                                target.style.width = event.rect.width + 'px'
                                target.style.height = event.rect.height + 'px'

                                // translate when resizing from top or left edges
                                x += event.deltaRect.left
                                y += event.deltaRect.top

                                target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

                                target.setAttribute('data-x', x)
                                target.setAttribute('data-y', y)
                                target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)

                                canvasHeightPercentageRef.current = event.rect.height / $(waveformRef.current).height();
                                await adjustCanvasHeight();
                            }
                        },
                        modifiers: [
                            // keep the edges inside the parent
                            interact.modifiers.restrictEdges({
                                outer: 'parent'
                            }),

                            // minimum size
                            interact.modifiers.restrictSize({
                                min: {
                                    width: 100,
                                    height: canvasHeightPercentageMin * $(waveformRef.current).height()
                                }
                            })
                        ],

                        inertia: true
                    })

                interact('.resizable-textbox')
                    .resizable({
                        // resize from all edges and corners
                        edges: { left: false, right: false, bottom: true, top: false },

                        listeners: {
                            async move(event) {
                                event.stopPropagation();
                                wavesurfer.current.enableDragSelection({
                                    drag: false,
                                    // slop: 5,
                                    // loop: false,
                                });
                                // console.log("INTERACT !!! ", event);

                                var target = event.target
                                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                                // update the element's style
                                target.style.width = event.rect.width + 'px'
                                target.style.height = event.rect.height + 'px'

                                // translate when resizing from top or left edges
                                x += event.deltaRect.left
                                y += event.deltaRect.top

                                target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

                                target.setAttribute('data-x', x)
                                target.setAttribute('data-y', y)
                                // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)

                                // canvasHeightPercentageRef.current = event.rect.height / $(waveformRef.current).height();
                                // await adjustCanvasHeight();
                            }
                        },
                        modifiers: [
                            // keep the edges inside the parent
                            // interact.modifiers.restrictEdges({
                            //     outer: 'parent'
                            // }),

                            // minimum size
                            interact.modifiers.restrictSize({
                                min: {
                                    width: 100,
                                    height: tierDivHeight
                                }
                            })
                        ],

                        inertia: true
                    })

                /*wavesurfer.exportPCM((500 * data.duration), 100000, true).then((e: any) => {
                    console.log("pecks length", e.length, 500 * data.duration);
                    if (500 * data.duration > 0) {
                        uploadPecks({
                            pecks: e.toString(),
                            id: data.id
                        })
                    }
                })*/

                wavesurfer.current.seekAndCenter(0);
                await focusOnCurrentRegion(Object.values(wavesurfer.current.regions.list)[0], false, false);
            });

            wavesurfer.current.on('finish', function () {
                console.log("FINISHED OR WHAT ???", wavesurfer.current.getCurrentTime(), wavesurfer.current.getDuration());

                // if (wavesurfer.current.getCurrentTime() >= wavesurfer.current.getDuration()) {
                //     // wavesurfer.current.stop();
                //     // wavesurfer.current.seekAndCenter(0);

                // wavesurfer.current.pause();
                setPlaying(false);
                setPlayingRegion(false);
                setPlayingLoop(false);

                playUniversal.current = false;
                playRegion.current = false;
                playRegionLoop.current = false;

                if (lastSelectedRegion.current) {
                    deFocusCurrentRegion(false)
                }

                //     // no jump when using MediaElement
                //     if (wavesurfer.current.backend.params.backend == 'MediaElement') {
                //         // console.log(wavesurfer.current.backend.buffer.duration);
                //         // let position = wavesurfer.current.backend.buffer.duration;
                //         wavesurfer.current.seekTo(1);
                //         return false;
                //     }
                // } else {
                //     let position = wavesurfer.current.getCurrentTime();
                //     wavesurfer.current.seekTo(0);
                //     wavesurfer.current.skip(position);
                // }
            });

            // wavesurfer.current.on('scroll', function (event: any) {
            //     // console.log("SCROLL EVENT: ", event)
            //     // if(lastSelectedRegion.current != null){
            //     //     lastSelectedRegion.current.update({
            //     //         scroll: true
            //     //     })
            //     // }
            // });

            // wavesurfer.current.drawer.on('click', async function (e: any) {
            //     // console.log("CTRL + CLICK !!! ", e);

            // });

            wavesurfer.current.drawer.on('click', async function (e: any) {
                console.log("DRAWER CLICK !!!");
                let regionId = e.target.getAttribute("data-id");

                if (e.target.parentNode.nodeName == "REGION") {
                    regionId = e.target.parentNode.getAttribute("data-id");
                }

                if (e.ctrlKey && e.shiftKey && e.buttons < 2 && e.button < 1) {
                    if (regionId != null) {
                        let targetRegion = wavesurfer.current.regions.list[regionId];
                        setTimeout(async () => {
                            if (previousAnnotationRef.current.annotation_status <= WORD_LEVEL) {
                                console.log("CTRL + SHIFT + CLICK !!! ", targetRegion);
                                let duplicateEnd = targetRegion.end;
                                targetRegion.onResize(wavesurfer.current.getCurrentTime() - targetRegion.end);
                                await focusOnCurrentRegion(await duplicateRegion(targetRegion, duplicateEnd), false, false);
                            }
                        }, 50);
                    }
                } else {
                    if (regionId == null) {
                        removeLastChildlessRegion(0);

                        if (wavesurfer.current.isPlaying()) {
                            playUniversal.current = true;
                            playRegion.current = false;
                            playRegionLoop.current = false;

                            setPlaying(true);
                            setPlayingRegion(false);
                            setPlayingLoop(false);
                        }

                        if (lastSelectedRegion.current) {
                            let status = previousAnnotationRef.current.annotation_status;
                            let unlock = (status < TRANSCRIPTION_LEVEL);

                            let textBoxElement = status <= WORD_LEVEL ? document.getElementById(`${lastSelectedRegion.current.id}|txt`) : status == TRANSCRIPTION_LEVEL ? document.getElementById(`${lastSelectedRegion.current.id}|phn`) : null;

                            if (textBoxElement == null || (document.activeElement?.nodeName !== "TEXTAREA" || !(await isTextSelected(textBoxElement)))) {
                                await deFocusCurrentRegion(false);
                                Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                                    let regionElement = document.querySelector('[data-id="' + region.id + '"]') as HTMLElement;
                                    regionElement.style.borderLeft = '0px solid rgba(0, 0, 0, 0)'
                                    regionElement.style.borderRight = '0px solid rgba(0, 0, 0, 0)'
                                })
                            }
                            // if(selection){
                            //     await navigator.clipboard.writeText(selection);
                            // }
                        }
                    } else {
                        let targetRegion = wavesurfer.current.regions.list[regionId]
                        if (await isChildlessRegion(targetRegion)) {

                        } else {
                            removeLastChildlessRegion(0);
                        }

                        console.log("REGION: ", targetRegion);

                        // আগের সব defocus করো, এটাকে focus করো
                        if (lastSelectedRegion.current == null || lastSelectedRegion.current.id != targetRegion.id) {
                            await focusOnCurrentRegion(targetRegion, false, false)
                        }

                        let status = previousAnnotationRef.current.annotation_status;

                        // if (status == 1) {
                        //     let children = document.querySelectorAll(`[data-id=${regionId}] textarea`);

                        //     for (let i = 0; i < children.length; i++) {
                        //         (children[i] as HTMLTextAreaElement).style.height = tierDivHeight + 'px';
                        //     }
                        // }
                    }
                }
            });

            wavesurfer.current.params.container.addEventListener('wheel', function (e: any) {
                // console.log("WHEEL !!!");

                // alert(" wavesurfer.current.drawer clkA");
                // console.log("Clicked: " + wavesurfer.current.getCurrentTime());


                // console.log("DRAWER WHEEL !!!", e);

                // let scale = wavesurfer.current.params.minPxPerSec;
                let status = previousAnnotationRef.current.annotation_status

                if (e.ctrlKey) {
                    if (status <= TRANSCRIPTION_LEVEL || !showSpectrogram) {
                        // console.log("HERE !!!");

                        e.preventDefault();
                        e.stopPropagation();

                        // e.deltaY
                        // scale += e.deltaY * -0.01;

                        // Restrict scale
                        // scale = Math.min(Math.max(1, scale), 10000);

                        // Apply scale transform
                        // el.style.transform = `scale(${scale})`;
                        if (e.deltaY > 0) {
                            setZoom('-')
                        }
                        else {
                            setZoom('+')
                        }
                        // wavesurfer.current.timeline.init();
                        // makeCanvasShorter();
                    }
                }
            })

            wavesurfer.current.on('seek', async function (event: any) {
                console.log("SEEK !!!", event);
                // if (document.activeElement?.nodeName !== "TEXTAREA") {
                // let x = isOutOfViewport(document.querySelector("wave wave:first-of-type"), true);
                // // console.log(x);
                // if (x.right == true) {
                //     document.querySelector("wave")?.scrollBy(wavesurfer.current.params.container.offsetWidth, 0);
                // } else if (x.left == true) {
                //     document.querySelector("wave")?.scrollBy(-wavesurfer.current.params.container.offsetWidth, 0);
                // }
                // }
            });

            wavesurfer.current.on('audioprocess', function () {
                // console.log(document.activeElement?.nodeName);

                if (document.activeElement?.nodeName !== "TEXTAREA") {
                    let x = isOutOfViewport(document.querySelector("wave wave:first-of-type"), true);
                    // console.log(x);
                    if (x.right == true) {
                        document.querySelector("wave")?.scrollBy(wavesurfer.current.params.container.offsetWidth, 0);
                    } else if (x.left == true) {
                        document.querySelector("wave")?.scrollBy(-wavesurfer.current.params.container.offsetWidth, 0);
                    }
                }
            });

            wavesurfer.current.on('region-click', async function (region: any) {

            });

            wavesurfer.current.on('region-dblclick', async function (region: any) {
                console.log("Region Double Click");
                let status = previousAnnotationRef.current.annotation_status;

                let children = document.querySelectorAll(`[data-id=${region.id}] textarea`);

                if (status == SENTENCE_LEVEL && children.length == 0) {
                    let new_region = await merge(region);
                    await focusOnCurrentRegion(new_region, true, true);
                }
            });

            wavesurfer.current.on('region-updated', async function (region: any) {
                // console.log("UPDATED? ", region, region.isDragging, region.isResizing);
                let regionElement = document.querySelector('[data-id="' + region.id + '"]') as HTMLElement;

                if (region.isDragging || region.isResizing) {
                    // if (lastSelectedRegion.current != null && region.id == lastSelectedRegion.current.id) {
                    //     if (region.scroll) {
                    //         return;
                    //     } else {
                    //         region.update({
                    //             scroll: true
                    //         })
                    //     }
                    // }
                    // console.log("NOW NOW ! ", region);

                    let result = await getOverlappedRegions(region);
                    // console.log("RESULT: ", result);

                    if (result["partiallyOverlapped"].length > 0 || result["fullyOverlapped"].length > 0) {
                        regionElement.style.borderLeft = '1px solid rgba(255, 0, 0, 1)'
                        regionElement.style.borderRight = '1px solid rgba(255, 0, 0, 1)'
                        // region.update({
                        //     color: 'rgba(239, 154, 154, 0.25)'
                        // })
                        // ToastWarningMsg("OVERLAPPED !!!", 1)

                        // if (overlappedSnackbar) {
                        // setOverlappedSnackbar(1)
                        // }
                    }
                    else if (result["justTouches"] == true) {
                        regionElement.style.borderLeft = '0.1px solid rgba(0, 0, 0, 1)'
                        regionElement.style.borderRight = '0.1px solid rgba(0, 0, 0, 1)'
                        // regionElement.style.borderLeft = '0.1px solid rgba(0, 128, 128, 1)'
                        // regionElement.style.borderRight = '0.1px solid rgba(0, 128, 128, 1)'
                        // setOverlappedSnackbar(0)
                    }
                    else {
                        // setOverlappedSnackbar(-1)
                        regionElement.style.borderLeft = '0.1px solid rgba(0, 0, 0, 1)'
                        regionElement.style.borderRight = '0.1px solid rgba(0, 0, 0, 1)'
                    }
                    //     Object.values(wavesurfer.current.regions.list).forEach((regionn: any) => {
                    //         if (regionn.id != region.id && regionn.scroll) {
                    //             regionn.update({
                    //                 scroll: false
                    //             })
                    //         }
                    //     })
                    //     if (!region.scroll) {
                    //         region.update({
                    //             scroll: true
                    //         })
                    //     }
                } else {
                    setOverlappedSnackbar(-1)
                    // regionElement.style.borderLeft = '0.1px solid rgba(0, 0, 0, 1)'
                    // regionElement.style.borderRight = '0.1px solid rgba(0, 0, 0, 1)'
                }
            });

            wavesurfer.current.on('audioprocess', function (event: any) {
                // console.log("AUDIO PROCESS: ", event);
            });

            wavesurfer.current.on('region-created', async function (region: any) {
                console.log("REGION CREATED !!! ", region);
                console.log("Regions List: ", wavesurfer.current.regions.list);

                let regionElement = document.querySelector('[data-id="' + region.id + '"]') as HTMLElement;

                // regionElement.style.borderLeft = '0.1px solid rgba(0, 0, 0, 1)'
                // regionElement.style.borderRight = '0.1px solid rgba(0, 0, 0, 1)'

                let status = previousAnnotationRef.current.annotation_status;

                region.update({
                    resize: false,
                    drag: false,
                    // color: 'rgba(0, 0, 0, 0)'
                    // scroll: false,
                    // color: selectedRegionColor.current
                });

                let targetSegment: any = { "tagList": [], "speakerNo": "1" };
                previousAnnotationRef.current.annotation.forEach((segment: any) => {
                    if (segment.id == region.id) {
                        targetSegment = segment;
                    }
                })

                let mergedTags = []
                if (region.id in tagsRegionMapRef.current) {
                    mergedTags = tagsRegionMapRef.current[region.id]["tagList"]
                }

                tagsRegionMapRef.current[region.id] = {
                    "tagList": (status < TRANSCRIPTION_LEVEL) ? mergedTags.length > 0 ? mergedTags : targetSegment["tagList"] : [],
                    "speakerNo": (status == SENTENCE_LEVEL) ? targetSegment["speakerNo"] : "",
                    // "overlapped": false
                }
                // await focusOnCurrentRegion(region, false, false)

                // let handles = document.querySelectorAll('handle')
                let handles = document.querySelectorAll('[data-id="' + region.id + '"] > handle')
                console.log("HANDLES: ", handles);

                // let handles = document.querySelectorAll('.wavesurfer-handle');

                handles.forEach((handle, index) => {
                    (handle as HTMLElement).style.width = '1px';
                    (handle as HTMLElement).style.backgroundColor = 'rgba(0, 0, 0, 0)';
                    (handle as HTMLElement).style.backgroundClip = 'padding-box';
                    if (index == 0) {
                        // (handle as HTMLElement).title = "LEFT HANDLE"
                        (handle as HTMLElement).style.borderRight = '15px solid rgba(255, 0, 0, 0)';
                    } else if (index == 1) {
                        // (handle as HTMLElement).title = "RIGHT HANDLE"
                        (handle as HTMLElement).style.borderLeft = '15px solid rgba(255, 0, 0, 0)';
                    }

                    handle.addEventListener('mouseover', (event: any) => {
                        event.stopPropagation();
                        let targetRegionId = ((event.target as HTMLElement)?.parentNode as HTMLElement)?.getAttribute("data-id");
                        let targetRegion = wavesurfer.current.regions.list[targetRegionId];
                        targetRegion.update({
                            resize: true,
                            // scroll: true
                            // color: selectedRegionColor.current
                        })
                        // if(!(targetRegion.isResizing)){
                        // event.target.style.backgroundColor = 'rgba(244, 143, 177, 0.25)'; // '#c5cae9';
                        // event.target.style.width = `${parseFloat(event.target.style.width) * 8}px`;

                        // console.log('Mouse Over Handle', targetRegion);
                        // }
                    });

                    handle.addEventListener('mouseleave', (event: any) => {
                        event.stopPropagation();
                        let targetRegionId = ((event.target as HTMLElement)?.parentNode as HTMLElement)?.getAttribute("data-id");
                        let targetRegion = wavesurfer.current.regions.list[targetRegionId];

                        // event.target.style.backgroundColor = 'black';
                        // event.target.style.width = `${parseFloat(event.target.style.width) / 8}px`

                        // console.log('Mouse Leave Handle', targetRegion);
                        if (!(targetRegion.isResizing)) {
                            targetRegion.update({
                                resize: false,
                                // scroll: false
                                // color: `rgba(0, 0, 0, 0)`
                            })
                        }
                    });
                });

                // console.log("TAG: ", renderToString(<FaTags />));

                //@ts-ignore
                let tagTop = `${canvasHeightPercentageRef.current * $(waveformRef.current).height()}px`;
                let speakerTagTop = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 2}px`;
                let module = previousAnnotationRef.current.module
                let targetDict = status == SENTENCE_LEVEL ? (module == 'stt' ? sentenceTagDictSTT : sentenceTagDictTTS) : status == WORD_LEVEL ? (module == 'stt' ? wordTagDictSTT : wordTagDictTTS) : {};

                let tagDiv = document.createElement("div");
                tagDiv.id = region.id + "|tagDiv"
                // tagDiv.className = 'px-1'
                tagDiv.style.position = 'absolute';
                tagDiv.style.top = tagTop;
                // tagDiv.style.width = "97%"
                tagDiv.style.marginLeft = "2px"
                tagDiv.style.marginRight = "1px"
                tagDiv.style.border = '0px solid red';

                tagDiv.style.display = "flex";
                tagDiv.style.flexDirection = "row";
                tagDiv.style.alignItems = "center";
                tagDiv.style.justifyContent = "flex-start";

                let tagsButton = document.createElement('button');
                tagsButton.className = "btn btn-sm m-0 p-0";
                tagsButton.style.backgroundColor = "#0e0918";
                tagsButton.style.color = "#FF9E44"
                tagsButton.innerHTML = `${renderToString(<FaTags />)}`
                tagsButton.id = region.id + "|tagButton";

                // tagsButton.style.position = 'absolute';
                // tagsButton.style.left = `${parseFloat(regionElement.style.left) + (parseFloat(regionElement.style.width)/2) - 10}px`
                tagsButton.style.width = "fit-content"
                // tagsButton.style.marginLeft = `10px`
                // tagsButton.style.zIndex = "15";

                // tagsButton.style.top = tagTop;
                tagsButton.style.textAlign = "center";
                tagsButton.title = "TAGS"; // $(pairedRegion).width() + 'px';
                // tagsButton.style.display = "none";

                tagDiv.appendChild(tagsButton)

                // let speakerDecreaseBtn = document.createElement('button')
                // speakerDecreaseBtn.id = region.id + "|speakerDecreaseButton";
                // speakerDecreaseBtn.title = "Decrease Speaker Number"
                // speakerDecreaseBtn.className = "btn btn-sm btn-danger"
                // speakerDecreaseBtn.style.backgroundColor = "#471620"
                // speakerDecreaseBtn.innerText = `-`
                // speakerDecreaseBtn.onclick = (event: any) => {
                //     event.stopPropagation()
                //     let speakerNum = document.getElementById(`${event.target.id.split('|')[0]}|spn`) as HTMLButtonElement;
                //     speakerNum.innerText = `${Math.max(1, parseInt(speakerNum.innerText) - 1)}`
                // }
                // speakerLeftDiv.appendChild(speakerDecreaseBtn);

                // let speakerIncreaseBtn = document.createElement('button')
                // speakerIncreaseBtn.id = region.id + "|speakerIncreaseButton";
                // speakerIncreaseBtn.title = "Increase Speaker Number"
                // speakerIncreaseBtn.className = "btn btn-sm btn-success"
                // speakerIncreaseBtn.style.backgroundColor = "#006B43"
                // speakerIncreaseBtn.innerText = `+`
                // speakerIncreaseBtn.onclick = (event: any) => {
                //     event.stopPropagation()
                //     let speakerNum = document.getElementById(`${event.target.id.split('|')[0]}|spn`) as HTMLButtonElement;
                //     speakerNum.innerText = `${Math.min(100, parseInt(speakerNum.innerText) + 1)}`
                // }
                // speakerLeftDiv.appendChild(speakerIncreaseBtn);

                // let speakerTagInput = document.createElement("input");
                // speakerTagInput.id = `${region.id}|speakerNumInput`
                // speakerTagInput.type = "number"
                // speakerTagInput.min = "1"
                // speakerTagInput.max = "100"
                // speakerTagInput.value = "1"
                // speakerTagInput.style.width = "fit-content"
                // speakerTagDiv.appendChild(speakerTagInput);

                // speakerTagInput.onclick = (event:any) => {
                //     event.stopPropagation()
                // }

                // speakerTagDiv.appendChild(speakerLeftDiv);

                // let speakerRightDiv = document.createElement("div");
                // let speakerOverlapBtn = document.createElement("button");
                // speakerOverlapBtn.id = region.id + "|speakerOverlapBtn";
                // speakerOverlapBtn.className = "btn btn-sm";
                // speakerOverlapBtn.style.backgroundColor = "#006B43";
                // speakerOverlapBtn.style.color = "FloralWhite";
                // speakerOverlapBtn.innerText = "No OVL";
                // speakerOverlapBtn.title = "No Overlapping"

                // speakerOverlapBtn.onclick = (event: any) => {
                //     event.stopPropagation();
                //     // console.log(event.target.innerText);
                //     if (event.target.innerText == "No OVL") {
                //         event.target.style.backgroundColor = "#471620";
                //         event.target.style.color = "FloralWhite";
                //         event.target.innerText = "OVL";
                //         event.target.title = "Overlapped";
                //         tagsRegionMapRef.current[event.target.id.slice(0, -3)] = {
                //             ...tagsRegionMapRef.current[event.target.id.slice(0, -3)],
                //             "overlapped": true
                //         }
                //     } else {
                //         event.target.style.backgroundColor = "#006B43";
                //         event.target.style.color = "FloralWhite";
                //         event.target.innerText = "No OVL"
                //         event.target.title = "No Overlapping"
                //         tagsRegionMapRef.current[event.target.id.slice(0, -3)] = {
                //             ...tagsRegionMapRef.current[event.target.id.slice(0, -3)],
                //             "overlapped": false
                //         }
                //     }
                // }
                // speakerRightDiv.appendChild(speakerOverlapBtn)
                // speakerTagDiv.appendChild(speakerRightDiv)

                // speakerTagInput.min = "1"
                // speakerTagInput.max = "100"
                // speakerTagInput.value = "1"

                //                 speakerTagDiv.innerHTML = `<div><label for="input">SP:</label>
                //                 <input name="input" type="number" value="1" min="1" max="100"/></div>
                //                 <div>
                //                 <input type="checkbox" id="vehicle1" name="vehicle1" />
                //   <label for="vehicle1">OVL</label></div>`

                let tagList = document.createElement("ul");
                tagList.id = region.id + "|tagList";
                tagList.style.backgroundColor = "FloralWhite";
                tagList.style.zIndex = "25";

                Object.entries(targetDict).forEach((tag: any) => {
                    let tagItem = document.createElement("li");
                    tagItem.title = tag[1];
                    tagItem.innerText = tag[1].split('-')[0];
                    tagItem.id = tag[0];

                    tagItem.addEventListener('mouseover', function (e: any) {
                        e.stopPropagation();
                        e.target.style.backgroundColor = "#006B43";
                        e.target.style.color = "FloralWhite";
                    });

                    tagItem.addEventListener('mouseout', function (e: any) {
                        e.target.style.backgroundColor = "FloralWhite";
                        e.target.style.color = "#000";
                    });

                    tagItem.addEventListener('click', async function (e: any) {
                        e.stopPropagation();
                        let pairedRegion: any = wavesurfer.current.regions.list[e.target.parentNode.id.split('|')[0]]
                        let children = document.querySelectorAll(`[data-id=${pairedRegion.id}] textarea`);
                        let status = previousAnnotationRef.current.annotation_status

                        if (children.length == 0) {
                            let graphemeTierContent: any = {}

                            graphemeTierContent["id"] = `${e.target.parentNode.id.split('|')[0]}|txt`;
                            graphemeTierContent["tierId"] = 1;
                            graphemeTierContent["tierName"] = "grapheme";
                            graphemeTierContent["start"] = pairedRegion.start;
                            graphemeTierContent["end"] = pairedRegion.end;
                            graphemeTierContent["text"] = { "verdict": "" };

                            console.log("GRAPHEME TIER CONTENT: ", graphemeTierContent);
                            await makeTextBox(graphemeTierContent);
                        }

                        // let targetTextBox = (document.getElementById(`${e.target.parentNode.id.slice(0, -3)}txt`) as HTMLTextAreaElement);
                        // console.log("TAG CLICKED !!! ", e.target.innerText);
                        // targetTextBox.value = `<${e.target.innerText}> ${targetTextBox.value}`
                        let tagSpan = document.createElement('span')
                        tagSpan.className = 'key m-0 p-0'
                        tagSpan.style.fontSize = "72%"
                        tagSpan.style.fontWeight = "bolder"
                        tagSpan.title = `${e.target.title}`
                        tagSpan.id = e.target.id
                        tagSpan.innerText = `${e.target.id}`

                        let tagSpanClose = document.createElement('span')
                        tagSpanClose.innerHTML = `&nbsp;&times;`
                        tagSpanClose.onclick = (event: any) => {
                            event.stopPropagation()
                            let tagList = tagsRegionMapRef.current[event.target.parentNode.parentNode.id.split('|')[0]]["tagList"]
                            const index = tagList.indexOf(event.target.parentNode.id);
                            if (index > -1) { // only splice array when item is found
                                tagList.splice(index, 1); // 2nd parameter means remove one item only
                            }
                            document.getElementById(`${event.target.parentNode.parentNode.id.split('|')[0]}|tagDiv`).removeChild(event.target.parentNode)
                            // console.log(event.target.parentNode.id, tagsRegionMapRef.current);
                        }
                        tagSpan.appendChild(tagSpanClose);

                        if (!(tagsRegionMapRef.current[e.target.parentNode.id.split('|')[0]]["tagList"].includes(e.target.id))) {
                            tagsRegionMapRef.current[e.target.parentNode.id.split('|')[0]]["tagList"].push(e.target.id)
                            document.getElementById(`${e.target.parentNode.id.split('|')[0]}|tagDiv`).appendChild(tagSpan)
                        }
                        // console.log(tagsRegionMapRef.current);
                        toggleTagList(e.target.parentNode.id.split('|')[0], "none");
                    });
                    tagList.appendChild(tagItem);
                });
                tagList.style.position = "absolute"
                tagList.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 2}px`;
                tagList.style.width = "240px"
                tagList.style.maxHeight = "120px"
                tagList.style.overflowY = "auto"
                tagList.style.display = "none"

                tagsButton.addEventListener('click', async function (e: any) {
                    e.stopPropagation();
                    let targetTagButton: any = e.target;

                    while (targetTagButton.nodeName.toLowerCase() !== "button") {
                        targetTagButton = targetTagButton.parentNode;
                    }
                    // console.log("TAGS BUTTON PRESSED: ", targetTagButton);
                    toggleTagList(targetTagButton.id.split('|')[0], null);
                    toggleSpeakerList(targetTagButton.id.split('|')[0], "none");
                    await focusOnCurrentRegion(wavesurfer.current.regions.list[targetTagButton.id.split('|')[0]], false, false);

                    // Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                    //     //@ts-ignore
                    //     if (region.id == e.target.id.split('|')[0]) {
                    //         region.remove();
                    //         lastSelectedRegion.current = null;
                    //         console.log("REGION TO BE DELETED: ", region);
                    //         return;
                    //     }
                    // });
                });

                let speakerTagDiv = document.createElement("div");
                speakerTagDiv.id = region.id + "|speakerTagDiv"
                // speakerTagDiv.className = "px-1"
                speakerTagDiv.style.position = 'absolute';
                speakerTagDiv.style.top = speakerTagTop;
                speakerTagDiv.style.width = "90%"
                speakerTagDiv.style.marginLeft = "2px"
                speakerTagDiv.style.border = '0px solid red';

                speakerTagDiv.style.display = "flex";
                speakerTagDiv.style.flexDirection = "row";
                speakerTagDiv.style.alignItems = "center";
                speakerTagDiv.style.justifyContent = "flex-start";

                // let speakerLeftDiv = document.createElement("div");
                // speakerLeftDiv.id = region.id + "|speakerLeftDiv";
                // speakerLeftDiv.style.display = "none"

                let speakerTagIcon = document.createElement('button');
                speakerTagIcon.id = region.id + "|speakerTagIcon";
                speakerTagIcon.title = "Speaker";
                speakerTagIcon.className = "btn btn-sm m-0 p-0";
                speakerTagIcon.style.backgroundColor = "#0e0918";
                speakerTagIcon.style.color = "#FF9E44"
                speakerTagIcon.innerHTML = `${renderToString(<RiUserVoiceFill />)}`

                // speakerTagIcon.style.position = 'absolute';
                // speakerTagIcon.style.width = "fit-content"
                // speakerTagIcon.style.marginLeft = `10px`
                // speakerTagIcon.style.zIndex = "15";

                speakerTagIcon.addEventListener('click', async function (e: any) {
                    e.stopPropagation();
                    let targetSpeakerButton: any = e.target;
                    // if (e.target.nodeName.toLowerCase() === "button") {
                    //     targetTagButton = e.target;
                    // } else if (e.target.nodeName.toLowerCase() === "svg" || e.target.nodeName.toLowerCase() === "img") {
                    //     targetTagButton = e.target.parentNode;
                    // } else if (e.target.nodeName.toLowerCase() === "path") {
                    //     targetTagButton = e.target.parentNode.parentNode;
                    // }
                    while (targetSpeakerButton.nodeName.toLowerCase() !== "button") {
                        targetSpeakerButton = targetSpeakerButton.parentNode;
                    }
                    // console.log("TAGS BUTTON PRESSED: ", targetSpeakerButton);
                    toggleSpeakerList(targetSpeakerButton.id.split('|')[0], null)
                    toggleTagList(targetSpeakerButton.id.split('|')[0], "none")
                    await focusOnCurrentRegion(wavesurfer.current.regions.list[targetSpeakerButton.id.split('|')[0]], false, false);
                });

                speakerTagDiv.appendChild(speakerTagIcon);

                let speakerTagNum = document.createElement('button');
                speakerTagNum.id = region.id + "|speakerNumber";
                speakerTagNum.title = "Speaker Number"
                speakerTagNum.className = "btn btn-sm btn-primary m-0 py-0 px-1";
                speakerTagNum.style.backgroundColor = "MidnightBlue";
                // speakerTagNum.style.color = "#FF9E44";
                speakerTagNum.innerText = tagsRegionMapRef.current[region.id]["speakerNo"];
                speakerTagNum.onclick = async (event: any) => {
                    event.stopPropagation();
                    await focusOnCurrentRegion(wavesurfer.current.regions.list[event.target.id.split('|')[0]], false, false);
                }
                speakerTagDiv.appendChild(speakerTagNum);
                // speakerTagDiv.appendChild(speakerLeftDiv);

                // let copyRegionTextIcon = document.createElement('button');
                // copyRegionTextIcon.id = region.id + "|regTxtCopy";
                // copyRegionTextIcon.title = "Copy Text";
                // copyRegionTextIcon.className = "btn btn-sm m-0 py-0 px-1";
                // copyRegionTextIcon.style.fontSize = "75%"
                // copyRegionTextIcon.style.backgroundColor = "#0e0918";
                // copyRegionTextIcon.style.color = "#FF9E44"
                // copyRegionTextIcon.innerHTML = `${renderToString(<IoCopy />)}`;

                // copyRegionTextIcon.addEventListener('click', function (event: any) {
                //     event.stopPropagation();

                //     let targetCopyButton: any = event.target;

                //     while(targetCopyButton.nodeName.toLowerCase() !== "button"){
                //         targetCopyButton = targetCopyButton.parentNode;
                //     }
                //     let textBoxElement = status == 0 ? document.getElementById(`${targetCopyButton.id.slice(0, -3)}txt`) : status == 1 ? document.getElementById(`${targetCopyButton.id.slice(0, -3)}phn`) : null;

                //     if(textBoxElement != null){
                //         await navigator.clipboard.writeText((textBoxElement as HTMLTextAreaElement).value);
                //         ToastSuccessMsg("Text Copied Successfully !", 1500)
                //     }
                // });
                // speakerTagDiv.appendChild(copyRegionTextIcon);

                let speakerTagList = document.createElement("ul");
                speakerTagList.id = region.id + "|speakerTagList";
                speakerTagList.style.backgroundColor = "FloralWhite";
                // speakerTagList.style.zIndex = "25";

                ["Speaker 1", "Speaker 2", "Speaker 3", "Speaker 4", "Speaker 5"].forEach((speakerNo: any, index: any) => {
                    let tagItem = document.createElement("li");
                    tagItem.title = speakerNo;
                    tagItem.innerText = speakerNo;
                    tagItem.id = speakerNo;

                    tagItem.addEventListener('mouseover', function (e: any) {
                        e.stopPropagation();
                        e.target.style.backgroundColor = "#006B43";
                        e.target.style.color = "FloralWhite";
                    });

                    tagItem.addEventListener('mouseout', function (e: any) {
                        e.target.style.backgroundColor = "FloralWhite";
                        e.target.style.color = "#000";
                    });

                    tagItem.addEventListener('click', async function (e: any) {
                        e.stopPropagation();

                        let targetSpeakerNum = (document.getElementById(`${e.target.parentNode.id.split('|')[0]}|speakerNumber`) as HTMLButtonElement);
                        targetSpeakerNum.innerText = e.target.innerText.split(' ')[1]
                        tagsRegionMapRef.current[e.target.parentNode.id.split('|')[0]] = {
                            ...tagsRegionMapRef.current[e.target.parentNode.id.split('|')[0]],
                            "speakerNo": e.target.innerText.split(' ')[1]
                        }
                        // console.log(tagsRegionMapRef.current)

                        toggleSpeakerList(e.target.parentNode.id.split('|')[0], "none");
                    });
                    speakerTagList.appendChild(tagItem);
                });

                speakerTagList.style.position = "absolute"
                speakerTagList.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 3}px`;
                speakerTagList.style.width = "120px"

                let speakerNumInput = document.createElement("input");
                speakerNumInput.id = region.id + "|speakerNumInput";
                speakerNumInput.type = "number"
                speakerNumInput.title = "Speaker Number"
                speakerNumInput.defaultValue = "6"
                speakerNumInput.min = "1"
                speakerNumInput.max = "100"
                speakerNumInput.style.backgroundColor = "MistyRose";
                // speakerNumInput.style.zIndex = "25";

                speakerNumInput.style.position = "absolute"

                speakerNumInput.style.width = "120px";

                speakerTagList.style.display = "none";
                speakerNumInput.style.display = "none";

                speakerNumInput.addEventListener('mousedown', (event: any) => {
                    event.stopPropagation();
                });

                speakerNumInput.addEventListener('click', (event: any) => {
                    event.stopPropagation();
                });

                speakerNumInput.addEventListener('input', (event: any) => {
                    event.stopPropagation();
                    let outputValue = event.target.value;
                    if (isNaN(parseInt(outputValue))) {
                        outputValue = 1;
                    }
                    outputValue = Math.min(Math.max(parseInt(outputValue), 1), 100);
                    document.getElementById(event.target.id.split('|')[0] + '|speakerNumInput').innerText = event.target.value;
                    let targetSpeakerNum = (document.getElementById(`${event.target.id.split('|')[0]}|speakerNumber`) as HTMLButtonElement);
                    targetSpeakerNum.innerText = outputValue

                    tagsRegionMapRef.current[event.target.id.split('|')[0]] = {
                        ...tagsRegionMapRef.current[event.target.id.split('|')[0]],
                        "speakerNo": outputValue
                    }
                })

                // tagsButton.style.display = 'none';
                // speakerTagDiv.style.display = 'none';

                if (status < TRANSCRIPTION_LEVEL) {
                    regionElement?.appendChild(tagDiv);
                    regionElement?.appendChild(tagList);

                    tagsRegionMapRef.current[region.id]["tagList"].forEach((tag: any) => {
                        let tagSpan = document.createElement('span')
                        tagSpan.className = 'key m-0 p-0'
                        tagSpan.style.fontSize = "72%"
                        tagSpan.style.fontWeight = "bolder"
                        tagSpan.title = `${targetDict[tag]}`
                        tagSpan.id = tag;
                        tagSpan.innerText = tag;

                        let tagSpanClose = document.createElement('span')
                        tagSpanClose.innerHTML = `&nbsp;&times;`
                        tagSpanClose.onclick = (event: any) => {
                            event.stopPropagation()
                            let tagList = tagsRegionMapRef.current[region.id]["tagList"]
                            const index = tagList.indexOf(event.target.parentNode.id);
                            if (index > -1) { // only splice array when item is found
                                tagList.splice(index, 1); // 2nd parameter means remove one item only
                            }
                            document.getElementById(`${region.id}|tagDiv`).removeChild(event.target.parentNode)
                            // console.log(event.target.parentNode.id, tagsRegionMapRef.current);
                        }
                        tagSpan.appendChild(tagSpanClose);

                        document.getElementById(`${region.id}|tagDiv`).appendChild(tagSpan)
                    });

                    if (status == SENTENCE_LEVEL) {
                        regionElement?.appendChild(speakerTagDiv);
                        regionElement?.appendChild(speakerTagList);
                        regionElement?.appendChild(speakerNumInput);
                    }
                }
                // regionElement.title = region.id
                await focusOnCurrentRegion(region, false, false)
            });

            wavesurfer.current.on('region-update-end', async function (region: any) {
                console.log("REGION UPDATE END: ", region);

                let status = previousAnnotationRef.current.annotation_status;

                let regionElement = document.querySelector('[data-id="' + region.id + '"]') as HTMLElement;
                // regionElement.style.borderLeft = '0.1px solid rgba(0, 0, 0, 0)'
                // regionElement.style.borderRight = '0.1px solid rgba(0, 0, 0, 0)'

                if (await isChildlessRegion(region)) {
                    wavesurfer.current.seekTo(region.start / wavesurfer.current.getDuration());
                    removeLastChildlessRegion(1);
                } else {
                    removeLastChildlessRegion(0);
                }

                // lastSelectedRegion.current = null;
                // lastSelectedRegion.current = region;

                if (!(await isChildlessRegion(region))) {
                    if (status == PHONEME_LEVEL) {
                        let parentRegion = wavesurfer.current.regions.list[region.id.split('-').slice(0, 2).join('-')];
                        let prevRegion = (await getPreviousRegion(region))['previousRegion'];
                        let nextRegion = (await getNextRegion(region))['nextRegion'];

                        let siblingCount = 0

                        for await (const regionn of Object.values(wavesurfer.current.regions.list)) {
                            let reg: any = regionn
                            if (reg.id.startsWith(region.id.split('-').slice(0, 2).join('-') + '-')) {
                                siblingCount += 1;
                            }
                        }
                        console.log("Sibling Count: ", siblingCount);

                        let firstChild = parseInt(region.id.split('-')[2]) == 1 ? true : false;
                        let lastChild = parseInt(region.id.split('-')[2]) == siblingCount ? true : false

                        // if (firstChild && region.start != parentStart) {
                        //     region.update({
                        //         start: parentStart
                        //     })
                        // }
                        // if (lastChild && region.end != parentEnd) {
                        //     region.update({
                        //         end: parentEnd
                        //     })
                        // }

                        if (firstChild) {
                            if (region.start < prevRegion.end) {
                                if (region.id != prevRegion.id && parentRegion.id != prevRegion.id) {
                                    region.update({
                                        start: prevRegion.end
                                    })
                                }
                            }
                            parentRegion.update({
                                start: region.start,
                                // end: region.end
                            })
                        }
                        if (lastChild) {
                            if (region.end > nextRegion.start) {
                                if (region.id != nextRegion.id && parentRegion.id != nextRegion.id) {
                                    region.update({
                                        end: nextRegion.start
                                    })
                                }
                            }
                            parentRegion.update({
                                // start: region.start,
                                end: region.end
                            })
                        }

                        if (!firstChild) {
                            let prevSibling = wavesurfer.current.regions.list[`${region.id.split('-').slice(0, 2).join('-')}-${parseInt(region.id.split('-')[2]) - 1}`];

                            if (region.start <= prevSibling.start) {
                                region.update({
                                    start: prevSibling.end
                                })
                            }

                            if (pairingRegionEnabled.current) {
                                if (region.start != prevSibling.end) {
                                    prevSibling.update({
                                        end: region.start
                                    })
                                }
                            }
                        }
                        if (!lastChild) {
                            let nextSibling = wavesurfer.current.regions.list[`${region.id.split('-').slice(0, 2).join('-')}-${parseInt(region.id.split('-')[2]) + 1}`];

                            if (region.end >= nextSibling.end) {
                                region.update({
                                    end: nextSibling.start
                                })
                            }

                            if (pairingRegionEnabled.current) {
                                if (region.end != nextSibling.start) {
                                    nextSibling.update({
                                        start: region.end
                                    })
                                }
                            }
                        }
                    }
                    else if (status == WORD_LEVEL) {
                        let sortedRegions: any = Object.values(wavesurfer.current.regions.list).sort(function (a: any, b: any) {
                            return a.start - b.start;
                        });
                        let regionIndex = -1;
                        for (let i = 0; i < sortedRegions.length; i++) {
                            if (region.id == sortedRegions[i].id) {
                                regionIndex = i;
                                break;
                            }
                        }
                        let firstChild = regionIndex == 0 ? true : false;
                        let lastChild = regionIndex == sortedRegions.length - 1 ? true : false;

                        if (!firstChild) {
                            let prevSibling = wavesurfer.current.regions.list[sortedRegions[regionIndex - 1].id];

                            if (region.start <= prevSibling.start) {
                                region.update({
                                    start: prevSibling.end
                                })
                            }
                        }

                        if (!lastChild) {
                            let nextSibling = wavesurfer.current.regions.list[sortedRegions[regionIndex + 1].id];

                            if (region.end >= nextSibling.end) {
                                region.update({
                                    end: nextSibling.start
                                })
                            }
                        }
                    }
                    if (status < PHONEME_LEVEL) {
                        await shiftAndRemove(region);
                    }
                }
                await focusOnCurrentRegion(region, false, false);
            });

            wavesurfer.current.on('region-removed', async function (region: any) {
                console.log("REGION REMOVED !!! ", region);
                if (lastSelectedRegion.current != null && lastSelectedRegion.current.id == region.id) {
                    lastSelectedRegion.current = null;

                    if (playRegion.current || playRegionLoop.current) {
                        playRegion.current = false;
                        playRegionLoop.current = false;
                    }
                    if (wavesurfer.current.isPlaying()) {
                        setPlaying(false)
                        try {
                            wavesurfer.current.pause();
                        } catch (err: any) {
                            console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                        }
                    }
                }
                // UNDO
            });

            wavesurfer.current.on('region-play', async function (region: any) {

            });

            wavesurfer.current.on('region-in', async function (region: any) {
                // console.log(document.activeElement?.nodeName);

                if (playUniversal.current == true && playRegion.current == false && playRegionLoop.current == false && document.activeElement?.nodeName !== "TEXTAREA") {
                    focusOnCurrentRegion(region, true, false)
                }
            });

            wavesurfer.current.on('region-out', async function (region: any) {
                console.log("REGION OUT !!!");

                console.log(lastSelectedRegion.current);
                console.log(playRegion.current, playRegionLoop.current, playUniversal.current, wavesurfer.current.getCurrentTime(), region.end);

                if (lastSelectedRegion.current != null && wavesurfer.current.getCurrentTime() >= region.end) {
                    if (lastSelectedRegion.current.id == region.id) {
                        if (playRegion.current) {
                            try {
                                if (wavesurfer.current.isPlaying()) {
                                    wavesurfer.current.pause();
                                }
                            } catch (err: any) {
                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                            }
                            setPlayingRegion(false);
                            wavesurfer.current.seekTo(region.start / wavesurfer.current.getDuration());
                        } else if (playRegionLoop.current) {
                            try {
                                if (wavesurfer.current.isPlaying()) {
                                    wavesurfer.current.pause();
                                }
                            } catch (err: any) {
                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                            }
                            // setPlayingLoop(false);
                            wavesurfer.current.seekTo(region.start / wavesurfer.current.getDuration());
                            if (previousAnnotationRef.current.annotation_status == PHONEME_LEVEL) {
                                await sleep(500);
                            }
                            // setPlayingLoop(true);
                            if (playRegionLoop.current) {
                                try {
                                    if (!(wavesurfer.current.isPlaying())) {
                                        wavesurfer.current.play();
                                    }
                                } catch (err: any) {
                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                }
                            }
                        } else {
                            // console.log("-----", document.activeElement?.nodeName);
                            if (document.activeElement?.nodeName !== "TEXTAREA") {
                                deFocusCurrentRegion(false)
                            }
                        }
                    }
                }
            });

            wavesurfer.current.on('region-mouseenter', function (region: any) {
                // if (lastSelectedRegion.current == null || lastSelectedRegion.current.id != region.id) {
                //     region.update({
                //         color: 'rgba(0, 107, 67, 0.1)'
                //     })
                // }
                // let regionElement = document.querySelector('[data-id="' + region.id + '"]') as HTMLElement;
                // regionElement.title = ""

                // console.log("REGION: ", region)

                // currentRegion.update({
                //     color: selectedRegionColor.current
                // });

                // Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
                //     if (region.id != currentRegion.id) {
                //         region.update({
                //             color: `rgba(0, 0, 0, 0)`
                //         });
                //     }
                // });

                // let status = previouslySavedAnnotation.annotation_status;
                // let unlock = (status == 0 || status == 2);
                // region.color = `rgba(${unlock ? 0 : 100}, 0, ${unlock ? 100 : 0}, 0.1)`;
            });

            wavesurfer.current.on('region-mouseleave', function (region: any) {
                // console.log("REGION: ", region)

                // if (lastSelectedRegion.current == null || lastSelectedRegion.current.id != region.id) {
                //     region.update({
                //         color: `rgba(0, 0, 0, 0)`
                //     });
                // }

                // let status = previouslySavedAnnotation.annotation_status;
                // let unlock = (status == 0 || status == 2);
                // region.color = `rgba(${unlock ? 0 : 100}, ${unlock ? 100 : 0}, 0, 0.1)`;
            });
        }
    }

    const saveOrSubmit = async (submit: any) => {
        console.log("INSIDE SAVE SUBMIT: ", submit);

        let currentAnnotationList: any = [];
        let status = previousAnnotationRef.current.annotation_status;

        if (Object.values(wavesurfer.current.regions.list).length == 0) {
            if (submit == 1) {
                ToastFailedMsg("রিজিওনের সংখ্যা শূন্য !");
                return;
            }
        }

        if (status == PHONEME_LEVEL) {
            currentAnnotationList = [...previousAnnotationRef.current.annotation]
            let graphemeIndex = -1;
            let phonemeIndex = -1;

            for await (const regionn of Object.values(wavesurfer.current.regions.list)) {
                let region: any = regionn

                let children = document.querySelectorAll(`[data-id=${region.id}] textarea`);

                for (let i = 0; i < children.length; i++) {
                    if (children[i].id.endsWith('txt')) {
                        graphemeIndex += 1;
                        phonemeIndex = -1;

                        currentAnnotationList[graphemeIndex].start = region.start;
                        currentAnnotationList[graphemeIndex].end = region.end;
                    } else if (children[i].id.endsWith('phn')) {
                        phonemeIndex += 1
                        currentAnnotationList[graphemeIndex].alignment[phonemeIndex].start = region.start;
                        currentAnnotationList[graphemeIndex].alignment[phonemeIndex].end = region.end;
                    }
                }
            };
        } else {
            let regionIndex = 0;
            let status = previousAnnotationRef.current.annotation_status;
            for await (const regionn of Object.values(wavesurfer.current.regions.list)) {
                let region: any = regionn
                if (await isChildlessRegion(region)) {
                    if (submit == 1) {
                        ToastFailedMsg("Region ট্যাগ শূন্য অথবা লেখা শূন্য রাখা যাবে না !");
                        return;
                    }
                }

                let children = document.querySelectorAll(`[data-id=${region.id}] textarea`);

                let graphemeVerdict = ""
                let phonemeVerdict = ""

                for (let i = 0; i < children.length; i++) {
                    let childrenValue = (children[i] as HTMLTextAreaElement).value.replaceAll('\n', ' ')
                    // /^\s+$/.test(childrenValue)
                    if (childrenValue.trim() == '') {
                        if (!(tagsRegionMapRef.current[region.id]["tagList"].length == 1 && tagsRegionMapRef.current[region.id]["tagList"].includes('OVL'))) {
                            if (submit == 1) {
                                // children[i].scrollIntoView()
                                (children[i] as HTMLElement).focus();
                                ToastFailedMsg("Region লেখা শূন্য রাখা যাবে না !");
                                return;
                            }
                        }
                    }
                    if (children[i].id.endsWith('txt')) {
                        let regex = /[^\u0981-\u0983\u0985-\u098B\u098F-\u0990\u0993-\u09A8\u09AA-\u09B0\u09B2\u09B6-\u09B9\u09BE-\u09C3\u09C7-\u09C8\u09CB-\u09CE\u09DC-\u09DD\u09DF\u200C\u200D,;।?!"'‘’“”:—৳#*%@ -]+|৳৳|##|[*][*]|%%|@@|[@#৳%*]$/

                        if (previousAnnotationRef.current.module == 'tts') {
                            regex = /[^\u0981-\u0983\u0985-\u098B\u098F-\u0990\u0993-\u09A8\u09AA-\u09B0\u09B2\u09B6-\u09B9\u09BE-\u09C3\u09C7-\u09C8\u09CB-\u09CE\u09DC-\u09DD\u09DF\u200C\u200D,;।?!"'‘’“”:—@# -]+|@@|##|[@#]$/
                        }
                        if (regex.test(childrenValue)) {
                            if (submit == 1) {
                                (children[i] as HTMLElement).focus()
                                ToastFailedMsg("এই অক্ষরগুলো এভাবে দেওয়া যাবে না !");
                                return;
                            }
                        } else {
                            if (previousAnnotationRef.current.module == 'tts') {
                                let no_of_hash_occurances = childrenValue.match(/#/g)
                                let no_of_atrate_occurances = childrenValue.match(/@/g)

                                console.log(no_of_hash_occurances, no_of_atrate_occurances)

                                if ((no_of_hash_occurances && no_of_hash_occurances.length % 2 != 0) || (no_of_atrate_occurances && no_of_atrate_occurances.length % 2 != 0)) {
                                    (children[i] as HTMLElement).focus()
                                    ToastFailedMsg("@ অথবা # শুরু করেছেন, অথচ শেষ করেন নাই !");
                                    return;
                                }
                            }
                        }
                        graphemeVerdict += childrenValue + ' '
                    } else if (children[i].id.endsWith('phn')) {
                        // if (/[^ɔaæiueokʰgŋcɟtdʱt̪d̪npbmrlʃshɽyʲʷ̃͜͡ːˑ̌ʔǁǀu̯]+/.test(childrenValue)) {
                        //     (children[i] as HTMLElement).focus()
                        //     ToastFailedMsg("এই অক্ষরগুলো দেওয়া যাবে না !");
                        //     return;
                        // }
                        if (/[^a-zA-Z*^ ]+/.test(childrenValue)) {
                            if (submit == 1) {
                                (children[i] as HTMLElement).focus()
                                ToastFailedMsg("এই অক্ষরগুলো দেওয়া যাবে না !");
                                return;
                            }
                        }
                        let validated_phoneme = getIpaFromRomanized(childrenValue.trim());
                        phonemeVerdict += validated_phoneme + ' ';
                    }
                }

                //@ts-ignore
                let graphemeObject: any = status <= WORD_LEVEL ? { "verdict": graphemeVerdict.trim() } : previousAnnotationRef.current.annotation[regionIndex]["text"];
                //@ts-ignore
                let phonemeObject: any = status > WORD_LEVEL ? { "verdict": phonemeVerdict.trim() } : {}

                console.log("GRAPHEME OBJECT: ", graphemeObject);
                console.log("PHONEME OBJECT: ", phonemeObject);

                const audioAnnotation: any = {
                    id: region.id,
                    start: region.start,
                    end: region.end,
                    text: graphemeObject,
                    phoneme: status > WORD_LEVEL ? phonemeObject : {},
                    tagList: tagsRegionMapRef.current[region.id]["tagList"],
                    speakerNo: tagsRegionMapRef.current[region.id]["speakerNo"]
                    // overlapped: tagsRegionMapRef.current[region.id]["overlapped"]
                }
                console.log(audioAnnotation);

                if (audioAnnotation['end'] - audioAnnotation['start'] > 17) {
                    if (submit == 1) {
                        let regionElement = (document.querySelector('[data-id="' + region.id + '"]') as HTMLElement);
                        regionElement.focus()
                        ToastFailedMsg("১৭ সেকেণ্ডের বড় সেগমেন্ট রাখা যাবে না ! ভেঙ্গে ছোট করুন !");
                        return;
                    }
                }
                currentAnnotationList.push(audioAnnotation)
                regionIndex += 1;
            };
        }

        // setIsLoading(false)
        // return;

        console.log("Current Annotation: ", currentAnnotationList);
        // return;
        await uploadAnnotation(currentAnnotationList, submit);
        // setTimeout(() => saveOrSubmit(false), 10000);
        // if(intervalRef.current != null){
        //     clearInterval(intervalRef.current)
        // }
        // intervalRef.current = setInterval(() => saveOrSubmit(false), 10000);
    }

    const adjustCanvasHeight = async () => {
        let canvas: any = document.querySelectorAll('canvas');
        // console.log("CANVAS LIST: ", canvas);

        let sumWidth = 0;
        let targetIndex = 0;

        for (let i = 0; i < canvas.length; i++) {
            //@ts-ignore
            if (canvas[i].style.zIndex >= 4) {
                continue;
            }
            sumWidth += $(canvas[i]).width();
            canvas[i].style.height = `${canvasHeightPercentageRef.current * $(waveformRef.current).height()}px`;
            if (canvas[i].style.zIndex == 2) {
                canvas[i].className = "resizable";
                canvas[i].style.pointerEvents = "auto";
                canvas[i].style.borderBottom = "1px solid #471620";
                targetIndex = i;
            }
        }

        Object.values(wavesurfer.current.regions.list).forEach((region: any) => {
            // console.log("REGION ID: ", region.id);

            // let handles = document.querySelectorAll('[data-id="' + region.id + '"] > handle');
            // console.log("HANDLES: ", handles);

            // handles.forEach((handle: any) => {
            //     handle.style.width = '1px';
            //     handle.style.height = `${tierDivHeight * 4}px`;
            //     handle.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height()}px`;
            // })

            let textBoxGraphemeDiv = document.getElementById(`${region.id}|txtdiv`);
            if (textBoxGraphemeDiv) {
                textBoxGraphemeDiv.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight}px`;
            }
            let textBoxPhonemeDiv = document.getElementById(`${region.id}|phndiv`);
            if (textBoxPhonemeDiv) {
                textBoxPhonemeDiv.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 2}px`;
            }

            let status = previousAnnotationRef.current.annotation_status;

            if (status < TRANSCRIPTION_LEVEL) {
                let tagDivElement = document.getElementById(`${region.id}|tagDiv`);
                let tagListElement = document.getElementById(`${region.id}|tagList`);

                tagDivElement.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height()}px`;
                tagListElement.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 2}px`;

                if (status == SENTENCE_LEVEL) {
                    let speakerDivElement = document.getElementById(`${region.id}|speakerTagDiv`);
                    speakerDivElement.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 2}px`;

                    let speakerTagList = document.getElementById(`${region.id}|speakerTagList`);
                    speakerTagList.style.top = `${canvasHeightPercentageRef.current * $(waveformRef.current).height() + tierDivHeight * 3}px`;

                    let speakerNumInput = document.getElementById(`${region.id}|speakerNumInput`);
                    speakerNumInput.style.top = `${parseFloat(speakerTagList.style.top) + $(speakerTagList).height()}px`;
                }
            }
        })

        // let link = $(canvas[targetIndex]);
        // let offset = link.offset();

        // let top = offset.top;
        // let left = offset.left;

        // let bottom = top + link.outerHeight();
        // let right = left + link.outerWidth();

        // console.log("OFFSET: ", offset);
        // console.log("TOP: ", top);
        // console.log("BOTTOM: ", bottom);
        // console.log("LEFT: ", left);
        // console.log("RIGHT: ", right);

        return sumWidth;
    }

    const uploadAnnotation = async (audioRegions: any, submit: any) => {
        console.log(isLoading, loading)
        // if (!isLoading) {
        setIsLoading(true);
        // let logedUserId: any = await getLoggedInUserId();

        if (submit == 0) {
            try {
                let annotatorPickObj: any = { "annotation": audioRegions, "annotator_id": userInfo.current.userId, "speech_id": localStorage.getItem("speech_id") };
                console.log("SAVE : ", annotatorPickObj);

                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                let requestOptions: RequestInit = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(annotatorPickObj),
                    cache: "no-cache"
                };

                let response = await fetch("https://amarkantho.revesoft.com:5108/save/", requestOptions)

                if (response.status === 200 || response.status === 201) {
                    setIsLoading(false);
                    ToastSuccessMsg("Saved");
                } else {
                    setIsLoading(false);
                    ToastFailedMsg("Not Saved")
                }
            } catch (err: any) {
                setLoading(false);
                ToastFailedMsg(err.message + '! Could Not Submit !');
            }
        }
        else if (submit == 1) {
            try {
                let annotatorPickObj: any = { "annotation": audioRegions, "annotator_id": userInfo.current.userId, "speech_id": localStorage.getItem("speech_id"), "column_filters": localStorage.getItem("column_filters") ? localStorage.getItem("column_filters") : [] };
                console.log("SUBMIT : ", annotatorPickObj);

                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                let requestOptions: RequestInit = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(annotatorPickObj),
                    cache: "no-cache"
                };

                let SUBMIT_PORT = sttEvalAnnotators.includes(userInfo.current.userId) ? 5117 : 5109;

                let response = await fetch(`https://amarkantho.revesoft.com:${SUBMIT_PORT}/submit/`, requestOptions)
                if (response.status === 200 || response.status === 201) {
                    setUnloadEventListener(false);
                    let res = await response.json();

                    if (res["speech_id"] == null) {
                        setIsLoading(false);
                        ToastWarningMsg("প্রসেসিং চলছে, কিছুক্ষণ পর HISTORY চেক করুন। নতুন ফাইল পাওয়া যায় নি। Task Page এ ফিরিয়ে নিচ্ছি ...");
                        setTimeout(() => {
                            window.location.replace(window.location.origin + `/annotation-task`);
                            // window.location.reload();
                        }, 10);
                    } else {
                        setIsLoading(false);
                        ToastInfoMsg("প্রসেসিং চলছে, কিছুক্ষণ পর HISTORY চেক করুন। নতুন ফাইলে নেওয়া হচ্ছে। একটু অপেক্ষা করুন ...");
                        localStorage.setItem("speech_id", res["speech_id"])
                        setTimeout(() => {
                            // window.location.replace(window.location.origin + `/annotation-task`);
                            window.location.reload();
                        }, 10);
                    }

                }
                else {
                    setIsLoading(false);
                    ToastFailedMsg("Can NOT Submit ");
                }
            } catch (err: any) {
                setLoading(false);
                ToastFailedMsg(err.message + '! Could Not Submit !');
            }
        }
        // }
    }

    async function onCancelClick() {
        setIsLoading(true);
        setUnloadEventListener(false)
        // window.removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
        let id: any = getLoggedInUserId();

        let requestObj = { "annotator_id": id, "speech_id": localStorage.getItem("speech_id") };
        console.log("CANCEL:", requestObj);

        setTimeout(async () => {
            try {
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                let requestOptions: RequestInit = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(requestObj),
                    cache: "no-cache"
                };
                let res = await fetch("https://amarkantho.revesoft.com:5107/cancel/", requestOptions)

                if (res.status == 200 || res.status == 201) {
                    let data = await res.json();
                    setIsLoading(false);
                    ToastSuccessMsg(data.message);
                    localStorage.removeItem('speech_id');
                    // history.push('/annotation-task')
                    // history.go(0)
                    window.location.replace(window.location.origin + `/annotation-task`)
                    // return true;
                } else {
                    window.location.replace(window.location.origin + `/login`)
                }
            } catch (err: any) {
                setIsLoading(false);
                ToastFailedMsg(err.message + '! Could Not Submit !');
            }
        }, 100);
        // return false;
    }

    // @ts-ignore
    return (
        <div>
            <Backdrop
                sx={{ color: '#b71c1c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={overlappedSnackbar != -1} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={overlappedSnackbar == 1 ? "error" : "success"} sx={{ width: '100%' }}>
                    {overlappedSnackbar == 1 ? "OVERLAPPED !" : overlappedSnackbar == 0 ? "JUST TOUCHES !" : ''} {overlappedSnackbar == 1 ? <JoinInnerIcon /> : overlappedSnackbar == 0 ? <BalanceIcon /> : null}
                </Alert>
            </Snackbar>
            {previousAnnotation ?
                (
                    <div className='m-0 p-0' style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Divider flexItem={true} light={false} orientation='vertical' sx={{ backgroundColor: "#0e0918", opacity: '75%', width: '0.5%' }} />
                        <div style={{ backgroundColor: "#DEDEDE", width: '99.5%' }}>
                            <div id="topNavBar" className='m-0 p-0' style={{ border: '0px solid green' }}>
                                <Box sx={{ m: 0, p: 0, opacity: '75%', width: '100%', height: '10vh', backgroundColor: '#212121', border: '0px solid red', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', overflowX: 'auto', overflowY: 'auto' }}>
                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ p: 0, m: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('decrease canvas height', 'ক্যানভাস এর উচ্চতা হ্রাস করুন', 'CTRL + SHIFT + \u2191')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    console.log("Decrease Canvas Height !!!");
                                                    canvasHeightPercentageRef.current = Math.max(canvasHeightPercentageMin, canvasHeightPercentageRef.current - canvasHeightBigStep);
                                                    await adjustCanvasHeight();
                                                }}>
                                                    <NorthIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('increase canvas height', 'ক্যানভাস এর উচ্চতা বৃদ্ধি করুন', 'CTRL + SHIFT + \u2193')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    console.log("Increase Canvas Height !!!");
                                                    canvasHeightPercentageRef.current = Math.min(canvasHeightPercentageMax, canvasHeightPercentageRef.current + canvasHeightBigStep);
                                                    await adjustCanvasHeight();
                                                }}>
                                                    <SouthIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ p: 0, m: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('decrease wave amplitude', 'ওয়েভ এর উচ্চতা হ্রাস করুন', 'CTRL + \u2193')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    console.log("Decrease Amplitude Button Clicked !!!");
                                                    if (wavesurfer.current.params.barHeight - barHeightStep >= minBarHeight) {
                                                        wavesurfer.current.params.barHeight -= barHeightStep;
                                                        wavesurfer.current.empty();
                                                        wavesurfer.current.drawBuffer();
                                                        await adjustCanvasHeight();
                                                    }
                                                }}>
                                                    <WaterIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('increase wave amplitude', 'ওয়েভ এর উচ্চতা বৃদ্ধি করুন', 'CTRL + \u2191')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    console.log("Increase Amplitude Button Clicked !!!");
                                                    if (wavesurfer.current.params.barHeight + barHeightStep <= maxBarHeight) {
                                                        wavesurfer.current.params.barHeight += barHeightStep;
                                                        wavesurfer.current.empty();
                                                        wavesurfer.current.drawBuffer();
                                                        await adjustCanvasHeight();
                                                    }
                                                }}>
                                                    <WavesIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ m: 0, p: 0, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', border: '2px solid rgba(33, 33, 33, 0.75)' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('change background color', 'ব্যাকগ্রাউন্ড কালার পরিবর্তন করুন', '')}
                                            >
                                                <input type="color" id="background" value={backgroundColor} onChange={(event: any) => {
                                                    setBackgroundColor(event.target.value);
                                                }} />
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('change waveform color', 'ওয়েভ কালার পরিবর্তন করুন', '')}
                                            >
                                                <input type="color" id="wave" value={waveColor} onChange={(event: any) => {
                                                    setWaveColor(event.target.value);
                                                }} />
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('change progress color', 'প্রগ্রেস কালার পরিবর্তন করুন', '')}
                                            >
                                                <input type="color" id="progress" value={progressColor} onChange={(event: any) => {
                                                    setProgressColor(event.target.value);
                                                }} />
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('change playhead color', 'প্লে হেড এর কালার পরিবর্তন করুন', '')}
                                            >
                                                <input type="color" id="handle" value={handleColor} onChange={(event: any) => {
                                                    setHandleColor(event.target.value);
                                                }} />
                                            </Tooltip>
                                        </Box>

                                        {/* <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} /> */}

                                        <Box sx={{ m: 0, p: 0, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', border: '2px solid rgba(33, 33, 33, 0.75)' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('toggle dark mode', 'ডার্ক মোড সুইচ করুন', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918', minWidth: '20%', maxWidth: '20%' }} onClick={() => {
                                                    setDarkMode(!darkMode);
                                                }}>
                                                    {darkMode ? <DarkModeIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} /> : <LightModeIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />}
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('zoom in / out', 'ZOOM বৃদ্ধি বা হ্রাস করুন', 'CTRL + অথবা CTRL - অথবা CTRL + SCROLL')}
                                            >
                                                <Slider
                                                    size="small"
                                                    aria-label="Small"
                                                    valueLabelDisplay="auto"
                                                    sx={{ minWidth: '75%', maxWidth: '75%' }}
                                                    min={zoomMin}
                                                    max={zoomMax}
                                                    // defaultValue={70}
                                                    value={zoomSliderValue}
                                                    onChange={(event, value, activeThumb) => {
                                                        // console.log("EVENT: ", event);
                                                        // console.log("VALUE: ", value);
                                                        // console.log("ACTIVE THUMB: ", activeThumb);
                                                        setZoomSliderValue(value);
                                                    }}
                                                    onChangeCommitted={async (event, value) => {
                                                        console.log("EVENT: ", event);
                                                        console.log("VALUE: ", value);
                                                        let status = previousAnnotationRef.current.annotation_status
                                                        setZoomSliderValue(value);

                                                        wavesurfer.current.zoom(value, true)
                                                        // makeCanvasShorter()
                                                        // wavesurfer.current.drawer.clearWave();
                                                        // wavesurfer.current.drawBuffer();

                                                        // if (wavesurfer.current.spectrogram) {
                                                        //     wavesurfer.current.spectrogram.init();
                                                        // }
                                                        // wavesurfer.current.timeline.init();
                                                        await adjustCanvasHeight();
                                                        // console.log("AFTER ZOOM: ", wavesurfer.current.params.minPxPerSec, wavesurfer.current.params.pixelRatio);

                                                        if (showSpectrogram) {
                                                            if (status > TRANSCRIPTION_LEVEL) {
                                                                if (wavesurfer.current.params.minPxPerSec < 6000) {
                                                                    wavesurfer.current.spectrogram.init();
                                                                    // wavesurfer.current.spectrogram.render();
                                                                } else {
                                                                    wavesurfer.current.spectrogram.canvas.style.width = Math.max(wavesurfer.current.drawer.getWidth(), wavesurfer.current.getDuration() * wavesurfer.current.params.minPxPerSec) + "px";
                                                                }
                                                                // console.log("NOW NOW: ", wavesurfer.current.spectrogram.canvas.style.width);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('skip backward 2.5 sec', '২.৫ সেকেণ্ড পিছনে যান', 'CTRL + 4')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.skipBackward(seekStep);
                                                }}>
                                                    <SkipPreviousIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('skip forward 2.5 sec', '২.৫ সেকেণ্ড সামনে যান', 'CTRL + 6')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.skipForward(seekStep);
                                                }}>
                                                    <SkipNextIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('go to start', 'একদম শুরুতে যান', 'CTRL + R')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.stop();
                                                    wavesurfer.current.seekAndCenter(0);
                                                    if (lastSelectedRegion.current) {
                                                        deFocusCurrentRegion(false)
                                                    }
                                                }}>
                                                    <ReplayIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            {/* <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('toggle mute', 'সাউন্ড বন্ধ / চালু করুন', 'CTRL + /')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.toggleMute();
                                                    setMute(wavesurfer.current.getMute())
                                                }}>
                                                    {mute ? <VolumeUpIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} /> : <VolumeOffIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />}
                                                </Button>
                                            </Tooltip> */}

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('Toggle Normalize', 'অডিওর ভলিউম পিক নরমালাইজ করুন', 'CTRL + ALT + N')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    if (normalizedRef.current) {
                                                        amplifyMedia(document.querySelector('audio'), 1);
                                                    } else {
                                                        // Calculate the gain adjustment factor based on target and actual dBFS
                                                        const gainFactor = Math.pow(10, (targetdBFS - maxAvgRmsdBFS.current['maxDbfs']) / 20);
                                                        console.log("GAIN FACTOR: ", gainFactor)
                                                        if (Number.isFinite(gainFactor)) {
                                                            amplifyMedia(document.querySelector('audio'), Math.max(gainFactor, 1));
                                                        }
                                                    }
                                                    normalizedRef.current = !normalizedRef.current
                                                    setNormalized(normalizedRef.current)
                                                }}>
                                                    <EqualizerIcon fontSize='large' color={normalized ? 'success' : 'error'} sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        {/* <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                // title={buttonTooltip('masking interference', 'মাস্কিং ইন্টারফিয়ারেন্স', '')}
                                                title={buttonTooltip('normalize waveform', 'ওয়েভফর্ম নরমালাইজ করুন', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    wavesurfer.current.params.normalize = wavesurfer.current.params.normalize ? false : true;
                                                    wavesurfer.current.empty();
                                                    wavesurfer.current.drawBuffer();
                                                    await adjustCanvasHeight();
                                                    // setHasMaskingInterference(!hasMaskingInterference)
                                                }}>
                                                    <EqualizerIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                    // <LensBlurIcon fontSize='large' color={hasMaskingInterference ? 'error' : 'warning'} sx={{ m: 0, p: 0 }} />

                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip
                                                // disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: 'rgba(26, 0, 13, 1)',
                                                            // color: "floralwhite",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={
                                                    <Stack sx={{ m: 0, p: 0, width: '100%' }}>
                                                        <Box sx={{ px: 1, py: 0.5, width: '100%', backgroundColor: 'rgba(32, 32, 32, 1)', textAlign: 'center', fontFamily: 'Roboto, sans-serif', fontSize: '150%', fontWeight: 'bolder' }}>AMPLIFY (1 - 250)</Box>
                                                        <Divider light={true} orientation='horizontal' sx={{ height: '0.5px', backgroundColor: 'rgba(62, 39, 35, 1)' }} />
                                                        <TextField
                                                            id="outlined-number"
                                                            label="GAIN"
                                                            type="number"
                                                            // defaultValue={1}
                                                            value={currentGain}
                                                            onChange={(event: any) => {
                                                                // console.log(event.target.value);
                                                                let gain = isNaN(parseInt(event.target.value)) ? 1 : parseInt(event.target.value);
                                                                if (gain < 1) {
                                                                    gain = 1
                                                                }
                                                                if (gain > 250) {
                                                                    gain = 250
                                                                }
                                                                setCurrentGain(event.target.value);
                                                                amplifyMedia(document.querySelector('audio'), Math.max(gain, 1));

                                                                // gainRef.current = isNaN(parseInt(event.target.value)) ? 1 : parseInt(event.target.value)
                                                            }}
                                                            sx={{ input: { color: 'floralWhite', fontWeight: 'bold', fontSize: '125%', fontFamily: 'Roboto, sans-serif' }, label: { color: '#bf360c', fontWeight: 'bold', fontSize: '125%', fontFamily: 'Roboto, sans-serif' } }}
                                                        // sx={{
                                                        //     backgroundColor: 'rgba(26, 0, 13, 1)',
                                                        //     color: 'floralwhite'
                                                        // }}
                                                        // InputLabelProps={{
                                                        //     shrink: true,
                                                        // }}
                                                        />

                                                        <Divider light={true} orientation='horizontal' sx={{ height: '0.5px', backgroundColor: 'rgba(62, 39, 35, 1)' }} />
                                                        {/* <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                            // let audioElement = document.querySelector('audio');
                                                            // audioElement.crossOrigin = "anonymous";
                                                            // console.log("Volume: ", wavesurfer.current.getVolume());

                                                            // console.log("CURRENT GAIN: ", currentGain);

                                                            amplifyMedia(document.querySelector('audio'), Math.max(currentGain, 1));

                                                            // // create an audio context and hook up the video element as the source
                                                            // var audioCtx = new (window.AudioContext)();
                                                            // var source = audioCtx.createMediaElementSource(audioElement);
                                                            // // create a gain node
                                                            // var gainNode = audioCtx.createGain();
                                                            // gainNode.gain.value = 10; // double the volume
                                                            // source.connect(gainNode);

                                                            // // connect the gain node to an output destination
                                                            // gainNode.connect(audioCtx.destination);
                                                            // audioCtx.resume();
                                                        }}>
                                                            <EqualizerIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                        </Button> /*}
                                                    </Stack>
                                                }
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} >
                                                    <SurroundSoundIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box> */}
                                    </Box>

                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            {/* <div className='col-1 p-0 m-0' >
                                                <Button variant="outline-primary" >
                                                    <img className='img-fluid'
                                                        src={playImg}
                                                        // style={{ width: "25px", height: "25px" }}
                                                        alt="play-img"
                                                        title='universal play'
                                                    />
                                                </Button>
                                            </div> */}

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('decrease playback rate by 0.1', 'স্পীচের গতি 0.1 হারে হ্রাস করুন', 'CTRL + [')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.setPlaybackRate(Math.max(minPlaybackRate, wavesurfer.current.getPlaybackRate() - playbackRateStep))
                                                }}>
                                                    <FastRewindIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('reset playback rate', 'স্পীচের গতি স্বাভাবিক করুন', 'CTRL + |')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.setPlaybackRate(1);
                                                }}>
                                                    <SettingsInputComponentIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('increase playback rate by 0.1', 'স্পীচের গতি 0.1 হারে বৃদ্ধি করুন', 'CTRL + ]')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={() => {
                                                    wavesurfer.current.setPlaybackRate(Math.min(maxPlaybackRate, wavesurfer.current.getPlaybackRate() + playbackRateStep))
                                                }}>
                                                    <FastForwardIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('universal play', 'অডিও প্লে করুন', 'CTRL + U')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    if (audioContextRef && audioContextRef.current.state == "suspended") {
                                                        await audioContextRef.current.resume();
                                                    }
                                                    if (!(wavesurfer.current.isPlaying())) {
                                                        setPlaying(true);
                                                        setPlayingRegion(false);
                                                        setPlayingLoop(false);

                                                        playUniversal.current = true;
                                                        playRegion.current = false;
                                                        playRegionLoop.current = false;

                                                        try {
                                                            if (!(wavesurfer.current.isPlaying())) {
                                                                wavesurfer.current.play();
                                                            }
                                                        } catch (err: any) {
                                                            console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                                        }
                                                    } else if (!playUniversal.current && wavesurfer.current.isPlaying()) {
                                                        setPlaying(true);
                                                        setPlayingRegion(false);
                                                        setPlayingLoop(false);

                                                        playUniversal.current = true;
                                                        playRegion.current = false;
                                                        playRegionLoop.current = false;
                                                    } else if (playUniversal.current && wavesurfer.current.isPlaying()) {
                                                        try {
                                                            wavesurfer.current.pause();
                                                        } catch (err: any) {
                                                            console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                                                        }

                                                        setPlaying(false);
                                                        setPlayingRegion(false);
                                                        setPlayingLoop(false);

                                                        playUniversal.current = false;
                                                        playRegion.current = false;
                                                        playRegionLoop.current = false;
                                                    }
                                                }}>
                                                    {playing ? <PauseIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} /> : <PlayArrowIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />}
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('region play from start', 'সিলেক্টেড রিজিওনটি শুরু থেকে প্লে করুন', 'CTRL + SHIFT + SPACE')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    if (lastSelectedRegion.current != null) {
                                                        console.log("Region Play from Start pressed");

                                                        if (audioContextRef && audioContextRef.current.state == "suspended") {
                                                            await audioContextRef.current.resume();
                                                        }

                                                        if (!(wavesurfer.current.isPlaying())) {
                                                            playUniversal.current = false;
                                                            playRegion.current = true;
                                                            playRegionLoop.current = false;

                                                            setPlaying(false);
                                                            setPlayingRegion(true);
                                                            setPlayingLoop(false);

                                                            wavesurfer.current.seekTo(lastSelectedRegion.current.start / wavesurfer.current.getDuration())
                                                            try {
                                                                if (!(wavesurfer.current.isPlaying())) {
                                                                    wavesurfer.current.play();
                                                                }
                                                            } catch (err: any) {
                                                                console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                                            }
                                                        } else if (!playRegion.current && wavesurfer.current.isPlaying()) {
                                                            playUniversal.current = false;
                                                            playRegion.current = true;
                                                            playRegionLoop.current = false;

                                                            setPlaying(false);
                                                            setPlayingRegion(true);
                                                            setPlayingLoop(false);
                                                        } else if (playRegion.current && wavesurfer.current.isPlaying()) {
                                                            try {
                                                                wavesurfer.current.pause();
                                                            } catch (err: any) {
                                                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                                                            }

                                                            playUniversal.current = false;
                                                            playRegion.current = false;
                                                            playRegionLoop.current = false;

                                                            setPlaying(false);
                                                            setPlayingRegion(false);
                                                            setPlayingLoop(false);
                                                        }
                                                    }
                                                }}>
                                                    {playingRegion ? <PausePresentationIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} /> : <SmartDisplayOutlinedIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />}
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('region play in loop', 'সিলেক্টেড রিজিওনটি বার বার প্লে করুন', 'CTRL + Q')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    if (lastSelectedRegion.current != null) {
                                                        console.log("Region LOOP play pressed !");

                                                        if (audioContextRef && audioContextRef.current.state == "suspended") {
                                                            await audioContextRef.current.resume();
                                                        }

                                                        if (!playRegionLoop.current && !(wavesurfer.current.isPlaying())) {
                                                            if (wavesurfer.current.getCurrentTime() >= lastSelectedRegion.current.start && wavesurfer.current.getCurrentTime() < lastSelectedRegion.current.end) {
                                                                playUniversal.current = false;
                                                                playRegion.current = false;
                                                                playRegionLoop.current = true;

                                                                setPlaying(false);
                                                                setPlayingRegion(false);
                                                                setPlayingLoop(true);

                                                                try {
                                                                    if (!(wavesurfer.current.isPlaying())) {
                                                                        wavesurfer.current.play();
                                                                    }
                                                                } catch (err: any) {
                                                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                                                }
                                                            } else {
                                                                playUniversal.current = false;
                                                                playRegion.current = false;
                                                                playRegionLoop.current = true;

                                                                setPlaying(false);
                                                                setPlayingRegion(false);
                                                                setPlayingLoop(true);

                                                                wavesurfer.current.seekTo(lastSelectedRegion.current.start / wavesurfer.current.getDuration())
                                                                try {
                                                                    if (!(wavesurfer.current.isPlaying())) {
                                                                        wavesurfer.current.play();
                                                                    }
                                                                } catch (err: any) {
                                                                    console.log(`PLAY INTERRUPTED ! ${err.message}`);
                                                                }
                                                            }
                                                        }
                                                        // else if (playRegionLoop.current && !wavesurfer.current.isPlaying()) {

                                                        // }
                                                        else if (!playRegionLoop.current && wavesurfer.current.isPlaying()) {
                                                            playUniversal.current = false;
                                                            playRegion.current = false;
                                                            playRegionLoop.current = true;

                                                            setPlaying(false);
                                                            setPlayingRegion(false);
                                                            setPlayingLoop(true);
                                                        } else if (playRegionLoop.current) {
                                                            try {
                                                                if (wavesurfer.current.isPlaying()) {
                                                                    wavesurfer.current.pause();
                                                                }
                                                            } catch (err: any) {
                                                                console.log(`PAUSE INTERRUPTED PLAY ! ${err.message}`);
                                                            }

                                                            playUniversal.current = false;
                                                            playRegion.current = false;
                                                            playRegionLoop.current = false;

                                                            setPlaying(false);
                                                            setPlayingRegion(false);
                                                            setPlayingLoop(false);
                                                        }
                                                    }
                                                }}>
                                                    {playingLoop ? <PauseCircleOutlineIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} /> : <PlayCircleOutlineIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />}
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('traverse to previous region', 'বামের রিজিওনটি সিলেক্ট করুন', 'CTRL + \u2190')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    await focusOnCurrentRegion((await getPreviousRegion()).previousRegion, true, true);
                                                }}>
                                                    <WestIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('traverse to next region', 'ডানের রিজিওনটি সিলেক্ট করুন', 'CTRL + \u2192')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    await focusOnCurrentRegion((await getNextRegion()).nextRegion, true, true);
                                                }}>
                                                    <EastIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('delete selected region', 'সিলেক্টেড রিজিওনটি ডিলিট করুন', 'CTRL + DEL')}
                                            >
                                                <span>
                                                    <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                        if (lastSelectedRegion.current == null) {
                                                            ToastFailedMsg("কোন Region Select করেন নাই !")
                                                        } else {
                                                            // handleShowDelete()
                                                            await deleteRegionProperly()
                                                        }
                                                    }}
                                                        disabled={previousAnnotationRef.current && previousAnnotationRef.current.annotation_status != SENTENCE_LEVEL}
                                                    >
                                                        <DeleteIcon fontSize='large' color='error' sx={{ m: 0, p: 0 }} />
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            {/* <Modal show={showDeleteModal} onHide={handleCloseDelete}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>মুছে ফেলা নিশ্চিত করুন !</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>আপনি কি নিশ্চিতভাবে Region টি মুছে ফেলতে চান?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={handleCloseDelete}>
                                                    ফিরে যান
                                                </Button>
                                                <Button variant="danger" onClick={async (event) => {
                                                    await deleteRegionProperly()
                                                    handleCloseDelete();
                                                }}>
                                                    মুছে ফেলুন
                                                </Button>
                                            </Modal.Footer>
                                        </Modal> */}
                                        </Box>

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('merge left', 'বামের রিজিওনের সাথে মার্জ করুন', 'CTRL + B + \u2190')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    if (previousAnnotationRef.current.annotation_status == SENTENCE_LEVEL) {
                                                        console.log("MERGE LEFT Button pressed");
                                                        await merge(null, -1);
                                                    }
                                                }}>
                                                    <JoinLeftIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('merge right', 'ডানের রিজিওনের সাথে মার্জ করুন', 'CTRL + B + \u2192')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    if (previousAnnotationRef.current.annotation_status == SENTENCE_LEVEL) {
                                                        console.log("MERGE RIGHT Button pressed");
                                                        await merge(null, 1);
                                                    }
                                                }}>
                                                    <JoinRightIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('split', 'সিলেক্টেড রিজিওনকে প্লে হেডের স্থানে দুই ভাগ করুন', 'CTRL + SHIFT + CLICK')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async (event) => {
                                                    if (previousAnnotationRef.current.annotation_status <= WORD_LEVEL) {
                                                        console.log("Split Button Pressed !!!");
                                                        if (lastSelectedRegion.current != null) {
                                                            let targetRegion = lastSelectedRegion.current;
                                                            let duplicateEnd = targetRegion.end;
                                                            targetRegion.onResize(wavesurfer.current.getCurrentTime() - targetRegion.end);
                                                            await focusOnCurrentRegion(await duplicateRegion(targetRegion, duplicateEnd), false, false);
                                                        }
                                                    }
                                                }}>
                                                    <VerticalSplitOutlinedIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('copy your user id', 'আপনার USER ID কপি করুন', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    await navigator.clipboard.writeText(userInfo.current.userId);
                                                    ToastSuccessMsg("Copied Annotator Id !", 1500)
                                                }}>
                                                    <VerifiedUserIcon fontSize='large' color='info' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('copy speech id', 'SPEECH ID কপি করুন', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                    await navigator.clipboard.writeText(localStorage.getItem("speech_id"));
                                                    ToastSuccessMsg("Copied Speech Id !", 1500)
                                                }}>
                                                    <FingerprintIcon fontSize='large' color='info' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('submit', 'আপনার কাজটি জমা দিন', 'CTRL + SHIFT + X')}
                                            >
                                                <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={event => saveOrSubmit(true)}>
                                                    <UploadIcon fontSize='large' color='success' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('save', 'আপনার কাজটি সংরক্ষণ করুন', 'CTRL + S')}
                                            >
                                                <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={event => saveOrSubmit(false)}>
                                                    <SaveIcon fontSize='large' color='success' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('work history', 'আপনার করা পূর্বের কাজগুলোর ইতিহাস দেখুন', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                }}>
                                                    <HistoryIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={
                                                    <Stack sx={{ m: 0, p: 0, width: '100%' }}>
                                                        <Box sx={{ px: 1, py: 0.5, width: '100%', backgroundColor: 'rgba(32, 32, 32, 1)', textAlign: 'center', fontFamily: 'Roboto, sans-serif', fontSize: '150%', fontWeight: 'bolder' }}>TIMER</Box>
                                                        <Divider light={true} orientation='horizontal' sx={{ height: '0.5px', backgroundColor: 'rgba(62, 39, 35, 1)' }} />
                                                        <Box sx={{ px: 1, py: 0.5, width: '100%', backgroundColor: 'rgba(26, 0, 13, 1)', textAlign: 'center', fontFamily: 'Noto Serif Bengali, serif', fontSize: '175%', fontWeight: 'bolder' }}>{formatHourMinSec(timePassed)}</Box>
                                                        <Divider light={true} orientation='horizontal' sx={{ height: '0.5px', backgroundColor: 'rgba(62, 39, 35, 1)' }} />
                                                    </Stack>
                                                }
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={async () => {
                                                }}>
                                                    <AccessTimeIcon fontSize='large' color='warning' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('report problem', 'সমস্যা রিপোর্ট করুন', '')}
                                            >
                                                <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={event => { }}>
                                                    <LocalFireDepartmentIcon fontSize='large' color='error' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('mark audio as invalid', 'সম্পূর্ণ অডিওকে অগ্রহণযোগ্য হিসেবে চিহ্নিত করুন', '')}
                                            ><span>
                                                    <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} disabled={previousAnnotationRef.current && previousAnnotationRef.current.annotation_status != SENTENCE_LEVEL}
                                                        onClick={(event: any) => {
                                                            openInvalidDialogRef.current = true;
                                                            setOpenInvalidDialog(true);
                                                        }}
                                                    >
                                                        <BlockIcon fontSize='large' color='error' sx={{ m: 0, p: 0 }} />
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <Dialog
                                                open={openInvalidDialog}
                                                TransitionComponent={Transition}
                                                keepMounted
                                                onClose={() => {
                                                    openInvalidDialogRef.current = false;
                                                    setOpenInvalidDialog(false);
                                                }}
                                                aria-describedby="alert-dialog-slide-description"
                                            >
                                                <DialogTitle>{"আপনি কি নিশ্চিত?"}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        আপনি কি আসলেই সম্পূর্ণ স্পীচকে <b>অগ্রহণযোগ্য</b> হিসেবে চিহ্নিত করতে চান?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button variant='contained' color='success' onClick={() => {
                                                        openInvalidDialogRef.current = false;
                                                        setOpenInvalidDialog(false);
                                                    }}>ফিরে যান</Button>
                                                    <Button variant='contained' color='error' onClick={async () => {
                                                        console.log("INVALID !!!");
                                                        const audioAnnotation: any = {
                                                            id: randomString(),
                                                            start: 0,
                                                            end: wavesurfer.current.getDuration(),
                                                            text: { "verdict": "" },
                                                            phoneme: {},
                                                            tagList: ['NA'],
                                                            speakerNo: "1"
                                                        }
                                                        console.log(audioAnnotation);
                                                        await uploadAnnotation([audioAnnotation], true);
                                                        openInvalidDialogRef.current = false;
                                                        setOpenInvalidDialog(false);
                                                    }}>সত্যিই অগ্রহণযোগ্য</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('instructions', 'নির্দেশনাবলী', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={event => { }}>
                                                    <InfoIcon fontSize='large' color='info' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('shortcuts', 'শর্টকাট কমান্ডসমূহ', '')}
                                            >
                                                <Button variant='contained' color='success' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={event => { handleShowShortcuts() }}>
                                                    <MenuBookIcon fontSize='large' color='info' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                            <Modal show={showShortcutsModal} onHide={handleCloseShortcuts} size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>SHORTCUT LIST !</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body style={{ fontSize: "75%" }}>
                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>PLAY SAVE SUBMIT</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combination</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Space</span></th>
                                                                        <td>Region Play from Playhead</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>Space</span></th>
                                                                        <td>Region Play from Start</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Q</span></th>
                                                                        <td>Region Play Loop</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>U</span></th>
                                                                        <td>Uniersal Play</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>S</span></th>
                                                                        <td>Save</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>X</span></th>
                                                                        <td>Submit</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>REGION TRAVERSAL</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combination</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&lArr;</span></th>
                                                                        <td>Traverse Region Backward</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&rArr;</span></th>
                                                                        <td>Traverse Region Forward</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&lArr;</span></th>
                                                                        <td>Focus Region Backward</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&rArr;</span></th>
                                                                        <td>Focus Region Forward</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>REGION RESIZE</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combination</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&lt;</span></th>
                                                                        <td>Region এর বাম প্রান্তকে বামে সরান</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&gt;</span></th>
                                                                        <td>Region এর বাম প্রান্তকে ডানে সরান</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&lt;</span></th>
                                                                        <td>Region এর ডান প্রান্তকে বামে সরান</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&gt;</span></th>
                                                                        <td>Region এর ডান প্রান্তকে ডানে সরান</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>PLAYBACK</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combination</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&#91;</span></th>
                                                                        <td>Decrease Playback Speed</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&#93;</span></th>
                                                                        <td>Increase Playback Speed</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>|</span></th>
                                                                        <td>Reset Playback Speed</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>4</span></th>
                                                                        <td>Skip Backward 2.5 sec</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>6</span></th>
                                                                        <td>Skip Forward 2.5 sec</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Alt</span>&nbsp;&nbsp;<span className='key'>N</span></th>
                                                                        <td>Toggle Normalize</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Alt</span>&nbsp;&nbsp;<span className='key'>/</span></th>
                                                                        <td>Toggle Mute</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>REGION MERGE SPLIT DELETE</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combination</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>B</span>&nbsp;&nbsp;<span className='key'>&lArr;</span></th>
                                                                        <td>Merge Previous</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>B</span>&nbsp;&nbsp;<span className='key'>&rArr;</span></th>
                                                                        <td>Merge Next</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>Click</span></th>
                                                                        <td>Split Region</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th scope="row"><span className='key'>ENTER</span></th>
                                                                        <td>Select / Focus</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>ESC</span></th>
                                                                        <td>DeSelect / DeFocus</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Del</span></th>
                                                                        <td className='bg-danger text-white'>Delete Region</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>REGION DRAG</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combo</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&lt;</span></th>
                                                                        <td>সম্পূর্ণ Region কে বামে সরান</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&gt;</span></th>
                                                                        <td>সম্পূর্ণ Region কে ডানে সরান</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>D</span></th>
                                                                        <td>Toggle Mouse Drag</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>A</span></th>
                                                                        <td>Playhead এর বামের সব Region কে বামে</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>S</span></th>
                                                                        <td>Playhead এর বামের সব Region কে ডানে</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>D</span></th>
                                                                        <td>Playhead এর ডানের সব Region কে বামে</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>F</span></th>
                                                                        <td>Playhead এর ডানের সব Region কে ডানে</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <h6 className='text-center'><strong>WAVE CANVAS HEIGHT ADJUSTMENT</strong></h6>
                                                            <table className="table table-sm table-striped table-bordered">
                                                                <thead className='text-center thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">Key Combination</th>
                                                                        <th scope="col">Effect</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&uArr;</span></th>
                                                                        <td>Increase Wave Height</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>&dArr;</span></th>
                                                                        <td>Decrease Wave Height</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&uArr;</span></th>
                                                                        <td>Decrease Canvas Height</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>&dArr;</span></th>
                                                                        <td>Increase Canvas Height</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><span className='key'>Ctrl</span>&nbsp;&nbsp;<span className='key'>Shift</span>&nbsp;&nbsp;<span className='key'>|</span></th>
                                                                        <td>Reset Canvas Height</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button color="primary" onClick={handleCloseShortcuts}>
                                                        CLOSE
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </Box>

                                        <Divider flexItem={true} light={true} orientation='vertical' sx={{ width: '2px' }} />

                                        <Box sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', border: '0px solid red' }}>
                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('logout', 'লগআউট করুন', '')}
                                            >
                                                <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={
                                                    handleShowLogout
                                                    // (event) => onLogout(event)
                                                }>
                                                    <LogoutIcon fontSize='large' color='error' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                            </Tooltip>
                                            <Modal show={showLogoutModal} onHide={handleCloseLogout}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>লগ আউট নিশ্চিত করুন !</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>আপনি কি নিশ্চিতভাবে লগ আউট করতে চান?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button color="primary" onClick={handleCloseLogout}>
                                                        ফিরে যান
                                                    </Button>
                                                    <Button color="error" onClick={async (event) => {
                                                        // setUnloadEventListener(false);
                                                        handleCloseLogout();
                                                        await onLogout();
                                                    }}>
                                                        লগ আউট
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            <Divider flexItem={true} light={true} orientation='horizontal' sx={{ height: '2px' }} />

                                            <Tooltip disableInteractive
                                                //   open={open}
                                                placement="bottom"
                                                arrow={true}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            // color: "purple",
                                                            backgroundColor: "rgba(53, 53, 53, 1)",
                                                            minWidth: 250,
                                                            maxWidth: 500,
                                                            // maxHeight: 300,
                                                            overflowX: 'auto',
                                                            overflowY: 'auto',
                                                            m: 0,
                                                            p: 0
                                                            // fontSize: "2em"
                                                        }
                                                    }
                                                }}

                                                //   {...rest}
                                                title={buttonTooltip('cancel', 'এই কাজটি বাতিল করুন', '')}
                                            >
                                                {/* <a href='/'> */}
                                                <Button variant='contained' color='info' size='small' sx={{ p: 0, m: 0, backgroundColor: '#0E0918' }} onClick={
                                                    handleShowCancel
                                                    // async () => {
                                                    //     await onCancelClick();
                                                    //     // return true;
                                                    // }
                                                }>
                                                    <CloseIcon fontSize='large' color='error' sx={{ m: 0, p: 0 }} />
                                                </Button>
                                                {/* </a> */}
                                            </Tooltip>
                                            <Modal show={showCancelModal} onHide={handleCloseCancel}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>বাতিল করা নিশ্চিত করুন !</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>আপনি কি নিশ্চিতভাবে কাজটি বাতিল করতে চান?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button color="primary" onClick={handleCloseCancel}>
                                                        ফিরে যান
                                                    </Button>
                                                    <Button color="error" onClick={async (event) => {
                                                        // setUnloadEventListener(false);
                                                        await onCancelClick();
                                                        handleCloseCancel();
                                                    }}>
                                                        বাতিল করুন
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <div className='row p-0 m-0'>
                                    <div className='col-12 p-0 m-0'>
                                        <div ref={waveTimelineRef} style={{
                                            backgroundColor: "MistyRose",
                                            // display: previousAnnotation.module == 'tts' ? "visible" : 'none'
                                        }}> </div>
                                        {/* <div className='text-center' style={{ border: "0px solid red", marginTop: "35vh", display: `${loading ? "block" : "none"}` }}>
                                    <ScaleLoader
                                        color="#471620"
                                        loading={loading}
                                        height={70}
                                        // cssOverride={override}
                                        // size={25}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div> */}

                                        <div className='text-center' style={{ display: `${loading ? "block" : "none"}`, marginTop: "35vh" }}>
                                            <ProgressBar striped variant={progress < 25 ? 'danger' : progress < 50 ? 'info' : progress < 75 ? 'primary' : 'success'} now={progress} label={`${progress}%`} />
                                        </div>
                                        <div ref={waveformRef} style={{ backgroundColor: backgroundColor, overflowY: "auto" }}>
                                            <div id="wave-spectrogram" style={{ display: showSpectrogram ? 'block' : 'none' }} ref={waveSpectrogramRef}> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : error != '' ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}><Alert severity="error">
                    <AlertTitle>ERROR</AlertTitle>
                    This is an Error Alert — <strong>{error}</strong>
                    <br /><br />
                    <Button color='error' size='small' onClick={async (event: any) => {
                        await onCancelClick();
                    }}>
                        CANCEL
                    </Button>
                </Alert>
                </div> :
                    (<div style={{
                        border: "2px solid red",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <p className='bangla' style={{ fontSize: "24px", fontWeight: "bold" }}>আপনার অ্যানোটেশনটি লোড করা হচ্ছে ...</p><br />
                        {/* <LongRunningLoading isLoading={loading} /> */}
                        <RingLoader
                            color="#471620"
                            loading={loading}
                            // cssOverride={override}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /><br />
                        <p className='bangla' style={{ fontSize: "24px", fontWeight: "bold" }}>অনুগ্রহ করে অপেক্ষা করুন ...</p>
                        <br />
                        <p className='bangla' style={{ fontSize: "24px", fontWeight: "bold", color: "red" }}>এখন Refresh দিবেন <strong>না</strong> ...</p>
                    </div>)
            }
        </div>
    )
}