import React, { FC, ReactNode, useEffect, useState, useRef, useMemo, useContext, createContext, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get, isLoggedIn, post, postToPython, setToken, decodeToken, unsetToken } from '../../common/http';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

import MaterialReactTable, { MRT_ColumnDef, MRT_Cell, MRT_Column, MRT_Row } from 'material-react-table';

import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { styled, lighten, darken } from '@mui/system';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';

import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CommentIcon from '@mui/icons-material/Comment';
import MicIcon from '@mui/icons-material/Mic';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';

import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg, ToastInfoMsg } from "../../common/toast";
import { red } from '@mui/material/colors';

const ColorModeContext = createContext({ toggleColorMode: () => { } });
const pages = ['Roles', 'Billing', 'Logout'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type TaskHistoryAnnotator = {
  speech_id: string;
  duration: number;
  tps: number;
  timeTaken: number;
  annotatedDuration: number;

  validated: string;
  validatedDuration: number;
  overlappedDuration: number;

  verified: string;
  verifiedDuration: number;
  deductedDuration: number;
  acceptedDuration: number;

  submittedOn: string;
  billingStatus: string;
  level: string;
};

type TaskHistoryValidator = {
  speech_id: string;
  duration: number;
  tps: number;
  timeTaken: number;
  validatedDuration: number;
  overlappedDuration: number;

  verified: string;
  verifiedDuration: number;
  deductedDuration: number;
  acceptedDuration: number;

  submittedOn: string;
  billingStatus: string;
  level: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const AdminMonitor: FC = () => {
  const levels = ['SENTENCE', 'WORD', 'TRANSCRIPTION', 'PHONEME']
  const distributions = ['READ', 'CONVERSATION_MONO', 'CONVERSATION_DI', 'CONVERSATION_MULTI', 'LECTURE', 'SPONTANEOUS', 'MISCELLANEOUS']
  const distribution_sources = ['CREATE', 'COLLECT']
  const billing_status_list = ["UNPAID", "PROCESSING", "PAID"]

  const [tab, setTab] = React.useState<string | null>('person');

  const handleToggleTab = (
    event: React.MouseEvent<HTMLElement>,
    newTab: string | null,
  ) => {
    setTab(newTab);
  };

  const [module, setModule] = React.useState<string | null>(localStorage.getItem("moduleMonitorAdmin") ? localStorage.getItem("moduleMonitorAdmin") : 'stt');

  const handleToggleModule = (
    event: React.MouseEvent<HTMLElement>,
    newModule: string | null,
  ) => {
    setModule(newModule);
  };

  const [userType, setUserType] = React.useState<string | null>(localStorage.getItem("userTypeMonitorAdmin") ? localStorage.getItem("userTypeMonitorAdmin") : 'annotator');

  const handleToggleUserType = (
    event: React.MouseEvent<HTMLElement>,
    newUserType: string | null,
  ) => {
    setUserType(newUserType);
  };

  const [dataHistoryAnnotator, setDataHistoryAnnotator] = useState<TaskHistoryAnnotator[]>([]);
  const [dataHistoryValidator, setDataHistoryValidator] = useState<TaskHistoryValidator[]>([]);

  const sumAnnotatedDuration = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.annotatedDuration, 0),
    [dataHistoryAnnotator],
  );

  const sumValidatedDurationAnt = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.validatedDuration, 0),
    [dataHistoryAnnotator],
  );

  const sumValidatedDuration = useMemo(
    () => dataHistoryValidator.reduce((acc, curr) => acc + curr.validatedDuration, 0),
    [dataHistoryValidator],
  );

  const sumOverlappedDurationAnt = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.overlappedDuration, 0),
    [dataHistoryAnnotator],
  );

  const sumOverlappedDurationVal = useMemo(
    () => dataHistoryValidator.reduce((acc, curr) => acc + curr.overlappedDuration, 0),
    [dataHistoryValidator],
  );

  const sumVerifiedDurationAnt = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.verifiedDuration, 0),
    [dataHistoryAnnotator],
  );

  const sumVerifiedDurationVal = useMemo(
    () => dataHistoryValidator.reduce((acc, curr) => acc + curr.verifiedDuration, 0),
    [dataHistoryValidator],
  );

  const sumDeductedDurationAnt = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.deductedDuration, 0),
    [dataHistoryAnnotator],
  );

  const sumDeductedDurationVal = useMemo(
    () => dataHistoryValidator.reduce((acc, curr) => acc + curr.deductedDuration, 0),
    [dataHistoryValidator],
  );

  const sumAcceptedDurationAnt = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.acceptedDuration, 0),
    [dataHistoryAnnotator],
  );

  const sumAcceptedDurationVal = useMemo(
    () => dataHistoryValidator.reduce((acc, curr) => acc + curr.acceptedDuration, 0),
    [dataHistoryValidator],
  );

  const mtpsAnt = useMemo(
    () => {
      if (dataHistoryAnnotator.length == 0) { return 0; }
      const mid = Math.floor(dataHistoryAnnotator.length / 2),
        nums = [...dataHistoryAnnotator].sort((a, b) => a.tps - b.tps);

      let res = dataHistoryAnnotator.length % 2 !== 0 ? nums[mid].tps : (nums[mid - 1].tps + nums[mid].tps) / 2;
      return parseFloat(res.toFixed(3));
    },
    [dataHistoryAnnotator],
  );

  const mtpsVal = useMemo(
    () => {
      if (dataHistoryValidator.length == 0) { return 0; }
      const mid = Math.floor(dataHistoryValidator.length / 2),
        nums = [...dataHistoryValidator].sort((a, b) => a.tps - b.tps);
      let res = dataHistoryValidator.length % 2 !== 0 ? nums[mid].tps : (nums[mid - 1].tps + nums[mid].tps) / 2;
      return parseFloat(res.toFixed(3));
    },
    [dataHistoryValidator],
  );

  const sumDurationAnt = useMemo(
    () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.duration, 0),
    [dataHistoryAnnotator],
  );

  const sumDurationVal = useMemo(
    () => dataHistoryValidator.reduce((acc, curr) => acc + curr.duration, 0),
    [dataHistoryValidator],
  );

  const userInfo: any = useRef({});
  const [userRole, setUserRole] = useState("");
  const [targetAnnotatorId, setTargetAnnotatorId] = useState(localStorage.getItem("targetAnnotatorId") ? localStorage.getItem("targetAnnotatorId") : "");
  const verificationStatsDict: any = useRef({});

  const [loading, setLoading]: any = useState(true);

  const MIN_DATE = new Date('2023-01-01T00:00:00.000000');
  const MAX_DATE = new Date();

  const [showCurrentTime, setShowCurrentTime] = useState(localStorage.getItem('showCurrentTimeMonitorAdmin') ? JSON.parse(localStorage.getItem('showCurrentTimeMonitorAdmin')) : true);
  const [startDateTime, setStartDateTime]: any = React.useState<Dayjs | null>(localStorage.getItem('startDateTimeMonitorAdmin') ? dayjs(localStorage.getItem('startDateTimeMonitorAdmin')) : dayjs(MIN_DATE));
  const [endDateTime, setEndDateTime]: any = React.useState<Dayjs | null>((!showCurrentTime) && localStorage.getItem('endDateTimeMonitorAdmin') ? dayjs(localStorage.getItem('endDateTimeMonitorAdmin')) : dayjs(MAX_DATE));

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnVisibilityMonitorAdmin, setColumnVisibilityMonitorAdmin] = useState<VisibilityState>(localStorage.getItem('columnVisibilityMonitorAdmin') ? JSON.parse(localStorage.getItem('columnVisibilityMonitorAdmin')) : {});
  const [columnFiltersMonitorAdmin, setColumnFiltersMonitorAdmin] = useState<ColumnFiltersState>(localStorage.getItem('columnFiltersMonitorAdmin') ? JSON.parse(localStorage.getItem('columnFiltersMonitorAdmin')) : []);
  const [globalFilterMonitorAdmin, setGlobalFilterMonitorAdmin] = useState(localStorage.getItem('globalFilterMonitorAdmin') ? JSON.parse(localStorage.getItem('globalFilterMonitorAdmin')) : undefined);
  const [sortingMonitorAdmin, setSortingMonitorAdmin] = useState<SortingState>(localStorage.getItem('sortingMonitorAdmin') ? JSON.parse(localStorage.getItem('sortingMonitorAdmin')) : []);
  const [paginationMonitorAdmin, setPaginationMonitorAdmin] = useState<PaginationState>(localStorage.getItem('paginationMonitorAdmin') ? JSON.parse(localStorage.getItem('paginationMonitorAdmin')) : {
    pageIndex: 0,
    pageSize: 10,
  });

  const [options, setOptions] = useState([]);

  const columnsHistoryAnnotator = useMemo<MRT_ColumnDef<TaskHistoryAnnotator>[]>(
    () => [
      // {
      //   header: 'Account Status',
      //   accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
      //   id: 'isActive',
      //   filterVariant: 'checkbox',
      //   Cell: ({ cell }) =>
      //     cell.getValue() === 'true' ? 'Active' : 'Inactive',
      //   size: 220,
      // },
      {
        accessorKey: 'speech_id',
        header: 'SPEECH ID',
        // filterVariant: 'text', // default
        // enableClickToCopy: true,
        enableColumnFilter: false,
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopyIcon />,
          sx: { justifyContent: 'flex-start' },
        },
        Cell: ({ cell, row }: any) => (
          <Box sx={{ cursor: 'pointer' }}>
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <Tooltip
              //   open={open}
              placement="bottom-start"
              arrow={true}
              componentsProps={{
                tooltip: {
                  sx: {
                    // color: "purple",
                    backgroundColor: "rgba(53, 53, 53, 1)",
                    minWidth: 250,
                    maxWidth: 500,
                    maxHeight: 300,
                    overflowX: 'auto',
                    overflowY: 'auto'
                    // fontSize: "2em"
                  }
                }
              }}

              //   {...rest}
              title={
                dataHistoryAnnotator && dataHistoryAnnotator.length > 0 && dataHistoryAnnotator.filter((item: any) =>
                  item.speech_id == cell.getValue()
                )[0].verified == "YES" ? (
                  <Stack>
                    <TableContainer component={Paper}>
                      <Table stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>REGION ID</TableCell>
                            <TableCell align="center">DEDUCTED DURATION</TableCell>
                            <TableCell align="center">REASON</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {verificationStatsDict.current[cell.getValue()].slice(1,).map((row: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.segment_id}
                              </TableCell>
                              <TableCell align="left" sx={{ backgroundColor: 'rgba(255, 0, 0, 0.0625)' }}>
                                {row.deducted_duration}
                              </TableCell>
                              <TableCell align="justify">{row.reason}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box>
                      {verificationStatsDict.current[cell.getValue()][0].verdict}
                    </Box>
                  </Stack>
                ) : ''
              }
            >
              <a href='https://google.com' target='_blank'>{cell.getValue().split('|')[0]}</a>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: 'duration',
        header: 'DURATION',
        enableColumnFilter: false,
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),

        // aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // //required to render an aggregated cell
        // AggregatedCell: ({ cell, table }) => (
        //     <>
        //         <FunctionsRoundedIcon />
        //         {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
        //         <Box
        //             sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
        //         >
        //             {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
        //             {formatHourMinSec(cell.getValue<number>())}
        //         </Box>
        //     </>
        // ),
        Footer: () => (
          <Stack>
            TOTAL DURATION:
            <Box color="warning.main">{formatHourMinSec(sumDurationAnt)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'annotatedDuration',
        header: "ANNOTATED DURATION (s)",
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),

        // aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // //required to render an aggregated cell
        // AggregatedCell: ({ cell, table }) => (
        //     <>
        //         <FunctionsRoundedIcon />
        //         {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
        //         <Box
        //             sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
        //         >
        //             {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
        //             {formatHourMinSec(cell.getValue<number>())}
        //         </Box>
        //     </>
        // ),
        Footer: () => (
          <Stack>
            TOTAL ANNOTATED:
            <Box color="warning.main">{formatHourMinSec(sumAnnotatedDuration)}</Box>
          </Stack>
        ),
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        // Cell: ({ cell, row }: any) => (
        //     <Box
        //         sx={{
        //             display: 'flex',
        //             alignItems: 'center',
        //             // justifyContent: 'center',
        //             gap: '1rem',
        //         }}
        //     >

        //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        //         <span>{`${("0" + Math.floor(cell.getValue() / 60)).slice(-2)} min ${("0" + Math.round(cell.getValue() % 60)).slice(-2)} sec`}</span>
        //     </Box>
        // ),
      },
      {
        accessorKey: 'timeTaken',
        header: 'Time Taken',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),

        // aggregationFn: 'median', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // //required to render an aggregated cell
        // AggregatedCell: ({ cell, table }) => (
        //     <>
        //         <FunctionsRoundedIcon />
        //         {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
        //         <Box
        //             sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
        //         >
        //             {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
        //             {formatHourMinSec(cell.getValue<number>())}
        //         </Box>
        //     </>
        // ),
        // Footer: () => (
        //   <Stack>
        //     MEDIAN TIME PER SEC:
        //     <Box color="warning.main">{formatHourMinSec(mtpsAnt)}</Box>
        //   </Stack>
        // ),
      },
      {
        accessorKey: 'tps',
        header: 'TPS',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{cell.getValue()}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            MTPS:
            <Box color="warning.main">{mtpsAnt}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'validated',
        header: 'VALIDATED',
        // filterVariant: 'select',
        // filterSelectOptions: ["YES", "NO"],
        enableColumnFilter: false,
        Footer: () => (
          <Stack>
            TOTAL VALIDATED:
            <Box color="warning.main">{formatHourMinSec(sumValidatedDurationAnt)}</Box>
          </Stack>
        ),
      },
      // {
      //     accessorKey: 'validatedDuration',
      //     header: 'VALIDATED DURATION (s)',
      //     // filterVariant: 'range',
      //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
      //     // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
      //     enableColumnFilter: false,
      //     // enableHiding: userInfo.current.role == "validator" ? false : true,

      //     Cell: ({ cell, row }: any) => (
      //         <Box
      //             sx={{
      //                 display: 'flex',
      //                 alignItems: 'center',
      //                 // justifyContent: 'center',
      //                 gap: '1rem',
      //             }}
      //         >

      //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
      //             <span>{formatHourMinSec(cell.getValue())}</span>
      //         </Box>
      //     ),

      //     aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
      //     //required to render an aggregated cell
      //     AggregatedCell: ({ cell, table }) => (
      //         <>
      //             <FunctionsRoundedIcon />
      //             {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
      //             <Box
      //                 sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
      //             >
      //                 {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
      //                 {formatHourMinSec(cell.getValue<number>())}
      //             </Box>
      //         </>
      //     ),
      //     Footer: () => (
      //         <Stack>
      //             TOTAL VALIDATED:
      //             <Box color="warning.main">{formatHourMinSec(sumValidatedDuration)}</Box>
      //         </Stack>
      //     ),
      // },
      {
        accessorKey: 'overlappedDuration',
        header: 'OVERLAPPED DURATION (s)',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableHiding: userInfo.current.role == "validator" ? false : true,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL OVERLAPPED:
            <Box color="warning.main">{formatHourMinSec(sumOverlappedDurationAnt)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'verified',
        header: 'VERIFIED',
        // filterVariant: 'select',
        // filterSelectOptions: ["YES", "NO"],
        enableColumnFilter: false,
        Footer: () => (
          <Stack>
            TOTAL VERIFIED:
            <Box color="warning.main">{formatHourMinSec(sumVerifiedDurationAnt)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'deductedDuration',
        header: 'DEDUCTED DURATION (s)',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableHiding: userInfo.current.role == "validator" ? false : true,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL DEDUCTED:
            <Box color="error.main">{formatHourMinSec(sumDeductedDurationAnt)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'acceptedDuration',
        header: 'ACCEPTED DURATION (s)',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL ACCEPTED:
            <Box color="success.main">{formatHourMinSec(sumAcceptedDurationAnt)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'billingStatus',
        header: 'BILLING STATUS',
        // filterVariant: 'multi-select',
        // filterSelectOptions: billing_status_list,
        enableColumnFilter: false,

        // muiTableHeadCellProps: {
        //   sx: { textAlign: 'center' }
        // },
        muiTableBodyCellProps: {
          sx: {
            // display: 'flex',
            // alignItems: 'center',
            textAlign: 'center',
            // gap: '1rem',
            backgroundColor: 'rgba(255, 0, 0, 0.1)'
          }
        },
        // Header: ({ column }) => <em>{column.columnDef.header}</em>,
        // Cell: ({ cell, row }: any) => (
        //     <Box
        //         sx={{
        //             // display: 'flex',
        //             // alignItems: 'center',
        //             textAlign: 'center',
        //             // gap: '1rem',
        //             backgroundColor: cell.getValue() == 'UNPAID' ? 'rgba(255, 0, 0, 0.1)' : cell.getValue() == 'PROCESSING' ? 'rgba(0, 0, 255, 0.1)' : 'rgba(0, 255, 0, 0.1)'
        //         }}
        //     >
        //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        //         {cell.getValue()}
        //     </Box>
        // ),
      },
      {
        // accessorFn: (row) => new Date(row.submittedOn), //convert to Date for sorting and filtering
        accessorKey: 'submittedOn',
        header: 'SUBMITTED ON',

        // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        // Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()

        // sortingFn: 'datetime',
        enableColumnFilter: false,
        // filterFn: 'lessThanOrEqualTo',

        //Custom Date Picker Filter from @mui/x-date-pickers
        // Filter: ({ column }) => (
        //     <LocalizationProvider dateAdapter={AdapterDayjs}>
        //         <DatePicker
        //             onChange={(newValue) => {
        //                 column.setFilterValue(newValue);
        //             }}
        //             renderInput={(params) => (
        //                 <TextField
        //                     {...params}
        //                     helperText={'Filter Mode: Less Than'}
        //                     sx={{ minWidth: '120px' }}
        //                     variant="standard"
        //                 />
        //             )}
        //             value={column.getFilterValue()}
        //         />
        //     </LocalizationProvider>
        // ),
      },
      {
        accessorKey: 'level',
        header: 'LEVEL',
        filterVariant: 'multi-select',
        filterSelectOptions: levels,
        // muiTableHeadCellProps: {
        //   sx: { textAlign: 'center' }
        // },
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        // Header: ({ column }) => <em>{column.columnDef.header}</em>,
        // Cell: ({ cell, row }: any) => (
        //     <Box
        //         sx={{
        //             display: 'flex',
        //             alignItems: 'center',
        //             gap: '1rem',
        //         }}
        //     >
        //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        //         <span>{cell.getValue()}</span>
        //     </Box>
        // ),
      },
    ],
    [sumAnnotatedDuration, sumValidatedDurationAnt, sumOverlappedDurationAnt, sumVerifiedDurationAnt, sumDeductedDurationAnt, sumAcceptedDurationAnt, mtpsAnt, sumDurationAnt],
  );

  const columnsHistoryValidator = useMemo<MRT_ColumnDef<TaskHistoryValidator>[]>(
    () => [
      // {
      //   header: 'Account Status',
      //   accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
      //   id: 'isActive',
      //   filterVariant: 'checkbox',
      //   Cell: ({ cell }) =>
      //     cell.getValue() === 'true' ? 'Active' : 'Inactive',
      //   size: 220,
      // },
      {
        accessorKey: 'speech_id',
        header: 'SPEECH ID',
        // filterVariant: 'text', // default
        // enableClickToCopy: true,
        enableColumnFilter: false,
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopyIcon />,
          sx: { justifyContent: 'flex-start' },
        },
        Cell: ({ cell, row }: any) => (
          <Box sx={{ cursor: 'pointer' }}>
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <Tooltip
              //   open={open}
              placement="bottom-start"
              arrow={true}
              componentsProps={{
                tooltip: {
                  sx: {
                    // color: "purple",
                    backgroundColor: "rgba(53, 53, 53, 1)",
                    minWidth: 250,
                    maxWidth: 500,
                    maxHeight: 300,
                    overflowX: 'auto',
                    overflowY: 'auto'
                    // fontSize: "2em"
                  }
                }
              }}

              //   {...rest}
              title={
                dataHistoryValidator && dataHistoryValidator.length > 0 && dataHistoryValidator.filter((item: any) =>
                  item.speech_id == cell.getValue()
                )[0].verified == "YES" ? (
                  <Stack>
                    <TableContainer component={Paper}>
                      <Table stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>REGION ID</TableCell>
                            <TableCell align="center">DEDUCTED DURATION</TableCell>
                            <TableCell align="center">REASON</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {verificationStatsDict.current[cell.getValue()].slice(1,).map((row: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.segment_id}
                              </TableCell>
                              <TableCell align="left" sx={{ backgroundColor: 'rgba(255, 0, 0, 0.0625)' }}>
                                {row.deducted_duration}
                              </TableCell>
                              <TableCell align="justify">{row.reason}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box>
                      {verificationStatsDict.current[cell.getValue()][0].verdict}
                    </Box>
                  </Stack>
                ) : ''
              }
            >
              <a href='https://google.com' target='_blank'>{cell.getValue().split('|')[0]}</a>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: 'duration',
        header: 'DURATION',
        enableColumnFilter: false,
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),

        // aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // //required to render an aggregated cell
        // AggregatedCell: ({ cell, table }) => (
        //     <>
        //         <FunctionsRoundedIcon />
        //         {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
        //         <Box
        //             sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
        //         >
        //             {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
        //             {formatHourMinSec(cell.getValue<number>())}
        //         </Box>
        //     </>
        // ),
        Footer: () => (
          <Stack>
            TOTAL DURATION:
            <Box color="warning.main">{formatHourMinSec(sumDurationVal)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'validatedDuration',
        header: "VALIDATED DURATION (s)",
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL VALIDATED:
            <Box color="warning.main">{formatHourMinSec(sumValidatedDuration)}</Box>
          </Stack>
        )
      },
      {
        accessorKey: 'timeTaken',
        header: 'Time Taken',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        // Footer: () => (
        //   <Stack>
        //     MEDIAN TIME TAKEN:
        //     <Box color="warning.main">{formatHourMinSec(mtpsVal)}</Box>
        //   </Stack>
        // ),
      },
      {
        accessorKey: 'tps',
        header: 'TPS',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{cell.getValue()}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            MTPS:
            <Box color="warning.main">{mtpsVal}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'overlappedDuration',
        header: 'OVERLAPPED DURATION (s)',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableHiding: userInfo.current.role == "validator" ? false : true,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL OVERLAPPED:
            <Box color="warning.main">{formatHourMinSec(sumOverlappedDurationVal)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'verified',
        header: 'VERIFIED',
        // filterVariant: 'select',
        // filterSelectOptions: ["YES", "NO"],
        enableColumnFilter: false,
        Footer: () => (
          <Stack>
            TOTAL VERIFIED:
            <Box color="warning.main">{formatHourMinSec(sumVerifiedDurationVal)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'deductedDuration',
        header: 'DEDUCTED DURATION (s)',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,
        // enableHiding: userInfo.current.role == "validator" ? false : true,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL DEDUCTED:
            <Box color="error.main">{formatHourMinSec(sumDeductedDurationVal)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'acceptedDuration',
        header: 'ACCEPTED DURATION (s)',
        // filterVariant: 'range',
        // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
        enableColumnFilter: false,

        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '1rem',
            }}
          >

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{formatHourMinSec(cell.getValue())}</span>
          </Box>
        ),
        Footer: () => (
          <Stack>
            TOTAL ACCEPTED:
            <Box color="success.main">{formatHourMinSec(sumAcceptedDurationVal)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'billingStatus',
        header: 'BILLING STATUS',
        // filterVariant: 'multi-select',
        // filterSelectOptions: billing_status_list,
        enableColumnFilter: false,

        // muiTableHeadCellProps: {
        //   sx: { textAlign: 'center' }
        // },
        muiTableBodyCellProps: {
          sx: {
            // display: 'flex',
            // alignItems: 'center',
            textAlign: 'center',
            // gap: '1rem',
            backgroundColor: 'rgba(255, 0, 0, 0.1)'
          }
        },
        // Header: ({ column }) => <em>{column.columnDef.header}</em>,
        // Cell: ({ cell, row }: any) => (
        //     <Box
        //         sx={{
        //             // display: 'flex',
        //             // alignItems: 'center',
        //             textAlign: 'center',
        //             // gap: '1rem',
        //             backgroundColor: cell.getValue() == 'UNPAID' ? 'rgba(255, 0, 0, 0.1)' : cell.getValue() == 'PROCESSING' ? 'rgba(0, 0, 255, 0.1)' : 'rgba(0, 255, 0, 0.1)'
        //         }}
        //     >
        //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        //         {cell.getValue()}
        //     </Box>
        // ),
      },
      {
        // accessorFn: (row) => new Date(row.submittedOn), //convert to Date for sorting and filtering
        accessorKey: 'submittedOn',
        header: 'SUBMITTED ON',

        // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        // Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()

        // sortingFn: 'datetime',
        enableColumnFilter: false
      },
      {
        accessorKey: 'level',
        header: 'LEVEL',
        filterVariant: 'multi-select',
        filterSelectOptions: levels,
        // muiTableHeadCellProps: {
        //   sx: { textAlign: 'center' }
        // },
        // muiTableBodyCellProps: {
        //     sx: { textAlign: 'center' }
        // },
        // Header: ({ column }) => <em>{column.columnDef.header}</em>,
        // Cell: ({ cell, row }: any) => (
        //     <Box
        //         sx={{
        //             display: 'flex',
        //             alignItems: 'center',
        //             gap: '1rem',
        //         }}
        //     >
        //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        //         <span>{cell.getValue()}</span>
        //     </Box>
        // ),
      },
    ],
    [sumValidatedDuration, sumOverlappedDurationVal, sumVerifiedDurationVal, sumDeductedDurationVal, sumAcceptedDurationVal, mtpsVal, sumDurationVal],
  );

  const formatHourMinSec = (totalSeconds: number) => {
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
    let seconds = (hours > 0 || minutes > 0) ? Math.round(totalSeconds - hours * 3600 - minutes * 60) : parseFloat((totalSeconds - hours * 3600 - minutes * 60).toFixed(3))
    // return `${("0" + hour).slice(-2)} hr ${("0" + minutes).slice(-2)} min ${("0" + seconds).slice(-2)} sec`
    return `${hours > 0 ? hours + ' hr ' : ''}${minutes > 0 ? minutes + ' min ' : ''}${seconds > 0 ? seconds + ' sec' : ''}`
  }

  const onLogout = () => {
    // setUnloadEventListener(false);

    // setTimeout(() => {
    localStorage.removeItem('speech_id_admin');
    localStorage.removeItem('latestPage');
    localStorage.removeItem('latestSizePerPage');
    unsetToken();
    // history.push('/login')
    // history.go(0)
    window.location.replace(window.location.origin + `/login`)
    // }, 100);
    // return true;
  };

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('darkMode') == 'light' ? 'light' : 'dark');
  const [open, setOpen] = useState(false);
  const firstRender = useRef(true);

  const [allAnnotators, setAllAnnotators]: any = useState([]);
  const [eligibleAnnotators, setEligibleAnnotators]: any = useState([]);
  const [selectedAnnotators, setSelectedAnnotators]: any = useState(localStorage.getItem("selectedUsersMonitorAdmin") ? JSON.parse(localStorage.getItem("selectedUsersMonitorAdmin")) : []);

  useEffect(() => {
    if (!firstRender.current) {
      console.log("TAB CHANGED: ", tab, firstRender.current);
      localStorage.setItem("tabMonitorAdmin", tab);
      if (tab == "person") {
        (async () => await loadDataHistory())();
      }
    }
  }, [tab]);

  useEffect(() => {
    if (!firstRender.current) {
      console.log("MODULE CHANGED: ", module);
      localStorage.setItem("moduleMonitorAdmin", module);
      getEligibleAnnotators();
    }
  }, [module])

  useEffect(() => {
    if (!firstRender.current) {
      console.log("USER TYPE CHANGED: ", userType);
      localStorage.setItem("userTypeMonitorAdmin", userType);
      // getEligibleAnnotators();
    }
  }, [userType])

  useEffect(() => {
    if (!firstRender.current) {
      console.log("ALL ANNOTATORS", allAnnotators);
      getEligibleAnnotators();
    }
  }, [allAnnotators]);

  useEffect(() => {
    console.log("SELECTED ANNOTATORS", selectedAnnotators);
    if (!firstRender.current) {
      // localStorage.setItem("selectedUsersMonitorAdmin", JSON.stringify(selectedAnnotators));
    }
  }, [selectedAnnotators]);

  useEffect(() => {
    console.log("SHOW CURRENT TIME MONITOR ADMIN CHANGED !!! ", showCurrentTime);
    if (!firstRender.current) {
        localStorage.setItem('showCurrentTimeMonitorAdmin', JSON.stringify(showCurrentTime));
    }
    if (showCurrentTime) {
        setEndDateTime(dayjs(MAX_DATE))
    } else {
        setEndDateTime(localStorage.getItem('endDateTimeMonitorAdmin') ? dayjs(localStorage.getItem('endDateTimeMonitorAdmin')) : dayjs(MAX_DATE))
    }
}, [showCurrentTime])

  useEffect(() => {
    let user = decodeToken()
    console.log('LoggedIn-UserInfo', user);
    if (user) {
      userInfo.current = user;
      setUserRole(user.role);
    } else {
      setLoading(true);
      onLogout();
    }

    if (!(userInfo.current.role.includes("admin"))) {
      setLoading(true);
      onLogout();
    } else {
      (async () => {
        setLoading(false);
        await getAllAnnotators();
      })();
    }
    firstRender.current = false
  }, []);

  const handleExportRowsPerson = (rows: MRT_Row<any>[]) => {
    const csvOptionsPerson = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: userType == "annotator" ? columnsHistoryAnnotator.map((c: any) => c.header) : columnsHistoryValidator.map((c: any) => c.header),
    };

    const csvExporterPerson = new ExportToCsv(csvOptionsPerson);
    console.log(rows)
    csvExporterPerson.generateCsv(rows.map((row) => row.original));
  };

  const handleExportDataPerson = () => {
    const csvOptionsPerson = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: userType == "annotator" ? columnsHistoryAnnotator.map((c: any) => c.header) : columnsHistoryValidator.map((c: any) => c.header),
    };

    const csvExporterPerson = new ExportToCsv(csvOptionsPerson);
    csvExporterPerson.generateCsv(userType == "annotator" ? dataHistoryAnnotator : dataHistoryValidator);
  };

  useEffect(() => {
    console.log("DATA HISTORY ANNOTATOR RESET !!! ", dataHistoryAnnotator);
  }, [dataHistoryAnnotator])

  useEffect(() => {
    console.log("DATA HISTORY VALIDATOR RESET !!! ", dataHistoryValidator);
  }, [dataHistoryValidator])

  useEffect(() => {
    console.log("ELIGIBLE ANNOTATORS", eligibleAnnotators);
    // setOptions(
    //   eligibleAnnotators.map((option: any) => {
    //     const firstLetter = option.id[0].toUpperCase();
    //     return {
    //       firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
    //       ...option,
    //     };
    //   })
    // );
  }, [eligibleAnnotators]);

  useEffect(() => {
    console.log("COLUMN VISIBILITY CHANGED: ", columnVisibilityMonitorAdmin);
    localStorage.setItem('columnVisibilityMonitorAdmin', JSON.stringify(columnVisibilityMonitorAdmin));
  }, [columnVisibilityMonitorAdmin])

  useEffect(() => {
    console.log("COLUMN FILTER CHANGED: ", columnFiltersMonitorAdmin);
    localStorage.setItem('columnFiltersMonitorAdmin', JSON.stringify(columnFiltersMonitorAdmin));
  }, [columnFiltersMonitorAdmin])

  useEffect(() => {
    console.log("GLOBAL FILTER CHANGED: ", globalFilterMonitorAdmin);
    // localStorage.setItem('globalFilterMonitorAdmin', JSON.stringify(globalFilterMonitorAdmin));
  }, [globalFilterMonitorAdmin])

  useEffect(() => {
    console.log("SORTING CHANGED: ", sortingMonitorAdmin);
    localStorage.setItem('sortingMonitorAdmin', JSON.stringify(sortingMonitorAdmin));
  }, [sortingMonitorAdmin])

  useEffect(() => {
    console.log("PAGINATION CHANGED !!! ", paginationMonitorAdmin);
    localStorage.setItem('paginationMonitorAdmin', JSON.stringify(paginationMonitorAdmin));
  }, [paginationMonitorAdmin])

  const getAllAnnotators = async () => {
    let requestOptions = {
      method: 'GET'
    };

    fetch("https://amarkantho.revesoft.com:5110/get-annotator-list/", requestOptions)
      .then(response => response.json())
      .then(data => {
        data.sort(function (a: any, b: any) {
          let x = a.id.toLowerCase();
          let y = b.id.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        });
        // console.log("DATA: ", data)
        setAllAnnotators(data.filter((item: any) => !item.role.includes("admin")))
        // let id = 0;
        // console.log("keys", Object.keys(data));
        // let list = Object.keys(data).map((annotator: any, index: any) => {
        //     return {
        //         "id": data[annotator]["annotator_id"],
        //         "name": annotator,
        //         "roles": data[annotator]["roles"]
        //     }
        // }
        // )
        // setAnnotatorList([...list]);
        // console.log("list", list);
      })
      .catch(error => console.log('error', error));
  }

  const getEligibleAnnotators = () => {
    setLoading(true);
    setEligibleAnnotators(allAnnotators.filter((annotator: any) => {
      let isNotAdmin = !annotator.id.includes('admin')
      let matchesUserType = (annotator.role == userType)
      let matchesModule = ((annotator.tts) == (module == "tts"))

      if (isNotAdmin && matchesUserType && matchesModule) {
        return annotator;
      }
    }));
    setLoading(false);
  }

  const loadDataHistory = async () => {
    setLoading(false);
    // if (!dataHistory.length) {
    //     setIsLoading(true);
    // } else {
    //     setIsRefetching(true);
    // }

    setIsLoading(true);

    console.log(startDateTime, startDateTime['$d'], typeof startDateTime['$d']);
    let dateStart = new Date(startDateTime['$d']);
    let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
    console.log(startDateTimeISO);

    console.log(endDateTime, endDateTime['$d'], typeof endDateTime['$d']);
    let dateEnd = new Date(endDateTime['$d']);
    let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
    console.log(endDateTimeISO);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "annotator_id": selectedAnnotators.join('|'),
        "column_filters": [],
        "start_date": startDateTimeISO,
        "end_date": endDateTimeISO
      }),
      cache: "no-cache"
    };

    let res = await fetch("https://amarkantho.revesoft.com:5113/pull-prev-works/", requestOptions)

    if (res.status === 200 || res.status === 201) {
      let datares = await res.json();
      console.log("datares response: ", datares);

      setIsLoading(false);
      setIsRefetching(false);
      setIsError(false);

      let user_type = datares['userType']
      setUserType(user_type)

      let targetArray = datares['history'].map((item: any, index: any) => {
        verificationStatsDict.current[`${item.speechId}|${item.level}`] = item.verificationStats
        // console.log(item, index);

        // let annotation_status = ''
        // if (item.annotation_status == "0_0") {
        //     annotation_status = "SENTENCE - MACHINE"
        // } else if (item.annotation_status.startsWith("0")) {
        //     annotation_status = `SENTENCE - HUMAN (${item.annotation_status.split('_')[1]})`
        // } else if (item.annotation_status == "1_0") {
        //     annotation_status = "PHONEME - MACHINE"
        // } else if (item.annotation_status.startsWith("1")) {
        //     annotation_status = `PHONEME - HUMAN (${item.annotation_status.split('_')[1]})`
        // } else if (item.annotation_status == "2_0") {
        //     annotation_status = "WORD - MACHINE"
        // } else if (item.annotation_status.startsWith("2")) {
        //     annotation_status = `WORD - HUMAN (${item.annotation_status.split('_')[1]})`
        // } else if (item.annotation_status == "3_0") {
        //     annotation_status = "ALIGN - MACHINE"
        // } else if (item.annotation_status.startsWith("3")) {isError
        //     annotation_status = `ALIGN - HUMAN (${item.annotation_status.split('_')[1]})`
        // }

        return user_type == "annotator" ? {
          speech_id: `${item.speechId}|${item.level}`,
          user_id: item.userId,
          duration: item.duration,
          tps: parseFloat((item.timeTaken / item.annotatedDuration).toFixed(3)),
          timeTaken: item.timeTaken,
          annotatedDuration: item.annotatedDuration,

          validated: item.validated,
          validatedDuration: item.validatedDuration,
          overlappedDuration: item.overlappedDuration,

          verified: item.verified,
          verifiedDuration: item.verifiedDuration,
          deductedDuration: item.deductedDuration,
          acceptedDuration: item.acceptedDuration,

          submittedOn: item.submittedOn,
          billingStatus: item.billStatus == -1 ? "UNPAID" : item.billStatus == 0 ? "PROCESSING" : "PAID",
          level: levels[item.level]
        } : {
          speech_id: `${item.speechId}|${item.level}`,
          user_id: item.userId,

          duration: item.duration,
          tps: parseFloat((item.timeTaken / item.validatedDuration).toFixed(3)),
          timeTaken: item.timeTaken,

          validatedDuration: item.validatedDuration,
          overlappedDuration: item.overlappedDuration,

          verified: item.verified,
          verifiedDuration: item.verifiedDuration,
          deductedDuration: item.deductedDuration,
          acceptedDuration: item.acceptedDuration,

          submittedOn: item.submittedOn,
          billingStatus: item.billStatus == -1 ? "UNPAID" : item.billStatus == 0 ? "PROCESSING" : "PAID",
          level: levels[item.level]
        }
      });

      // console.log("VERIFICATION STATS DICT: ", verificationStatsDict.current)

      user_type == "annotator" ? setDataHistoryAnnotator(targetArray) : setDataHistoryValidator(targetArray);

      // setEligibleDataHistory(targetArray.filter((item: any) => {
      //     let submittedOn = new Date(item.submittedOn).getTime()
      //     let start = new Date(startDateTime).getTime()
      //     let end = new Date(endDateTime).getTime()
      //     return submittedOn >= start && submittedOn <= end
      // }));
    } else {
      //let error = await res.json()
      setIsLoading(false);
      setIsRefetching(false);
      setIsError(true);
    }
  }

  // const handleDeleteRow = useCallback(
  //   (row: MRT_Row<DisputedSpeech>) => {
  //     // if (
  //     //   !window.confirm(`Are you sure you want to delete ${row.getValue('speechId')}`)
  //     // ) {
  //     //   return;
  //     // }
  //     //send api delete request here, then refetch or update local table data for re-render
  //     data.splice(row.index, 1);
  //     setData([...data]);
  //   },
  //   [data],
  // );


  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('darkMode', mode === 'light' ? 'dark' : 'light')
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode],
  );

  function MyHeader() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    // const color = red[500];

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    // return (
    //     <div className="mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: '0px solid red' }}>
    //         <div style={{ width: 'fit-content' }}>
    //             <Button variant="contained" color="primary" endIcon={<HomeIcon />} onClick={() => window.location.replace(window.location.origin + `/`)}>
    //                 HOME
    //             </Button>
    //         </div>
    //         <Stack direction="row" spacing={2} alignItems="center">
    //             <Typography variant="h5" sx={{ fontWeight: '600' }}>REGISTRATION</Typography>
    //             {/* {theme.palette.mode} mode */}
    //             <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
    //                 {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
    //             </IconButton>
    //         </Stack>
    //         <div style={{ width: 'fit-content' }}>
    //             <Button variant="contained" color="success" endIcon={<LoginIcon />} onClick={() => window.location.replace(window.location.origin + `/login`)}>
    //                 LOGIN
    //             </Button>
    //         </div>
    //     </div>
    // );
    return (
      <AppBar position="sticky" sx={{ backgroundColor: '#212121' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            <LocalFireDepartmentIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: 'red' }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href='/admin'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              DISPUTE
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={(event: any) => {
                    handleCloseNavMenu();
                    let pageName = event.target.innerText;
                    if (pageName.toLowerCase() == 'roles') {
                      window.location.replace(window.location.origin + `/roles`)
                    } else if (pageName.toLowerCase() == 'billing') {
                      window.location.replace(window.location.origin + `/billing`)
                    } else if (pageName.toLowerCase() == 'logout') {
                      onLogout();
                    }
                  }}>
                    <Typography textAlign="center">{page}</Typography>
                    {/* {page.toLowerCase()=='registration' ? <LoginIcon /> : <HomeIcon />} */}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <LocalFireDepartmentIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, color: 'red' }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href='/admin'
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              DISPUTE
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  variant="contained"
                  color="success"
                  onClick={(event: any) => {
                    handleCloseNavMenu();
                    let targetButton = event.target;
                    while (targetButton.nodeName != 'BUTTON') {
                      targetButton = targetButton.parentNode;
                    }
                    let pageName = targetButton.innerText;
                    if (pageName.toLowerCase() == 'roles') {
                      window.location.replace(window.location.origin + `/roles`)
                    } else if (pageName.toLowerCase() == 'billing') {
                      window.location.replace(window.location.origin + `/billing`)
                    } else if (pageName.toLowerCase() == 'logout') {
                      onLogout();
                    }
                  }}
                  endIcon={page.toLowerCase() == 'roles' ? <AssignmentIndIcon /> : page.toLowerCase() == 'billing' ? <AttachMoneyIcon /> : <LogoutIcon />}
                  sx={{ ml: 2, my: 2, color: 'white', backgroundColor: '#004d40' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* <Tooltip title="Open settings">
                              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                  <Avatar alt="Remy Sharp" src={activity} />
                              </IconButton>
                          </Tooltip>
                          <Menu
                              sx={{ mt: '45px' }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                              }}
                              keepMounted
                              transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                          >
                              {settings.map((setting) => (
                                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                      <Typography textAlign="center">{setting}</Typography>
                                  </MenuItem>
                              ))}
                          </Menu> */}
              <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Backdrop
          sx={{ color: '#f06292', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => { }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MyHeader />

        <div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <ToggleButtonGroup
                value={tab}
                color='success'
                size='small'
                exclusive
                onChange={handleToggleTab}
              >
                <ToggleButton value="group">
                  <GroupsIcon />
                </ToggleButton>
                <ToggleButton value="person">
                  <PersonIcon />
                </ToggleButton>
                <ToggleButton value="file">
                  <ArticleIcon />
                </ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                value={module}
                color='info'
                size='small'
                exclusive
                onChange={handleToggleModule}
              // disabled={tab != "person"}
              >
                <ToggleButton value="stt">
                  <MicIcon />
                </ToggleButton>
                <ToggleButton value="tts">
                  <TextSnippetIcon />
                </ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                value={userType}
                color='secondary'
                size='small'
                exclusive
                onChange={handleToggleUserType}
                disabled={tab != "person"}
              >
                <ToggleButton value="annotator">
                  <DoneIcon />
                </ToggleButton>
                <ToggleButton value="validator">
                  <DoneAllIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {userType == "annotator" ?
              <MaterialReactTable
                columns={columnsHistoryAnnotator}
                data={dataHistoryAnnotator}
                // muiTableBodyRowProps={({ row }) => ({
                //   onClick: (event) => {
                //     console.log("ROW: ", event, row);
                //   },
                //   sx: {
                //     cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                //   },
                // })}

                // muiTableBodyCellProps={({ cell }) => ({
                //   onClick: (event) => {
                //     console.log("CELL: ", event, cell);
                //     if (cell.column.id == 'speechId') {
                //       cell.row.toggleSelected()
                //     }
                //   },
                // })}

                muiTableBodyCellProps={({ cell }: any) => ({
                  onClick: async (event) => {
                    console.log("CELL: ", event, cell);
                    if (cell.column.id == 'speechId') {
                      if (cell.getValue() != null) {
                        console.log("CELL CLICKED:", cell)
                      }
                    }
                  },
                })}

                // enableRowActions

                // renderRowActionMenuItems={({ closeMenu, row, table }) => [
                //   <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                //   <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                // ]}

                enableStickyHeader
                enableStickyFooter
                // muiTableContainerProps={{ sx: { maxHeight: '300px' } }}

                //optionally override the default column widths
                defaultColumn={{
                  maxSize: 400,
                  minSize: 80,
                  size: 150, //default size is usually 180
                }}
                enableColumnResizing
                columnResizeMode="onChange" //default is "onEnd"
                enableColumnOrdering

                positionActionsColumn="last"
                enableRowSelection
                enablePinning
                enableColumnFilterModes

                filterFns={{
                  customFilterFn: (row, id, filterValue) => {
                    return row.getValue(id) === filterValue;
                  },
                }}
                localization={
                  {
                    filterCustomFilterFn: 'Custom Filter Fn',
                  } as any
                }

                // renderDetailPanel={({ row }) => (
                //   <Box
                //     sx={{
                //       display: 'grid',
                //       margin: 'auto',
                //       gridTemplateColumns: '1fr 1fr',
                //       width: '100%',
                //     }}
                //   >
                //     <Typography>Annotator ID: {row.original.speechId}</Typography>
                //     <Typography>Validator ID: {row.original.speechId}</Typography>
                //   </Box>
                // )}
                positionExpandColumn="first"

                muiToolbarAlertBannerProps={
                  isError
                    ? {
                      color: 'error',
                      children: 'Error loading data',
                    }
                    : undefined
                }

                onColumnVisibilityChange={setColumnVisibilityMonitorAdmin}
                onColumnFiltersChange={setColumnFiltersMonitorAdmin}
                onGlobalFilterChange={setGlobalFilterMonitorAdmin}
                onPaginationChange={setPaginationMonitorAdmin}
                onSortingChange={setSortingMonitorAdmin}

                state={{
                  columnVisibility: columnVisibilityMonitorAdmin,
                  columnFilters: columnFiltersMonitorAdmin,
                  globalFilter: globalFilterMonitorAdmin,
                  sorting: sortingMonitorAdmin,
                  pagination: paginationMonitorAdmin,
                  isLoading: isLoading,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching || isLoading,
                }}
                initialState={{ showColumnFilters: false, columnPinning: { left: ['speechId'] }, density: 'compact' }}

                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                    <Tooltip arrow title="Refresh Data">
                      <IconButton onClick={async () => await loadDataHistory()}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>

                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={allAnnotators}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => option.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={async (event: React.SyntheticEvent, value: any, reason: string, details?: any) => {
                        // let selectedAnnotatorDict: any = {}
                        // for await (const annotator of value) {
                        //   selectedAnnotatorDict[annotator.id] = {
                        //     "roles": annotator.roles,
                        //     "role": annotator.role,
                        //     "tts": annotator.tts
                        //   }
                        // }
                        setSelectedAnnotators(value.map((annotator: any) => annotator.id))
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.id}
                        </li>
                      )}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Users" placeholder="Select User(s)" size="small" />
                      )}
                    />

                    {/* export all data that is currently in the table (ignore pagination, sorting, filtering, etc.) */}
                    {/* <Tooltip arrow title="Export All Data (ignore PFS)">
                        <IconButton onClick={handleExportData} color='info'>
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* export all rows, including from the next page, (still respects filtering and sorting) */}
                    {/* <Tooltip arrow title="Export All Data (respect FS)">
                        <IconButton onClick={() => {
                          let targetRows = table.getPrePaginationRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Eligible Row to Export !!!")
                          }
                        }}
                          color='secondary'
                        // disabled={table.getPrePaginationRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* export all rows as seen on the screen (respects pagination, sorting, filtering, etc.) */}
                    {/* <Tooltip arrow title="Export Current Page (respect PFS)">
                        <IconButton onClick={() => {
                          let targetRows = table.getRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Eligible Row to Export !!!")
                          }
                        }}
                          color='primary'
                        // disabled={table.getRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* export selected rows */}
                    {/* <Tooltip arrow title="Export Selected Rows Only">
                        <IconButton onClick={() => {
                          let targetRows = table.getSelectedRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Row Selected to Export !!!")
                          }
                        }}
                          color='warning'
                        // disabled={table.getSelectedRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* Reject Selected */}
                    {/* <Tooltip arrow title="Mark Annotator as Winner for Selected Speeches">
                        <IconButton onClick={() => {
                          let selectedRows = table.getSelectedRowModel().rows
                          if (selectedRows.length > 0) {
                            console.log("SELECTED ROWS ANNOTATOR WIN: ", selectedRows)

                            selectedRows.forEach((row: MRT_Row<DisputedSpeech>, index: number, array: MRT_Row<DisputedSpeech>[]) => {
                              handleVerdict(row, "annotator")
                            })
                          }
                        }}
                          color='error'
                        // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        >
                          <DoneIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* Accept Selected */}
                    {/* <Tooltip arrow title="Mark Validator as Winner for Selected Speeches">
                        <IconButton onClick={() => {
                          let selectedRows = table.getSelectedRowModel().rows
                          if (selectedRows.length > 0) {
                            console.log("SELECTED ROWS VALIDATOR WIN: ", selectedRows)

                            selectedRows.forEach((row: MRT_Row<DisputedSpeech>, index: number, array: MRT_Row<DisputedSpeech>[]) => {
                              handleVerdict(row, "validator")
                            })
                          }
                        }}
                          color='success'
                        // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        >
                          <DoneAllIcon />
                        </IconButton>
                      </Tooltip> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} size="small" />}
                        label="FROM"
                        value={startDateTime}
                        minDateTime={dayjs(MIN_DATE)}
                        hideTabs={false}
                        // showToolbar={true}
                        onChange={(newValue: any) => {
                          if (newValue) {
                            // console.log(newValue, newValue['$d'], typeof newValue['$d'])
                            // console.log(new Date(newValue['$d']).toLocaleString());
                            setStartDateTime(newValue);

                            let dateStart = new Date(newValue['$d']);
                            let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                            localStorage.setItem('startDateTimeMonitorAdmin', startDateTimeISO)

                            // setEligibleDataHistory(dataHistory.filter((item: any) => {
                            //     let submittedOn = new Date(item.submittedOn).getTime()
                            //     let start = new Date(newValue['$d']).getTime()
                            //     let end = new Date(endDateTime).getTime()
                            //     return submittedOn >= start && submittedOn <= end
                            // }))
                          }
                        }}
                      />
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} size="small" />}
                        label="TO"
                        value={endDateTime}
                        maxDateTime={dayjs(MAX_DATE)}
                        hideTabs={false}
                        onChange={(newValue: any) => {
                          if (newValue) {
                            setEndDateTime(newValue);

                            let dateEnd = new Date(newValue['$d']);
                            let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                            localStorage.setItem('endDateTimeMonitorAdmin', endDateTimeISO)

                            // setEligibleDataHistory(dataHistory.filter((item: any) => {
                            //     let submittedOn = new Date(item.submittedOn).getTime()
                            //     let start = new Date(startDateTime).getTime()
                            //     let end = new Date(newValue['$d']).getTime()
                            //     return submittedOn >= start && submittedOn <= end
                            // }))
                          }
                        }}
                      />
                    </LocalizationProvider>
                    <Tooltip arrow title={showCurrentTime ? "Showing Current Time" : "Showing Last Selected"}>
                      {showCurrentTime ? <IconButton color='success' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                        <UpdateIcon />
                      </IconButton> : <IconButton color='error' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                        <UpdateDisabledIcon />
                      </IconButton>
                      }
                    </Tooltip>
                  </Box>
                )}
              /> :
              <MaterialReactTable
                columns={columnsHistoryValidator}
                data={dataHistoryValidator}
                // muiTableBodyRowProps={({ row }) => ({
                //   onClick: (event) => {
                //     console.log("ROW: ", event, row);
                //   },
                //   sx: {
                //     cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                //   },
                // })}

                // muiTableBodyCellProps={({ cell }) => ({
                //   onClick: (event) => {
                //     console.log("CELL: ", event, cell);
                //     if (cell.column.id == 'speechId') {
                //       cell.row.toggleSelected()
                //     }
                //   },
                // })}

                muiTableBodyCellProps={({ cell }: any) => ({
                  onClick: async (event) => {
                    console.log("CELL: ", event, cell);
                    if (cell.column.id == 'speechId') {
                      if (cell.getValue() != null) {
                        console.log("CELL CLICKED:", cell)
                      }
                    }
                  },
                })}

                // enableRowActions

                // renderRowActionMenuItems={({ closeMenu, row, table }) => [
                //   <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                //   <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                // ]}

                enableStickyHeader
                enableStickyFooter
                // muiTableContainerProps={{ sx: { maxHeight: '300px' } }}

                //optionally override the default column widths
                defaultColumn={{
                  maxSize: 400,
                  minSize: 80,
                  size: 150, //default size is usually 180
                }}
                enableColumnResizing
                columnResizeMode="onChange" //default is "onEnd"
                enableColumnOrdering

                positionActionsColumn="last"
                enableRowSelection
                enablePinning
                enableColumnFilterModes

                filterFns={{
                  customFilterFn: (row, id, filterValue) => {
                    return row.getValue(id) === filterValue;
                  },
                }}
                localization={
                  {
                    filterCustomFilterFn: 'Custom Filter Fn',
                  } as any
                }

                // renderDetailPanel={({ row }) => (
                //   <Box
                //     sx={{
                //       display: 'grid',
                //       margin: 'auto',
                //       gridTemplateColumns: '1fr 1fr',
                //       width: '100%',
                //     }}
                //   >
                //     <Typography>Annotator ID: {row.original.speechId}</Typography>
                //     <Typography>Validator ID: {row.original.speechId}</Typography>
                //   </Box>
                // )}
                positionExpandColumn="first"

                muiToolbarAlertBannerProps={
                  isError
                    ? {
                      color: 'error',
                      children: 'Error loading data',
                    }
                    : undefined
                }

                onColumnVisibilityChange={setColumnVisibilityMonitorAdmin}
                onColumnFiltersChange={setColumnFiltersMonitorAdmin}
                onGlobalFilterChange={setGlobalFilterMonitorAdmin}
                onPaginationChange={setPaginationMonitorAdmin}
                onSortingChange={setSortingMonitorAdmin}

                state={{
                  columnVisibility: columnVisibilityMonitorAdmin,
                  columnFilters: columnFiltersMonitorAdmin,
                  globalFilter: globalFilterMonitorAdmin,
                  sorting: sortingMonitorAdmin,
                  pagination: paginationMonitorAdmin,
                  isLoading: isLoading,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching || isLoading,
                }}
                initialState={{ showColumnFilters: false, columnPinning: { left: ['speechId'] }, density: 'compact' }}

                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                    <Tooltip arrow title="Refresh Data">
                      <IconButton onClick={async () => await loadDataHistory()}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>

                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={eligibleAnnotators}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => option.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={async (event: React.SyntheticEvent, value: any, reason: string, details?: any) => {
                        // let selectedAnnotatorDict: any = {}
                        // for await (const annotator of value) {
                        //   selectedAnnotatorDict[annotator.id] = {
                        //     "roles": annotator.roles,
                        //     "role": annotator.role,
                        //     "tts": annotator.tts
                        //   }
                        // }
                        setSelectedAnnotators(value.map((annotator: any) => annotator.id))
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.id}
                        </li>
                      )}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Users" placeholder="Select User(s)" size="small" />
                      )}
                    />

                    {/* export all data that is currently in the table (ignore pagination, sorting, filtering, etc.) */}
                    {/* <Tooltip arrow title="Export All Data (ignore PFS)">
                        <IconButton onClick={handleExportData} color='info'>
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* export all rows, including from the next page, (still respects filtering and sorting) */}
                    {/* <Tooltip arrow title="Export All Data (respect FS)">
                        <IconButton onClick={() => {
                          let targetRows = table.getPrePaginationRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Eligible Row to Export !!!")
                          }
                        }}
                          color='secondary'
                        // disabled={table.getPrePaginationRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* export all rows as seen on the screen (respects pagination, sorting, filtering, etc.) */}
                    {/* <Tooltip arrow title="Export Current Page (respect PFS)">
                        <IconButton onClick={() => {
                          let targetRows = table.getRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Eligible Row to Export !!!")
                          }
                        }}
                          color='primary'
                        // disabled={table.getRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* export selected rows */}
                    {/* <Tooltip arrow title="Export Selected Rows Only">
                        <IconButton onClick={() => {
                          let targetRows = table.getSelectedRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Row Selected to Export !!!")
                          }
                        }}
                          color='warning'
                        // disabled={table.getSelectedRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* Reject Selected */}
                    {/* <Tooltip arrow title="Mark Annotator as Winner for Selected Speeches">
                        <IconButton onClick={() => {
                          let selectedRows = table.getSelectedRowModel().rows
                          if (selectedRows.length > 0) {
                            console.log("SELECTED ROWS ANNOTATOR WIN: ", selectedRows)

                            selectedRows.forEach((row: MRT_Row<DisputedSpeech>, index: number, array: MRT_Row<DisputedSpeech>[]) => {
                              handleVerdict(row, "annotator")
                            })
                          }
                        }}
                          color='error'
                        // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        >
                          <DoneIcon />
                        </IconButton>
                      </Tooltip> */}

                    {/* Accept Selected */}
                    {/* <Tooltip arrow title="Mark Validator as Winner for Selected Speeches">
                        <IconButton onClick={() => {
                          let selectedRows = table.getSelectedRowModel().rows
                          if (selectedRows.length > 0) {
                            console.log("SELECTED ROWS VALIDATOR WIN: ", selectedRows)

                            selectedRows.forEach((row: MRT_Row<DisputedSpeech>, index: number, array: MRT_Row<DisputedSpeech>[]) => {
                              handleVerdict(row, "validator")
                            })
                          }
                        }}
                          color='success'
                        // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        >
                          <DoneAllIcon />
                        </IconButton>
                      </Tooltip> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} size="small" />}
                        label="FROM"
                        value={startDateTime}
                        minDateTime={dayjs(MIN_DATE)}
                        hideTabs={false}
                        // showToolbar={true}
                        onChange={(newValue: any) => {
                          if (newValue) {
                            // console.log(newValue, newValue['$d'], typeof newValue['$d'])
                            // console.log(new Date(newValue['$d']).toLocaleString());
                            setStartDateTime(newValue);

                            let dateStart = new Date(newValue['$d']);
                            let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                            localStorage.setItem('startDateTimeMonitorAdmin', startDateTimeISO)

                            // setEligibleDataHistory(dataHistory.filter((item: any) => {
                            //     let submittedOn = new Date(item.submittedOn).getTime()
                            //     let start = new Date(newValue['$d']).getTime()
                            //     let end = new Date(endDateTime).getTime()
                            //     return submittedOn >= start && submittedOn <= end
                            // }))
                          }
                        }}
                      />
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} size="small" />}
                        label="TO"
                        value={endDateTime}
                        maxDateTime={dayjs(MAX_DATE)}
                        hideTabs={false}
                        onChange={(newValue: any) => {
                          if (newValue) {
                            setEndDateTime(newValue);

                            let dateEnd = new Date(newValue['$d']);
                            let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                            localStorage.setItem('endDateTimeMonitorAdmin', endDateTimeISO)

                            // setEligibleDataHistory(dataHistory.filter((item: any) => {
                            //     let submittedOn = new Date(item.submittedOn).getTime()
                            //     let start = new Date(startDateTime).getTime()
                            //     let end = new Date(newValue['$d']).getTime()
                            //     return submittedOn >= start && submittedOn <= end
                            // }))
                          }
                        }}
                      />
                    </LocalizationProvider>
                    <Tooltip arrow title={showCurrentTime ? "Showing Current Time" : "Showing Last Selected"}>
                      {showCurrentTime ? <IconButton color='success' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                        <UpdateIcon />
                      </IconButton> : <IconButton color='error' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                        <UpdateDisabledIcon />
                      </IconButton>
                      }
                    </Tooltip>
                  </Box>
                )}
              />
            }
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider >
  );
};

export default AdminMonitor;

