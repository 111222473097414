import React, { FC, ReactNode, useEffect, useState, useRef, useMemo, useContext, createContext, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get, isLoggedIn, post, postToPython, setToken, decodeToken, unsetToken } from '../../common/http';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

import MaterialReactTable, { MRT_ColumnDef, MRT_Cell, MRT_Column, MRT_Row } from 'material-react-table';

import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { styled, lighten, darken } from '@mui/system';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MonitorIcon from '@mui/icons-material/Monitor';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg, ToastInfoMsg } from "../../common/toast";
import { red } from '@mui/material/colors';
import { userInfo } from 'os';

const ColorModeContext = createContext({ toggleColorMode: () => { } });
const pages = ['Roles', 'Monitor', 'Logout'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DisputedSpeech = {
  speechId: string;
  duration: number;
  textSimilarity: number;
  overlappedA: number;
  overlappedV: number;
  level: string;
  annotatorId: string;
  validatorId: string;
  annotatedDuration: number;
  validatedDuration: number;
  distribution: string;
  distributionSource: string;
  spokenAt: string;
  annotatedAt: string;
  validatedAt: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: '100%' }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AdminTaskPage: FC = () => {
  const levels = ['SENTENCE', 'WORD', 'TRANSCRIPTION', 'PHONEME']
  const distributions = ['READ', 'CONVERSATION_MONO', 'CONVERSATION_DI', 'CONVERSATION_MULTI', 'LECTURE', 'SPONTANEOUS', 'MISCELLANEOUS']
  const distribution_sources = ['CREATE', 'COLLECT']
  const billing_status_list = ["UNPAID", "PROCESSING", "PAID"]
  const RESTRICTED_ADMINS = { "annotation_tts_buet1202@gmail.com": 1, "crebsol_stt_admin@gmail.com": 1 }

  const userInfo: any = useRef({})

  const [loading, setLoading]: any = useState(true);

  const MIN_DATE = new Date('2023-01-01T00:00:00.000000');
  const MAX_DATE = new Date();

  const [showCurrentTime, setShowCurrentTime] = useState(localStorage.getItem('showCurrentTimeAdmin') ? JSON.parse(localStorage.getItem('showCurrentTimeAdmin')) : true)

  const [startDateTime, setStartDateTime]: any = React.useState<Dayjs | null>(localStorage.getItem('startDateTimeAdmin') ? dayjs(localStorage.getItem('startDateTimeAdmin')) : dayjs(MIN_DATE));
  const [endDateTime, setEndDateTime]: any = React.useState<Dayjs | null>((!showCurrentTime) && localStorage.getItem('endDateTimeAdmin') ? dayjs(localStorage.getItem('endDateTimeAdmin')) : dayjs(MAX_DATE));

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnVisibilityAdmin, setColumnVisibilityAdmin] = useState<VisibilityState>(localStorage.getItem('columnVisibilityAdmin') ? JSON.parse(localStorage.getItem('columnVisibilityAdmin')) : { duration: false, annotatedDuration: false, validatedDuration: false, spokenAt: false, annotatedAt: false, validatedAt: false });
  const [columnFiltersAdmin, setColumnFiltersAdmin] = useState<ColumnFiltersState>(localStorage.getItem('columnFiltersAdmin') ? JSON.parse(localStorage.getItem('columnFiltersAdmin')) : []);
  const [globalFilterAdmin, setGlobalFilterAdmin] = useState(localStorage.getItem('globalFilterAdmin') ? JSON.parse(localStorage.getItem('globalFilterAdmin')) : undefined);
  const [sortingAdmin, setSortingAdmin] = useState<SortingState>(localStorage.getItem('sortingAdmin') ? JSON.parse(localStorage.getItem('sortingAdmin')) : []);
  const [paginationAdmin, setPaginationAdmin] = useState<PaginationState>(localStorage.getItem('paginationAdmin') ? JSON.parse(localStorage.getItem('paginationAdmin')) : {
    pageIndex: 0,
    pageSize: 10,
  });

  const [tabValue, setTabValue] = useState(localStorage.getItem('tabValueAdmin') ? parseInt(localStorage.getItem('tabValueAdmin')) : 0);

  const [tabs, setTabs] = useState(['STT', 'TTS'])

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    console.log("HANDLE TAB CLICK: ", newValue)
    setTabValue(newValue);
  };

  const columns = useMemo<MRT_ColumnDef<DisputedSpeech>[]>(
    () => [
      // {
      //   header: 'Account Status',
      //   accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
      //   id: 'isActive',
      //   filterVariant: 'checkbox',
      //   Cell: ({ cell }) =>
      //     cell.getValue() === 'true' ? 'Active' : 'Inactive',
      //   size: 220,
      // },
      {
        accessorKey: 'speechId',
        header: 'Speech ID',
        filterVariant: 'text', // default
        // enableClickToCopy: true,
        enableHiding: false,
        // Cell: ({ cell, row }: any) => (
        //   <Box sx={{ cursor: 'pointer' }}>
        //     {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        //     <a href='https://google.com' target='_blank'>{cell.getValue()}</a>
        //   </Box>
        // ),
        Cell: ({ cell, row }: any) => (
          <Box
            sx={{
              cursor: 'pointer'
            }}
          >
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{cell.getValue()}</span>
          </Box>
        ),
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopyIcon />,
          sx: { justifyContent: 'flex-start' },
        },
      },
      {
        accessorKey: 'duration',
        header: 'Duration',
        enableColumnFilter: false,

        // filterVariant: 'range',
        // // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between

        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //   sx: { textAlign: 'center' }
        // },
      },
      {
        accessorKey: 'textSimilarity',
        header: 'Text Similarity %',
        enableColumnFilter: false,

        // filterVariant: 'range',
        // // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between

        // enableResizing: false, //disable resizing for this column
        // enablePinning: false,
        // size: 100,
        // muiTableBodyCellProps: {
        //   sx: { textAlign: 'center' }
        // },
      },
      {
        accessorKey: 'overlappedA',
        header: 'OverlappedA %',
        enableColumnFilter: false,

        // filterVariant: 'range',
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between

        Header: ({ column }) => <em><DoneIcon />{column.columnDef.header}</em>,
        // muiTableHeadCellProps: {
        //   sx: { textAlign: 'center' }
        // },
        // muiTableBodyCellProps: {
        //   sx: { textAlign: 'center' }
        // }
      },
      {
        accessorKey: 'overlappedV',
        header: 'OverlappedV %',
        enableColumnFilter: false,

        // filterVariant: 'range',
        // filterFn: 'betweenInclusive', // use betweenInclusive instead of between

        Header: ({ column }) => <em><DoneAllIcon />{column.columnDef.header}</em>,
        // muiTableHeadCellProps: {
        //   sx: { textAlign: 'center' }
        // },
        // muiTableBodyCellProps: {
        //   sx: { textAlign: 'center' }
        // }
      },
      {
        accessorKey: 'level',
        header: 'LEVEL',
        filterVariant: 'multi-select',
        filterSelectOptions: levels,
      },
      {
        accessorKey: 'annotatorId',
        header: 'Annotator ID',
        filterVariant: 'text'
      },
      {
        accessorKey: 'validatorId',
        header: 'Validator ID',
        filterVariant: 'text'
      },
      {
        accessorKey: 'annotatedDuration',
        header: 'Annotated Duration',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validatedDuration',
        header: 'Validated Duration',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'distribution',
        header: 'DISTRIBUTION',
        filterVariant: 'multi-select',
        filterSelectOptions: distributions,
      },
      {
        accessorKey: 'distributionSource',
        header: 'DISTRIBUTION SOURCE',
        filterVariant: 'multi-select',
        filterSelectOptions: distribution_sources,
      },
      {
        accessorFn: (row) => new Date(row.spokenAt),
        id: 'spokenAt',
        header: 'Spoken At',
        Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => new Date(row.annotatedAt),
        id: 'annotatedAt',
        header: 'Annotated At',
        Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => new Date(row.validatedAt),
        id: 'validatedAt',
        header: 'Validated At',
        Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const [data, setData] = useState<DisputedSpeech[]>([]);

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c: any) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows: MRT_Row<DisputedSpeech>[]) => {
    console.log(rows)
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const formatHourMinSec = (totalSeconds: number) => {
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
    let seconds = Math.round(totalSeconds - hours * 3600 - minutes * 60)
    // return `${("0" + hour).slice(-2)} hr ${("0" + minutes).slice(-2)} min ${("0" + seconds).slice(-2)} sec`
    return `${hours > 0 ? hours + ' hr ' : ''}${minutes > 0 ? minutes + ' min ' : ''}${seconds > 0 ? seconds + ' sec' : ''}`
  }

  const onLogout = () => {
    // setUnloadEventListener(false);

    // setTimeout(() => {
    localStorage.removeItem('speech_id_admin');
    localStorage.removeItem('latestPage');
    localStorage.removeItem('latestSizePerPage');
    unsetToken();
    // history.push('/login')
    // history.go(0)
    window.location.replace(window.location.origin + `/login`)
    // }, 100);
    // return true;
  };

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('darkMode') == 'light' ? 'light' : 'dark');
  const [open, setOpen] = useState(false);
  const firstRender = useRef(true);

  const [annotatorList, setAnnotatorList]: any = useState([]);

  useEffect(() => {
    setGlobalFilterAdmin(undefined);
    (async () => {
      console.log("TAB VALUE CHANGED: ", tabValue);

      if (!firstRender.current) {
        localStorage.setItem("tabValueAdmin", tabValue.toString());
        await loadData();
      }
    })()
  }, [tabValue])

  useEffect(() => {
    let user = decodeToken()
    console.log('LoggedIn-UserInfo', user);
    if (user) {
      userInfo.current = user;
    } else {
      setLoading(true);
      onLogout();
    }

    if (!(userInfo.current.role.includes("admin"))) {
      setLoading(true);
      onLogout();
    } else {
      (async () => {
        let unfinished: any = await hasUnfinishedTask();
        if ('error' in unfinished) {
          return;
        } else {
          userInfo.current['role'] = unfinished['role']
          userInfo.current['tts'] = unfinished['tts']

          if (!unfinished["saved"]) {
            setLoading(false);
            await loadData();
          } else {
            localStorage.setItem("speech_id_admin", unfinished["speech_id"])
            // history.push(`/annotation/`);
            // history.go(0);
            window.location.replace(window.location.origin + `/admin-view-edit`)
          }
        }
        // await loadRoleWiseLabels();
      })();
    }
    firstRender.current = false;
    //called when jwt changed
    // if (isLoggedIn()) {
    //     // history.push('/')
    //     window.location.replace(window.location.origin + `/annotation-task`)
    // } else {
    //     (async () => {
    //         await getAnnotatorList();
    //     })()
    //     setLoading(false);
    // }
    // setLoading(false);
  }, []);

  useEffect(() => {
    console.log("DATA RESET !!! ", data);
    // const msg = new SpeechSynthesisUtterance("Hello World")
    // window.speechSynthesis.speak(msg)
  }, [data])

  useEffect(() => {
    console.log("TABS CHANGED: ", tabs);
  }, [tabs])

  useEffect(() => {
    console.log("COLUMN VISIBILITY CHANGED: ", columnVisibilityAdmin);
    localStorage.setItem('columnVisibilityAdmin', JSON.stringify(columnVisibilityAdmin));
  }, [columnVisibilityAdmin])

  useEffect(() => {
    console.log("COLUMN FILTER CHANGED: ", columnFiltersAdmin);
    localStorage.setItem('columnFiltersAdmin', JSON.stringify(columnFiltersAdmin));
  }, [columnFiltersAdmin])

  useEffect(() => {
    console.log("GLOBAL FILTER CHANGED: ", globalFilterAdmin);
    // localStorage.setItem('globalFilterAdmin', JSON.stringify(globalFilter));
  }, [globalFilterAdmin])

  useEffect(() => {
    console.log("SORTING CHANGED: ", sortingAdmin);
    localStorage.setItem('sortingAdmin', JSON.stringify(sortingAdmin));
  }, [sortingAdmin])

  useEffect(() => {
    console.log("PAGINATION CHANGED !!! ", paginationAdmin);
    localStorage.setItem('paginationAdmin', JSON.stringify(paginationAdmin));
  }, [paginationAdmin])

  useEffect(() => {
    console.log("SHOW CURRENT TIME ADMIN CHANGED !!! ", showCurrentTime);
    if (!firstRender.current) {
        localStorage.setItem('showCurrentTimeAdmin', JSON.stringify(showCurrentTime));
    }
    if (showCurrentTime) {
        setEndDateTime(dayjs(MAX_DATE))
    } else {
        setEndDateTime(localStorage.getItem('endDateTimeAdmin') ? dayjs(localStorage.getItem('endDateTimeAdmin')) : dayjs(MAX_DATE))
    }
}, [showCurrentTime])

  const hasUnfinishedTask = async () => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    try {
      let requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "annotator_id": userInfo.current.userId
        }),
        cache: "no-cache"
      };

      let response = await fetch("https://amarkantho.revesoft.com:5103/has-unfinished-work/", requestOptions)
      setLoading(false);
      let status = response.status
      if (status == 200 || status == 201) {
        let result = await response.json()
        // ToastSuccessMsg(responseBody.message)
        console.log("HAS UNFINISHED WORK: ", result);
        return result;
      } else if (status == 422) {
        // ToastFailedMsg("Field Missing !");
        return { "error": "Field Missing !" }
      } else {
        let responseBody = await response.json()
        ToastFailedMsg(responseBody.message)
        return { "error": responseBody.message }
      }
    }
    catch (err: any) {
      setLoading(false)
      console.log("ERROR ! ", err.message)
      ToastFailedMsg(`ERROR ! ${err.message}`)
      // window.location.replace(window.location.origin + `/`)
      return { "error": err.message }
    }
  }

  const loadData = async () => {
    // if (!data.length) {
    //   setIsLoading(true);
    // } else {
    //   setIsRefetching(true);
    // }
    setIsLoading(true);

    console.log(startDateTime, startDateTime['$d'], typeof startDateTime['$d']);
    let dateStart = new Date(startDateTime['$d']);
    let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
    console.log(startDateTimeISO);

    console.log(endDateTime, endDateTime['$d'], typeof endDateTime['$d']);
    let dateEnd = new Date(endDateTime['$d']);
    let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
    console.log(endDateTimeISO);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "verifier_id": userInfo.current.userId,
        "module": tabs[tabValue].toLowerCase(),
        "column_filters": columnFiltersAdmin,
        "global_filter": globalFilterAdmin === undefined ? '' : globalFilterAdmin,
        "start_date": startDateTimeISO,
        "end_date": endDateTimeISO
      }),
      // redirect: 'follow'
    };

    let res = await fetch("https://amarkantho.revesoft.com:5111/pull-disputes/", requestOptions)

    if (res.status === 200 || res.status === 201) {
      let datares = await res.json();
      console.log("datares response: ", datares?.data);
      setIsLoading(false);
      setIsRefetching(false);
      setIsError(false);
      setData(datares.data.map((item: any, index: any) => {
        // console.log(item, index);

        // let annotation_status = ''
        // if (item.annotation_status == "0_0") {
        //     annotation_status = "SENTENCE - MACHINE"
        // } else if (item.annotation_status.startsWith("0")) {
        //     annotation_status = `SENTENCE - HUMAN (${item.annotation_status.split('_')[1]})`
        // } else if (item.annotation_status == "1_0") {
        //     annotation_status = "PHONEME - MACHINE"
        // } else if (item.annotation_status.startsWith("1")) {
        //     annotation_status = `PHONEME - HUMAN (${item.annotation_status.split('_')[1]})`
        // } else if (item.annotation_status == "2_0") {
        //     annotation_status = "WORD - MACHINE"
        // } else if (item.annotation_status.startsWith("2")) {
        //     annotation_status = `WORD - HUMAN (${item.annotation_status.split('_')[1]})`
        // } else if (item.annotation_status == "3_0") {
        //     annotation_status = "ALIGN - MACHINE"
        // } else if (item.annotation_status.startsWith("3")) {
        //     annotation_status = `ALIGN - HUMAN (${item.annotation_status.split('_')[1]})`
        // }

        return {
          speechId: item.speech_id,
          duration: item.duration,
          textSimilarity: item.text_similarity,
          overlappedA: Math.min(Math.round((item.annotator_overlapped * 100 / item.annotated_duration) * 100) / 100, 100),
          overlappedV: Math.min(Math.round((item.validator_overlapped * 100 / item.validated_duration) * 100) / 100, 100),
          level: levels[item.annotation_status],
          annotatorId: item.annotator_id,
          validatorId: item.validator_id,
          annotatedDuration: item.annotated_duration.toFixed(2),
          validatedDuration: item.validated_duration.toFixed(2),
          distribution: item.distribution,
          distributionSource: item.distribution_source,
          spokenAt: item.spoken_at,
          annotatedAt: item.annotated_at,
          validatedAt: item.validated_at,
        }
      }));
    } else {
      //let error = await res.json()
      setIsLoading(false);
      setIsRefetching(false);
      setIsError(true);
    }
  }

  const handleVerdict = async (row: any, winner: any) => {
    if (row.original.speechId != null && row.original.level != null) {
      setLoading(true);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "verifier_id": userInfo.current.userId,
          "verdict_list": [{
            "speech_id": row.original.speechId,
            "annotation_status": levels.indexOf(row.original.level),
            "winner": winner,
            "manually_checked": false
          }]
        }),
        // redirect: 'follow'
      };
      console.log(requestOptions);

      let res = await fetch("https://amarkantho.revesoft.com:5112/clear-disputes/", requestOptions)

      if (res.status === 200 || res.status === 201) {
        let datares = await res.json();
        setLoading(false);
        console.log("datares response: ", datares);
        ToastSuccessMsg(datares.message);
        handleDeleteRow(row);
      } else {
        let datares = await res.json();
        setLoading(false);
        ToastFailedMsg(datares.message);
      }
    }
  }

  const handleDeleteRow = useCallback(
    (row: MRT_Row<DisputedSpeech>) => {
      // if (
      //   !window.confirm(`Are you sure you want to delete ${row.getValue('speechId')}`)
      // ) {
      //   return;
      // }
      //send api delete request here, then refetch or update local table data for re-render
      data.splice(row.index, 1);
      setData([...data]);
    },
    [data],
  );


  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('darkMode', mode === 'light' ? 'dark' : 'light')
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode],
  );

  function MyHeader() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    // const color = red[500];

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    // return (
    //     <div className="mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: '0px solid red' }}>
    //         <div style={{ width: 'fit-content' }}>
    //             <Button variant="contained" color="primary" endIcon={<HomeIcon />} onClick={() => window.location.replace(window.location.origin + `/`)}>
    //                 HOME
    //             </Button>
    //         </div>
    //         <Stack direction="row" spacing={2} alignItems="center">
    //             <Typography variant="h5" sx={{ fontWeight: '600' }}>REGISTRATION</Typography>
    //             {/* {theme.palette.mode} mode */}
    //             <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
    //                 {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
    //             </IconButton>
    //         </Stack>
    //         <div style={{ width: 'fit-content' }}>
    //             <Button variant="contained" color="success" endIcon={<LoginIcon />} onClick={() => window.location.replace(window.location.origin + `/login`)}>
    //                 LOGIN
    //             </Button>
    //         </div>
    //     </div>
    // );
    return (
      <AppBar position="sticky" sx={{ backgroundColor: '#212121' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            <LocalFireDepartmentIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: 'red' }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href='/admin'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              DISPUTE
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={(event: any) => {
                    handleCloseNavMenu();
                    let pageName = event.target.innerText;
                    if (pageName.toLowerCase() == 'roles') {
                      window.location.replace(window.location.origin + `/roles`)
                    } else if (pageName.toLowerCase() == 'monitor') {
                      window.location.replace(window.location.origin + `/admin-monitor`)
                    } else if (pageName.toLowerCase() == 'logout') {
                      onLogout();
                    }
                  }}>
                    <Typography textAlign="center">{page}</Typography>
                    {/* {page.toLowerCase()=='registration' ? <LoginIcon /> : <HomeIcon />} */}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <LocalFireDepartmentIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, color: 'red' }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href='/admin'
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              DISPUTE
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  variant="contained"
                  color="success"
                  onClick={(event: any) => {
                    handleCloseNavMenu();
                    let targetButton = event.target;
                    while (targetButton.nodeName != 'BUTTON') {
                      targetButton = targetButton.parentNode;
                    }
                    let pageName = targetButton.innerText;
                    if (pageName.toLowerCase() == 'roles') {
                      window.location.replace(window.location.origin + `/roles`)
                    } else if (pageName.toLowerCase() == 'monitor') {
                      window.location.replace(window.location.origin + `/admin-monitor`)
                    } else if (pageName.toLowerCase() == 'logout') {
                      onLogout();
                    }
                  }}
                  endIcon={page.toLowerCase() == 'roles' ? <AssignmentIndIcon /> : page.toLowerCase() == 'monitor' ? <MonitorIcon /> : <LogoutIcon />}
                  sx={{ ml: 2, my: 2, color: 'white', backgroundColor: '#004d40' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* <Tooltip title="Open settings">
                              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                  <Avatar alt="Remy Sharp" src={activity} />
                              </IconButton>
                          </Tooltip>
                          <Menu
                              sx={{ mt: '45px' }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                              }}
                              keepMounted
                              transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                          >
                              {settings.map((setting) => (
                                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                      <Typography textAlign="center">{setting}</Typography>
                                  </MenuItem>
                              ))}
                          </Menu> */}
              <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Backdrop
          sx={{ color: '#f06292', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => { }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MyHeader />

        <div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Tabs value={tabValue} onChange={handleTabClick} textColor={tabValue == 0 ? 'primary' : 'secondary'} indicatorColor={tabValue == 0 ? 'primary' : 'secondary'} centered>
                <Tab label={tabs[0]} {...a11yProps(0)} />
                <Tab label={tabs[1]} {...a11yProps(1)} />
              </Tabs>
            </Box>
            {[0, 1].map(idx =>
              <TabPanel key={idx} value={tabValue} index={idx}>

                <MaterialReactTable
                  columns={columns}
                  data={data}
                  // muiTableBodyRowProps={({ row }) => ({
                  //   onClick: (event) => {
                  //     console.log("ROW: ", event, row);
                  //   },
                  //   sx: {
                  //     cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                  //   },
                  // })}

                  // muiTableBodyCellProps={({ cell }) => ({
                  //   onClick: (event) => {
                  //     console.log("CELL: ", event, cell);
                  //     if (cell.column.id == 'speechId') {
                  //       cell.row.toggleSelected()
                  //     }
                  //   },
                  // })}

                  muiTableBodyCellProps={({ cell }: any) => ({
                    onClick: async (event) => {
                      console.log("CELL: ", event, cell);
                      let module = tabs[tabValue].toUpperCase()
                      if (cell.column.id == 'speechId') {
                        if (cell.getValue() != null) {
                          setLoading(true);
                          try {
                            let myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            let requestOptions: RequestInit = {
                              method: 'POST',
                              headers: myHeaders,
                              body: JSON.stringify({
                                "annotator_id": userInfo.current.userId,
                                "speech_id": cell.getValue(),
                                "module": module
                                // "distribution": cell.row.original.distribution,
                                // "distribution_source": cell.row.original.distribution_source
                              }),
                              cache: "no-cache"
                            };

                            let res = await fetch("https://amarkantho.revesoft.com:5115/pick-admin/", requestOptions)
                            let data = await res.json();
                            setLoading(false);
                            if (res.status === 200 || res.status === 201) {
                              console.log("data: ", data);
                              if (data && !(data.alreadyPicked)) {
                                /*await loadForCurrentPage({
                                       ...filterQueries,
                                    page: response?.currentPage
                                })*/
                                ToastSuccessMsg(data.message);
                                //history.push(`/annotation/${item.id}`);
                                localStorage.setItem("last_picked_admin", Math.round(new Date().getTime() / 1000).toString());
                                localStorage.setItem("speech_id_admin", cell.getValue());
                                cell.row.toggleSelected()
                                // window.open(window.location.origin + `/admin-view-edit`, '_blank') || window.location.replace(window.location.origin + `/admin-view-edit`)
                                window.location.replace(window.location.origin + `/admin-view-edit`)
                                // return true;

                              } else {
                                ToastWarningMsg(data.message);
                                localStorage.setItem("speech_id_admin", data["speech_id"]);
                                // return true;
                              }
                            }
                            else { //if (res.status == 403 ) {
                              ToastFailedMsg(data.message);
                              // return false;
                            }
                          } catch (err: any) {
                            setLoading(false);
                            ToastFailedMsg(err.message + '! Please check Internet connection ...');
                          }
                        }
                      }
                    },
                  })}

                  enableRowActions
                  renderRowActions={({ cell, row, table }) => {
                    return (
                      <Box>
                        <Tooltip arrow title="Mark Annotator as Winner for this Speech">
                          <IconButton onClick={async () => {
                            if (!(userInfo.current.userId in RESTRICTED_ADMINS)) {
                              console.log("REJECT", row);
                              await handleVerdict(row, "annotator");
                            }
                          }} color='error'
                          // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                          >
                            <DoneIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip arrow title="Mark Validator as Winner for this Speech">
                          <IconButton onClick={async () => {
                            if (!(userInfo.current.userId in RESTRICTED_ADMINS)) {
                              console.log("ACCEPT", row);
                              await handleVerdict(row, "validator");
                            }
                          }} color='success'
                          // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                          >
                            <DoneAllIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )
                  }
                  }

                  // renderRowActionMenuItems={({ closeMenu, row, table }) => [
                  //   <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                  //   <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                  // ]}

                  enableStickyHeader
                  enableStickyFooter
                  // muiTableContainerProps={{ sx: { maxHeight: '300px' } }}

                  //optionally override the default column widths
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 150, //default size is usually 180
                  }}
                  enableColumnResizing
                  columnResizeMode="onChange" //default is "onEnd"
                  enableColumnOrdering

                  positionActionsColumn="last"
                  enableRowSelection
                  enablePinning
                  enableColumnFilterModes

                  filterFns={{
                    customFilterFn: (row, id, filterValue) => {
                      return row.getValue(id) === filterValue;
                    },
                  }}
                  localization={
                    {
                      filterCustomFilterFn: 'Custom Filter Fn',
                    } as any
                  }

                  // renderDetailPanel={({ row }) => (
                  //   <Box
                  //     sx={{
                  //       display: 'grid',
                  //       margin: 'auto',
                  //       gridTemplateColumns: '1fr 1fr',
                  //       width: '100%',
                  //     }}
                  //   >
                  //     <Typography>Annotator ID: {row.original.speechId}</Typography>
                  //     <Typography>Validator ID: {row.original.speechId}</Typography>
                  //   </Box>
                  // )}
                  positionExpandColumn="first"

                  muiToolbarAlertBannerProps={
                    isError
                      ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                      : undefined
                  }

                  onColumnVisibilityChange={setColumnVisibilityAdmin}
                  onColumnFiltersChange={setColumnFiltersAdmin}
                  onGlobalFilterChange={setGlobalFilterAdmin}
                  onPaginationChange={setPaginationAdmin}
                  onSortingChange={setSortingAdmin}

                  state={{
                    columnVisibility: columnVisibilityAdmin,
                    columnFilters: columnFiltersAdmin,
                    globalFilter: globalFilterAdmin,
                    sorting: sortingAdmin,
                    pagination: paginationAdmin,
                    isLoading: isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching || isLoading,
                  }}
                  initialState={{ showColumnFilters: false, columnPinning: { left: ['speechId'] }, density: 'compact' }}

                  positionToolbarAlertBanner="bottom"
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Tooltip arrow title="Refresh Data">
                        <IconButton onClick={async () => await loadData()}>
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>

                      {/* export all data that is currently in the table (ignore pagination, sorting, filtering, etc.) */}
                      <Tooltip arrow title="Export All Data (ignore PFS)">
                        <IconButton onClick={handleExportData} color='info'>
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>

                      {/* export all rows, including from the next page, (still respects filtering and sorting) */}
                      <Tooltip arrow title="Export All Data (respect FS)">
                        <IconButton onClick={() => {
                          let targetRows = table.getPrePaginationRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Eligible Row to Export !!!")
                          }
                        }}
                          color='secondary'
                        // disabled={table.getPrePaginationRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>

                      {/* export all rows as seen on the screen (respects pagination, sorting, filtering, etc.) */}
                      <Tooltip arrow title="Export Current Page (respect PFS)">
                        <IconButton onClick={() => {
                          let targetRows = table.getRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Eligible Row to Export !!!")
                          }
                        }}
                          color='primary'
                        // disabled={table.getRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>

                      {/* export selected rows */}
                      <Tooltip arrow title="Export Selected Rows Only">
                        <IconButton onClick={() => {
                          let targetRows = table.getSelectedRowModel().rows
                          if (targetRows.length > 0) {
                            handleExportRows(targetRows)
                          } else {
                            ToastInfoMsg("No Row Selected to Export !!!")
                          }
                        }}
                          color='warning'
                        // disabled={table.getSelectedRowModel().rows.length === 0}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Reject Selected */}
                      <Tooltip arrow title="Mark Annotator as Winner for Selected Speeches">
                        <IconButton onClick={() => {
                          if (!(userInfo.current.userId in RESTRICTED_ADMINS)) {
                            let selectedRows = table.getSelectedRowModel().rows
                            if (selectedRows.length > 0) {
                              console.log("SELECTED ROWS ANNOTATOR WIN: ", selectedRows)

                              selectedRows.forEach((row: MRT_Row<DisputedSpeech>, index: number, array: MRT_Row<DisputedSpeech>[]) => {
                                handleVerdict(row, "annotator")
                              })
                            }
                          }
                        }}
                          color='error'
                        // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        >
                          <DoneIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Accept Selected */}
                      <Tooltip arrow title="Mark Validator as Winner for Selected Speeches">
                        <IconButton onClick={() => {
                          if (!(userInfo.current.userId in RESTRICTED_ADMINS)) {
                            let selectedRows = table.getSelectedRowModel().rows
                            if (selectedRows.length > 0) {
                              console.log("SELECTED ROWS VALIDATOR WIN: ", selectedRows)

                              selectedRows.forEach((row: MRT_Row<DisputedSpeech>, index: number, array: MRT_Row<DisputedSpeech>[]) => {
                                handleVerdict(row, "validator")
                              })
                            }
                          }
                        }}
                          color='success'
                        // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        >
                          <DoneAllIcon />
                        </IconButton>
                      </Tooltip>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} size="small" />}
                          label="FROM"
                          value={startDateTime}
                          minDateTime={dayjs(MIN_DATE)}
                          hideTabs={false}
                          // showToolbar={true}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              // console.log(newValue, newValue['$d'], typeof newValue['$d'])
                              // console.log(new Date(newValue['$d']).toLocaleString());
                              setStartDateTime(newValue);

                              let dateStart = new Date(newValue['$d']);
                              let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                              localStorage.setItem('startDateTimeAdmin', startDateTimeISO)

                              // setEligibleDataHistory(dataHistory.filter((item: any) => {
                              //     let submittedOn = new Date(item.submittedOn).getTime()
                              //     let start = new Date(newValue['$d']).getTime()
                              //     let end = new Date(endDateTime).getTime()
                              //     return submittedOn >= start && submittedOn <= end
                              // }))
                            }
                          }}
                        />
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} size="small" />}
                          label="TO"
                          value={endDateTime}
                          maxDateTime={dayjs(MAX_DATE)}
                          hideTabs={false}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              setEndDateTime(newValue);

                              let dateEnd = new Date(newValue['$d']);
                              let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                              localStorage.setItem('endDateTimeAdmin', endDateTimeISO)

                              // setEligibleDataHistory(dataHistory.filter((item: any) => {
                              //     let submittedOn = new Date(item.submittedOn).getTime()
                              //     let start = new Date(startDateTime).getTime()
                              //     let end = new Date(newValue['$d']).getTime()
                              //     return submittedOn >= start && submittedOn <= end
                              // }))
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <Tooltip arrow title={showCurrentTime ? "Showing Current Time" : "Showing Last Selected"}>
                        {showCurrentTime ? <IconButton color='success' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                          <UpdateIcon />
                        </IconButton> : <IconButton color='error' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                          <UpdateDisabledIcon />
                        </IconButton>
                        }
                      </Tooltip>
                    </Box>
                  )}
                />
              </TabPanel>
            )}
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider >
  );
};

export default AdminTaskPage;

