import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useParams } from "react-router-dom";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

// import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';

//in entry point of your app (index.js)    

// const channel = new BroadcastChannel('tab');

// channel.postMessage('another-tab');
// note that listener is added after posting the message

// channel.addEventListener('message', (msg) => {
//   if (msg.data === 'another-tab') {
//     // message received from 2nd tab
//     // alert('Cannot open multiple instances');
//     // window.addEventListener("beforeunload", function(event) {
//     //     event.stopImmediatePropagation();
//     // }, true);
//     window.close();
//   }
// });


// i18next.init({
//     interpolation: { escapeValue: false },  // React already does escaping
//     lng: Config.DEFAULT_LANGUAGE,                              // default language to use
//     resources: {
//         ben: {
//             common: common_ben               // 'common' is our custom namespace
//         },
//         en: {
//             common: common_en
//         },
//     },
// });


ReactDOM.render(
    // <React.StrictMode>

    // <I18nextProvider i18n={i18next}>
        <App/>,
    // </I18nextProvider>,

    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
