import { Store } from "react-notifications-component";

export function ToastSuccessMsg(message: any, dur: any = 2500) {
  Store.addNotification({
    title: "Successful!",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: dur,
      onScreen: true,
    },
  });
}

export function ToastFailedMsg(message: any, dur: any = 2500) {
  Store.addNotification({
    title: "Failed!",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: dur,
      onScreen: true,
    },
  });
}

export function ToastInfoMsg(message: any, dur: any = 2500) {
  Store.addNotification({
    // title: "War!",
    message: message,
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: dur,
      onScreen: true,
    },
  });
}

export function ToastWarningMsg(message: any, dur: any = 2500) {
  Store.addNotification({
    // title: "War!",
    message: message,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: dur,
      onScreen: true,
    },
  });
}
