import React, { useEffect, useState, useRef, useMemo, useContext, createContext } from "react";
import { get, isLoggedIn, post, postToPython, setToken, decodeToken } from '../../common/http';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { styled, lighten, darken } from '@mui/system';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import { ToastFailedMsg, ToastSuccessMsg } from "../../common/toast";
import { red } from '@mui/material/colors';

// import activity from '../../icons/activity.png';

const ColorModeContext = createContext({ toggleColorMode: () => { } });
const pages = ['Registration', 'Home'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Login() {
    const [username, setUsername] = useState("");
    const [usernameExists, setUsernameExists] = useState(false);

    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [validNewPassword, setValidNewPassword] = useState(false);
    const [matchNewPassword, setMatchNewPassword] = useState(false);

    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('darkMode') == 'light' ? 'light' : 'dark');

    const [open, setOpen] = useState(false);

    const [annotatorList, setAnnotatorList]: any = useState([]);
    const [loading, setLoading]: any = useState(true);

    useEffect(() => {
        //called when jwt changed
        if (isLoggedIn()) {
            // history.push('/')
            window.location.replace(window.location.origin + `/annotation-task`)
        } else {
            (async () => {
                await getAnnotatorList();
            })()
            setLoading(false);
        }
    }, []);

    const getAnnotatorList = async () => {
        let requestOptions = {
            method: 'GET'
        };

        fetch("https://amarkantho.revesoft.com:5110/get-annotator-list/", requestOptions)
            .then(response => response.json())
            .then(data => {
                setAnnotatorList(data.map((item: any) => item.id))
                // let id = 0;
                // console.log("keys", Object.keys(data));
                // let list = Object.keys(data).map((annotator: any, index: any) => {
                //     return {
                //         "id": data[annotator]["annotator_id"],
                //         "name": annotator,
                //         "roles": data[annotator]["roles"]
                //     }
                // }
                // )
                // setAnnotatorList([...list]);
                // console.log("list", list);
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        // console.log("selectedItems", annotatorList);
    }, [annotatorList]);

    // const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault()
    //     let res = await postToPython("/auth/login", formData)
    //     // let res = await post("/auth/login", formData);
    //     if (res.status === 200 || res.status === 201) {
    //         //success
    //         let data = await res.json()
    //         console.log('msg', data.data);
    //         console.log("data.saved: ", data.data.saved);

    //         setToken(data.data);
    //         let userInfo = decodeToken();

    //         if (userInfo.roles.includes(-1)) {
    //             window.location.replace(window.location.origin + `/admin`)
    //         } else {
    //             if (data.data.saved) {
    //                 //alert("SAVED ? 1")
    //                 localStorage.setItem("speech_id", data.data["speech_id"])
    //                 // history.replace(`/annotation`);
    //                 window.location.replace(window.location.origin + `/annotation`)
    //             }
    //             else {
    //                 // alert("SAVED ? 0")
    //                 //  history.replace('/annotation-task');
    //                 window.location.replace(window.location.origin + `/annotation-task`)
    //             }
    //         }

    //         // window.location.reload();
    //         ToastSuccessMsg("Login Successful")
    //     } else {
    //         ToastFailedMsg("Failed to login. Invalid Username & Password")
    //     }

    // }

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                localStorage.setItem('darkMode', mode === 'light' ? 'dark' : 'light')
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                },
            }),
        [mode],
    );

    function MyHeader() {
        const theme = useTheme();
        const colorMode = useContext(ColorModeContext);
        // const color = red[500];

        const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
        const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

        const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElUser(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            setAnchorElNav(null);
        };

        const handleCloseUserMenu = () => {
            setAnchorElUser(null);
        };

        // return (
        //     <div className="mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: '0px solid red' }}>
        //         <div style={{ width: 'fit-content' }}>
        //             <Button variant="contained" color="primary" endIcon={<HomeIcon />} onClick={() => window.location.replace(window.location.origin + `/`)}>
        //                 HOME
        //             </Button>
        //         </div>
        //         <Stack direction="row" spacing={2} alignItems="center">
        //             <Typography variant="h5" sx={{ fontWeight: '600' }}>REGISTRATION</Typography>
        //             {/* {theme.palette.mode} mode */}
        //             <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        //                 {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
        //             </IconButton>
        //         </Stack>
        //         <div style={{ width: 'fit-content' }}>
        //             <Button variant="contained" color="success" endIcon={<LoginIcon />} onClick={() => window.location.replace(window.location.origin + `/login`)}>
        //                 LOGIN
        //             </Button>
        //         </div>
        //     </div>
        // );
        return (
            <AppBar position="sticky" sx={{ backgroundColor: '#212121' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <LoginIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href='/login'
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            LOGIN
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={(event: any) => {
                                        handleCloseNavMenu();
                                        let pageName = event.target.innerText;
                                        if (pageName.toLowerCase() == 'registration') {
                                            window.location.replace(window.location.origin + `/registration`)
                                        } else if (pageName.toLowerCase() == 'home') {
                                            window.location.replace(window.location.origin + `/`)
                                        }
                                    }}>
                                        <Typography textAlign="center">{page}</Typography>
                                        {/* {page.toLowerCase()=='registration' ? <LoginIcon /> : <HomeIcon />} */}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <LoginIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href='/login'
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            LOGIN
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    variant="contained"
                                    color="success"
                                    onClick={(event: any) => {
                                        handleCloseNavMenu();
                                        let targetButton = event.target;
                                        while (targetButton.nodeName != 'BUTTON') {
                                            targetButton = targetButton.parentNode;
                                        }
                                        let pageName = targetButton.innerText;
                                        if (pageName.toLowerCase() == 'registration') {
                                            window.location.replace(window.location.origin + `/registration`)
                                        } else if (pageName.toLowerCase() == 'home') {
                                            window.location.replace(window.location.origin + `/`)
                                        }
                                    }}
                                    endIcon={page.toLowerCase() == 'registration' ? <PersonAddIcon /> : <HomeIcon />}
                                    sx={{ ml: 2, my: 2, color: 'white', backgroundColor: '#004d40' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            {/* <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src={activity} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu> */}
                            <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: '#f06292', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => { }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <MyHeader />
                <div className="container mt-5" style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', border: '0px solid red' }}>
                    <Card sx={{ maxWidth: 345, border: '2px solid #1a237e' }}>
                        <CardActionArea>
                            {/* <CardMedia
                                    component="img"
                                    height="140"
                                    image="/static/images/cards/contemplative-reptile.jpg"
                                    alt="green iguana"
                                /> */}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{ mb: 5 }}>
                                    Welcome to Login Panel
                                </Typography>
                                <Stack spacing={3}>
                                    <TextField
                                        required
                                        id="username"
                                        label="Username"
                                        defaultValue=""
                                        spellCheck={false}
                                        sx={{ width: '100%' }}
                                        onChange={(e: any) => {
                                            setUsername(e.target.value);
                                            setUsernameExists(annotatorList.includes(e.target.value))
                                        }}
                                        error={!usernameExists}
                                        helperText={usernameExists ? '' : "Username Doesn't Exist ! Type Correctly !"}
                                    />
                                    <TextField
                                        required
                                        id="password"
                                        label="Password"
                                        type="password"
                                        disabled={!usernameExists}
                                        defaultValue=""
                                        sx={{ width: '100%' }}
                                        onChange={(e: any) => {
                                            setPassword(e.target.value);
                                            // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i;
                                            const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/i;
                                            setValidPassword(passwordRegex.test(e.target.value.toLowerCase()));
                                        }}
                                        error={!validPassword && usernameExists}
                                        helperText={validPassword || !usernameExists ? '' : "Must contain at least 8 characters including alphabet and number"}
                                    />
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Stack direction='row' spacing={2}>
                                <Stack sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', m: 0, p: 0 }}>
                                    <Button size="small" color="error" onClick={() => setOpen(true)} disabled={!usernameExists}>
                                        Change Password
                                    </Button>
                                    <Dialog open={open} TransitionComponent={Transition} onClose={() => setOpen(false)}>
                                        <DialogTitle>CHANGE PASSWORD</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText sx={{ mb: 3 }}>
                                                Type your New Password below & store it in a safe place so that you do Not forget again !
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="new-password"
                                                label="New Password"
                                                type="password"
                                                fullWidth
                                                variant="standard"
                                                onChange={(e: any) => {
                                                    setNewPassword(e.target.value);
                                                    const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/i;
                                                    setValidNewPassword(passwordRegex.test(e.target.value.toLowerCase()));
                                                }}
                                                error={!validNewPassword}
                                                helperText={validNewPassword ? '' : "Must contain at least 8 characters including alphabet and number"}
                                            />
                                            <TextField
                                                // autoFocus
                                                margin="dense"
                                                id="new-password-retype"
                                                label="Retype New Password"
                                                type="password"
                                                fullWidth
                                                variant="standard"
                                                disabled={!validNewPassword}
                                                onChange={(e: any) => {
                                                    setMatchNewPassword(e.target.value == newPassword);
                                                }}
                                                error={validNewPassword && !matchNewPassword}
                                                helperText={matchNewPassword || !validNewPassword ? '' : "Password does Not match ! Type correctly !"}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant="contained" color="error" onClick={() => setOpen(false)}>CANCEL</Button>
                                            <Button variant="contained" color="success" endIcon={<SendIcon />} disabled={!matchNewPassword} onClick={async () => {
                                                setOpen(false);
                                                setLoading(true);
                                                console.log("RESET PASSWORD !");
                                                let myHeaders = new Headers();
                                                myHeaders.append("Content-Type", "application/json");

                                                let requestBody = JSON.stringify({
                                                    "username": username,
                                                    "password": newPassword
                                                });
                                                // console.log(requestBody)

                                                try {
                                                    let requestOptions = {
                                                        method: 'POST',
                                                        headers: myHeaders,
                                                        body: requestBody,
                                                        // redirect: 'follow'
                                                    };

                                                    let response = await fetch("https://amarkantho.revesoft.com:5102/reset-password/", requestOptions)
                                                    setLoading(false)
                                                    let status = response.status
                                                    if (status == 200 || status == 201) {
                                                        let responseBody = await response.json()
                                                        ToastSuccessMsg(responseBody.message)
                                                    } else if (status == 422) {
                                                        ToastFailedMsg("Field Missing !")
                                                    } else {
                                                        let responseBody = await response.json()
                                                        ToastFailedMsg(responseBody.message)
                                                    }
                                                }
                                                catch (err: any) {
                                                    console.log("ERROR ! ", err.message)
                                                    ToastFailedMsg(`ERROR ! ${err.message}`)
                                                }
                                            }}>SUBMIT</Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Button size="small" color="primary" onClick={() => window.location.replace(window.location.origin + `/registration`)} >
                                        NO ACCOUNT? REGISTER
                                    </Button>
                                </Stack>
                                <Box>
                                    <Button variant="contained" size="small" color="success" endIcon={<LoginIcon />} disabled={!usernameExists || password == '' || loading} onClick={async (event: any) => {
                                        event.preventDefault();
                                        setLoading(true);
                                        console.log("LOGIN !");
                                        let myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/json");

                                        let requestBody = JSON.stringify({
                                            "username": username,
                                            "password": password
                                        });
                                        // console.log(requestBody)

                                        try {
                                            let requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: requestBody,
                                                // redirect: 'follow'
                                            };

                                            let response = await fetch("https://amarkantho.revesoft.com:5102/login/", requestOptions)
                                            setLoading(false)
                                            let status = response.status
                                            if (status == 200 || status == 201) {
                                                let responseBody = await response.json()
                                                ToastSuccessMsg(responseBody.message)
                                                console.log('RESPONSE BODY: ', responseBody.data);

                                                setToken(responseBody.data);
                                                let userInfo = decodeToken();

                                                if (userInfo.role.includes("admin")) {
                                                    window.location.replace(window.location.origin + `/admin`)
                                                } else {
                                                    if (responseBody.data.saved) {
                                                        localStorage.setItem("speech_id", responseBody.data["speech_id"])
                                                        window.location.replace(window.location.origin + `/annotation`)
                                                    }
                                                    else {
                                                        window.location.replace(window.location.origin + `/annotation-task`)
                                                    }
                                                }
                                            } else if (status == 422) {
                                                ToastFailedMsg("Field Missing !")
                                            } else {
                                                let responseBody = await response.json()
                                                ToastFailedMsg(responseBody.message)
                                            }
                                        }
                                        catch (err: any) {
                                            setLoading(false)
                                            console.log("ERROR ! ", err.message)
                                            ToastFailedMsg(`ERROR ! ${err.message}`)
                                        }
                                    }}>
                                        LOGIN
                                    </Button>
                                </Box>
                            </Stack>
                        </CardActions>
                    </Card>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
