import { useEffect, useState, useRef, useMemo, useContext, createContext } from 'react';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import MicIcon from '@mui/icons-material/Mic';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonitorIcon from '@mui/icons-material/Monitor';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LogoutIcon from '@mui/icons-material/Logout';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

import { getLoggedInUserId, getRoles, decodeToken, isLoggedIn, unsetToken } from "../../common/http";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// import { Button } from 'react-bootstrap';
import { ToastFailedMsg, ToastSuccessMsg, ToastInfoMsg, ToastWarningMsg } from "../../common/toast";

const ColorModeContext = createContext({ toggleColorMode: () => { } });
const pages = ['Dispute', 'Monitor', 'Logout'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

export default function RoleUpdate() {
    // let checkboxOptions: any = [1, 2, 3, 4, 5, 6, 7, 8];
    const totalNumberOfLevels = 8;
    const RESTRICTED_ADMINS = { "annotation_tts_buet1202@gmail.com": 1, "crebsol_stt_admin@gmail.com": 1 }

    const lastSelectedID: any = useRef("")
    const [annotatorList, setAnnotatorList]: any = useState([]);
    // const annotatorMap: any = useRef({});
    const [options, setOptions] = useState([]);
    const userInfo: any = useRef({});
    const [selectedItems, setSelectedItems]: any = useState([]);
    const [loading, setLoading]: any = useState(true);

    const [tts, setTTS] = useState(false);

    const [semiAdminChoice, setSemiAdminChoice] = useState('non-admin');

    useEffect(() => {
        console.log("TTS: ", tts, typeof tts);
    }, [tts]);

    useEffect(() => {
        let user = decodeToken()
        console.log('LoggedIn-UserInfo', user);
        userInfo.current = user;

        if (!(userInfo.current.role.includes("admin"))) {
            setLoading(true)
            window.location.replace(window.location.origin + `/`)
        } else {
            (async () => {
                await getAnnotatorList();
            })()

            for (let i = 0; i < totalNumberOfLevels; i++) {
                // console.log(i)
                setSelectedItems((prevItems: any) => [...prevItems, false])
            }
        }
    }, []);

    useEffect(() => {
        console.log("selectedItems", annotatorList);

        if (annotatorList.length > 0) {
            setLoading(false);
            // for (let i = 0; i < annotatorList.length; i++) {
            //     annotatorMap.current[annotatorList[i].username] = {
            //         id: annotatorList[i].id,
            //         roles: annotatorList[i].roles
            //     }
            // }

            setOptions(
                annotatorList.map((option: any) => {
                    const firstLetter = option.id[0].toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                        ...option,
                    };
                }).toSorted((a: any, b: any) => (a.id > b.id) ? 1 : -1)
            );
        }
    }, [annotatorList]);

    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('darkMode') == 'light' ? 'light' : 'dark');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                },
            }),
        [mode],
    );

    const getAnnotatorList = async () => {
        let requestOptions = {
            method: 'GET'
        };

        fetch("https://amarkantho.revesoft.com:5110/get-annotator-list/", requestOptions)
            .then(response => response.json())
            .then(data => {
                if (userInfo.current.role == 'admin') {
                    setAnnotatorList(data.filter((item: any) => !(item.roles.includes(-1))))
                } else if (userInfo.current.role == 'stt-admin') {
                    setAnnotatorList(data.filter((item: any) => !(item.roles.includes(-1) || item.roles.includes(-3) || item.tts == true)))
                } else if (userInfo.current.role == 'tts-admin') {
                    setAnnotatorList(data.filter((item: any) => !(item.roles.includes(-1) || item.roles.includes(-2) || item.tts == false)))
                }

                // let id = 0;
                // console.log("keys", Object.keys(data));
                // let list = Object.keys(data).map((annotator: any, index: any) => {
                //     return {
                //         "id": data[annotator]["annotator_id"],
                //         "name": annotator,
                //         "roles": data[annotator]["roles"]
                //     }
                // }
                // )
                // setAnnotatorList([...list]);
                // console.log("list", list);
            })
            .catch(error => console.log('error', error));
    }

    const onLogout = () => {
        // setUnloadEventListener(false);

        // setTimeout(() => {
        localStorage.removeItem('speech_id');
        localStorage.removeItem('latestPage');
        localStorage.removeItem('latestSizePerPage');
        unsetToken();
        // history.push('/login')
        // history.go(0)
        window.location.replace(window.location.origin + `/login`)
        // }, 100);
        // return true;
    };

    const onSubmit = async () => {
        setLoading(true)
        let targetArr = annotatorList.filter((item: any, index: any, arr: any) => item.id == lastSelectedID.current)
        // console.log(targetArr)
        if (targetArr.length == 1) {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let roles = selectedItems.map((e: any, i: any) => e === true ? i + 1 : '').filter(String)

            if (semiAdminChoice == 'non-admin') {
                let includesOdd = roles.includes(1) || roles.includes(3) || roles.includes(5) || roles.includes(7)
                let includesEven = roles.includes(2) || roles.includes(4) || roles.includes(6) || roles.includes(8)

                if (includesOdd && includesEven) {
                    setLoading(false)
                    ToastFailedMsg("একইসাথে Annotator এবং Validator, উভয় Role এ assign করা যাবে না। যেকোন এক প্রকার role choose করুন।", 4000)
                    return;
                }
            }

            if (semiAdminChoice == 'stt-admin') {
                roles = [-2]
            } else if (semiAdminChoice == 'tts-admin') {
                roles = [-3]
            }

            let raw = JSON.stringify({
                "enforcer_id": userInfo.current.userId,
                "annotator_id": targetArr[0].id,
                "roles": roles,
                "tts": (semiAdminChoice == 'stt-admin' || userInfo.current.role == 'stt-admin') ? false : (semiAdminChoice == 'tts-admin' || userInfo.current.role == 'tts-admin') ? true : tts
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw
            };

            console.log("ROLES: ", roles)

            fetch("https://amarkantho.revesoft.com:5110/update-roles/", requestOptions)
                .then(async (response: any) => {
                    if (response.status == 200) {
                        return { state: "success", res: await response.json() }
                    } else {
                        return { state: "error", res: await response.json() }
                    }
                })
                .then(result => {
                    console.log(result);
                    if (result.state == "success") {
                        setLoading(false)
                        ToastSuccessMsg(result.res.message);
                        setAnnotatorList(annotatorList.map((item: any, index: any, arr: any) => item.id == lastSelectedID.current ? { ...item, "roles": roles, "tts": tts } : item))
                        // setSelectedItems(selectedItems.map((item: any, index: any, array: any) => {
                        //     return false
                        // }))
                    } else {
                        setLoading(false)
                        ToastFailedMsg(result.res.message);
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            setLoading(false)
            ToastFailedMsg("Username Exist করে না !!!")
        }
    }

    function MyHeader() {
        const theme = useTheme();
        const colorMode = useContext(ColorModeContext);
        // const color = red[500];

        const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
        const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

        const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElUser(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            setAnchorElNav(null);
        };

        const handleCloseUserMenu = () => {
            setAnchorElUser(null);
        };

        // return (
        //     <div className="mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: '0px solid red' }}>
        //         <div style={{ width: 'fit-content' }}>
        //             <Button variant="contained" color="primary" endIcon={<HomeIcon />} onClick={() => window.location.replace(window.location.origin + `/`)}>
        //                 HOME
        //             </Button>
        //         </div>
        //         <Stack direction="row" spacing={2} alignItems="center">
        //             <Typography variant="h5" sx={{ fontWeight: '600' }}>REGISTRATION</Typography>
        //             {/* {theme.palette.mode} mode */}
        //             <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        //                 {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
        //             </IconButton>
        //         </Stack>
        //         <div style={{ width: 'fit-content' }}>
        //             <Button variant="contained" color="success" endIcon={<LoginIcon />} onClick={() => window.location.replace(window.location.origin + `/login`)}>
        //                 LOGIN
        //             </Button>
        //         </div>
        //     </div>
        // );
        return (
            <AppBar position="sticky" sx={{ backgroundColor: '#212121' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <AssignmentIndIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href='/roles'
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            ROLES
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={(event: any) => {
                                        handleCloseNavMenu();
                                        let pageName = event.target.innerText;
                                        if (pageName.toLowerCase() == 'dispute') {
                                            window.location.replace(window.location.origin + `/admin`)
                                        } else if (pageName.toLowerCase() == 'monitor') {
                                            window.location.replace(window.location.origin + `/admin-monitor`)
                                        } else if (pageName.toLowerCase() == 'logout') {
                                            onLogout();
                                        }
                                    }}>
                                        <Typography textAlign="center">{page}</Typography>
                                        {/* {page.toLowerCase()=='registration' ? <LoginIcon /> : <HomeIcon />} */}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <AssignmentIndIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href='/roles'
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            ROLES
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    variant="contained"
                                    color={page.toLowerCase() == 'dispute' ? "error" : "success"}
                                    onClick={(event: any) => {
                                        handleCloseNavMenu();
                                        let targetButton = event.target;
                                        while (targetButton.nodeName != 'BUTTON') {
                                            targetButton = targetButton.parentNode;
                                        }
                                        let pageName = targetButton.innerText;
                                        if (pageName.toLowerCase() == 'dispute') {
                                            window.location.replace(window.location.origin + `/admin`)
                                        } else if (pageName.toLowerCase() == 'monitor') {
                                            window.location.replace(window.location.origin + `/admin-monitor`)
                                        } else if (pageName.toLowerCase() == 'logout') {
                                            onLogout();
                                        }
                                    }}
                                    endIcon={page.toLowerCase() == 'dispute' ? <LocalFireDepartmentIcon /> : page.toLowerCase() == 'monitor' ? <MonitorIcon /> : <LogoutIcon />}
                                    sx={{ ml: 2, my: 2, color: 'white', backgroundColor: page.toLowerCase() == 'dispute' ? '#b71c1c' : '#004d40' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            {/* <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src={activity} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu> */}
                            <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: '#f06292', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => { }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <MyHeader />
                <div className='container'>
                    {/* <SearchIcon /> */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {selectedItems.map((item: any, index: any, array: any) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        label={`${index + 1}`}
                                        control={<Checkbox id={`${index + 1}`} color='success' checked={array[index]} onChange={(event: any) => {
                                            // console.log(event.target.id, event.target.checked)
                                            setSelectedItems(array.map((itm: any, idx: any) => idx == index ? event.target.checked : itm))
                                        }} />}
                                    />
                                )
                            })}
                        </Box>
                        <Autocomplete
                            id="grouped-demo"
                            options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option: any) => option.firstLetter}
                            getOptionLabel={(option: any) => option.id}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            clearOnBlur={false}
                            selectOnFocus={true}
                            sx={{ width: 300 }}

                            onChange={(event: any, value: any, reason: any) => {
                                console.log("CHANGE: ", value);
                                try {
                                    lastSelectedID.current = value.id;
                                    setSelectedItems(selectedItems.map((item: any, index: any, array: any) => {
                                        return value.roles.includes(index + 1) ? true : false
                                    }));
                                    setTTS(value.tts)
                                    if (value.roles.includes(-2)) {
                                        setSemiAdminChoice('stt-admin')
                                    } else if (value.roles.includes(-3)) {
                                        setSemiAdminChoice('tts-admin')
                                    } else {
                                        setSemiAdminChoice('non-admin')
                                    }
                                } catch (err) {
                                    lastSelectedID.current = "";
                                    setSelectedItems(selectedItems.map((item: any, index: any, array: any) => {
                                        return false
                                    }));
                                    setTTS(false)
                                }
                            }}
                            onHighlightChange={(event: any, option: any, reason: any) => {
                                // console.log("HIGHLIGHT: ", option);
                            }}
                            onInputChange={(event: any, value: any, reason: any) => {
                                lastSelectedID.current = value;
                                // console.log("INPUT CHANGE: ", lastSelectedUsername.current);
                            }}
                            renderInput={(params) => <TextField {...params} label="Search by Username ..." />}
                            renderGroup={(params) => {
                                // console.log("PARAMS: ", params);

                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )
                            }}
                        />
                        <Stack direction="column" spacing={2}>
                            <Button variant="contained" color="info" endIcon={<DoneIcon />} onClick={() => {
                                setSelectedItems(selectedItems.map((item: any, index: any, array: any) => {
                                    return (index + 1) % 2 == 1 ? true : false
                                }))
                            }}>
                                ANNOTATOR
                            </Button>
                            <Button variant="contained" color="primary" endIcon={<DoneAllIcon />} onClick={() => {
                                setSelectedItems(selectedItems.map((item: any, index: any, array: any) => {
                                    return (index + 1) % 2 == 0 ? true : false
                                }))
                            }}>
                                VALIDATOR
                            </Button>
                            <Box>
                                <Switch
                                    checked={tts}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setTTS(event.target.checked);
                                    }}
                                    color='error'

                                /> TTS
                            </Box>

                            <ToggleButtonGroup
                                orientation="vertical"
                                value={semiAdminChoice}
                                exclusive
                                onChange={(event: React.MouseEvent<HTMLElement>, adminChoice: string) => {
                                    console.log("SEMI ADMIN !!! ", adminChoice)
                                    setSemiAdminChoice(adminChoice);
                                }}
                            >
                                <ToggleButton value="non-admin" aria-label="non-admin" color='error'>
                                    NON-ADMIN&nbsp;&nbsp; <PersonOffIcon />
                                </ToggleButton>
                                <ToggleButton value="stt-admin" aria-label="stt-admin" color='info'>
                                    SEMI-ADMIN STT&nbsp;&nbsp; <MicIcon />
                                </ToggleButton>
                                <ToggleButton value="tts-admin" aria-label="tts-admin" color='secondary'>
                                    SEMI-ADMIN TTS&nbsp;&nbsp; <TextSnippetIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <Button variant="contained" color="success" endIcon={<TaskAltIcon />}
                                disabled={userInfo.current.userId in RESTRICTED_ADMINS}
                                onClick={onSubmit}>
                                ASSIGN
                            </Button>
                        </Stack>
                    </Box>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}